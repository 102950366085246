import makeRequired from './required.helper'
import setRidDefault from './set_rid_default'

const randomId = options => {
  const data = {}

  const {
    key = 'rid',
    label = 'system.fields.rid',
    restrictUpdate = false
  } = { ...options }

  data[key] = {
    dyField: 1,
    dyLabel: label,
    dyValidation: [...makeRequired()],
    dyDefault: setRidDefault,
    dyType: 'rid'
  }

  if (restrictUpdate) {
    data[key].dyRestrictUpdate = true
  }

  return data
}

export default randomId
