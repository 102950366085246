import { isBlank } from './../../utils/data/is_blank'

/**
 * transform a value to a defaultValue if value is blank.
 *
 * options include:
 * - defaultValue: the value to assign fieldValue if fieldValue is blank
 *
 * @param {object} options options
 * @param {object} data    { doc, fieldValue }
 * @returns {any}          transformed value
 */

const transform = options => payload => {
  const { fieldValue } = payload
  const defaultValue =
    options && options.defaultValue ? options.defaultValue : ''
  return isBlank(fieldValue) ? defaultValue : fieldValue
}

export default transform
