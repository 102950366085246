import { CATEGORY_ROUTE } from './../../../setup/collection.constants'

import ListCategories from './ListCategories.vue'
import ViewCategory from './ViewCategory.vue'

const routes = [
  {
    path: `/${CATEGORY_ROUTE}`,
    name: CATEGORY_ROUTE,
    component: ListCategories,
    meta: { requiresSuperUser: true, requiresAdministrator: true }
  },

  {
    path: `/${CATEGORY_ROUTE}/:id`,
    name: `${CATEGORY_ROUTE}:view`,
    component: ViewCategory,
    props: true,
    meta: { requiresSuperUser: true, requiresAdministrator: true }
  }
]

export default routes
