import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'
import getUidRe from './../../utils/uid/get_uid_re'

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  const isString = typeof fieldValue === 'string'
  if (isBlank(fieldValue) && isString) {
    return true
  }

  if (isString === false) {
    return { fieldError: 'system.errors.invalid' }
  }

  if (getUidRe().test(fieldValue)) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
