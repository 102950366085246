<template>
  <dy-base-article>
    <template #header>
      <h1>Thank You</h1>
    </template>

    <dy-base-suspense>
      <template #default>
        <p class="info">
          Thank you to all for your participation in this year's
          <strong>I Recommend</strong>
          nominations for our summer issue.
        </p>

        <p class="info">
          Be sure to read through the issue when it drops
          <strong>June 1st</strong> to see if your entry made it.
        </p>

        <p class="info">
          We will be drawing 5 names for the beauty prize bags valued at up to
          $300, so best of luck.
        </p>

        <p class="info">Thanks again for your support!</p>
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>
