import { isEmpty } from '../../utils/data/is_empty'
import { isString } from '../../utils/data/is_string'
import { isNumber } from '../../utils/data/is_number'
import { isArray } from '../../utils/data/is_array'
import { isBlank } from '../../utils/data/is_blank'

const isValidRange = options => value => {
  const minimum = options.minimum || 0
  const maximum = options.maximum || 0

  if (value < minimum) {
    return { fieldError: `${options.message}Minimum`, minimum }
  }

  if (maximum > 0 && value > maximum) {
    return { fieldError: `${options.message}Maximum`, maximum }
  }

  return true
}

/**
 * validate the size/length of a value
 *
 *
 * USE:
 * {
 *   rule: 'range',
 *   message: 'system.errors.textLength',
 *   minimum: 0,
 *   maximum: 100
 * }
 *
 * @param {object} options options
 * @param {objec}  validateData { fieldValue, doc }
 */
const validate = options => payload => {
  const { fieldValue } = payload
  const skipOnEmpty = !!options.skipOnEmpty
  if (isEmpty(fieldValue)) {
    return true
  }

  if (isArray(fieldValue)) {
    if (skipOnEmpty && fieldValue.length === 0) {
      return true
    }

    return isValidRange({ message: 'system.errors.list', ...options })(
      fieldValue.length
    )
  }

  if (skipOnEmpty && isBlank(fieldValue)) {
    return true
  }

  if (isNumber(fieldValue)) {
    return isValidRange({ message: 'system.errors.number', ...options })(
      fieldValue
    )
  }

  if (isString(fieldValue)) {
    return isValidRange({ message: 'system.errors.string', ...options })(
      fieldValue.trim().length
    )
  }

  return {
    fieldError: `value must be a string, integer, or array: ${typeof fieldValue}`
  }
}

export default validate
