import { isBlank } from '../utils/data/is_blank'

import inflateErrors from './inflate_errors'

class ValidationError extends Error {
  constructor(payload) {
    const {
      doc,
      errors,
      message = 'error.messages.validationError'
    } = {
      ...payload
    }

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError)
    }

    this.code = '400'
    this.title = 'error.labels.validationError'
    this.message = message
    this.data = {
      doc: isBlank(doc) ? {} : doc,
      errors: inflateErrors(errors)
    }
    this.errorOn = new Date().toJSON()
  }
}

export default ValidationError
