import authRoutes from '../../../resources/auth/pages/auth.routes'
import categoryRoutes from '../../../resources/categories/pages/category.routes'
import errorRoutes from '../../../widgets/errors/pages/error.routes'
import homeRoutes from '../../../resources/home/pages/home.routes'
import surveyRoutes from '../../../resources/surveys/pages/survey.routes'
import reportRoutes from '../../../resources/reports/pages/report.routes'

const setClientRoutes = () => {
  return [
    ...authRoutes,
    ...categoryRoutes,
    ...homeRoutes,
    ...surveyRoutes,
    ...reportRoutes,
    ...errorRoutes
  ]
}

export default setClientRoutes
