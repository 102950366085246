import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'

import assertError from '../assert_error'
import assertCollection from '../assert_collection'
import toModels from '../to_models'

const queryAll = fdb => collectionName => options => {
  return assertCollection(collectionName)
    .then(() => [])
    .then(addOrderBy(options))
    .then(addLimit(options))
    .then(constraints => {
      const q = query(collection(fdb, collectionName), ...constraints)
      return getDocs(q)
    })
    .then(toModels)
    .catch(assertError)
}

const addOrderBy = options => constraints => {
  return (options.orderBy || []).reduce((accum, clause) => {
    const { field, direction } = clause
    accum.push(orderBy(field, direction))
    return accum
  }, constraints)
}

const addLimit = options => constraints => {
  return options.limit && options.limit > 0
    ? [...constraints, limit(options.limit)]
    : constraints
}

export default queryAll
