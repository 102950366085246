<template lang="html">
  <div class="category__container">
    <div
      class="category__title"
      @click="toggleOpen"
      title="Click to open or close"
    >
      <dy-base-icon
        v-if="isOpen"
        icon="faq/down"
        class="icon--category icon--md"
      />

      <dy-base-icon v-else icon="faq/right" class="icon--category icon--md" />
      {{ category.name }}
    </div>
    <ul class="faq" v-if="isOpen">
      <dy-product
        v-for="(product, idx) in category.products"
        :key="product.id"
        :product="product"
        :schema="getResponseSchema(product.id)"
        :doc="getResponse(product.id)"
        :docErrors="getResponseErrors(product.id)"
        :docIdx="getResponseIndex(product.id)"
        :productIdx="idx"
        @runUpdateFieldValue="runUpdateFieldValue"
        @runUpdate="runUpdate"
      />
    </ul>
  </div>
</template>

<script>
  import getFieldSchema from './../../../services/schema/get_field_schema'
  import { getItem, setItem } from './../../../services/store/get_local_storage'

  const LOCAL_STORAGE_KEY = 'openCategories'
  import SurveyFormProduct from './SurveyFormProduct.vue'

  export default {
    name: 'SurveyFormCategpru',

    data() {
      return {
        openList: '',
        isOpen: false
      }
    },
    props: {
      category: {
        type: Object,
        required: true
      },

      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      },

      docErrors: {
        type: Object,
        required: true
      },

      productXref: {
        type: Object,
        required: true
      },

      categoryIdx: {
        type: Number,
        required: true
      }
    },

    emits: ['runUpdateFieldValue', 'runUpdate'],

    methods: {
      toggleOpen() {
        this.isOpen = !this.isOpen
        const updatedList = this.isOpen
          ? this.openList.split(',').concat(this.category.id).join(',')
          : this.openList
              .split(',')
              .filter(x => x !== this.category.id)
              .join(',')
        setItem(LOCAL_STORAGE_KEY)(updatedList)
        this.openList = updatedList
      },

      getResponse(productId) {
        return this.productXref[productId].response
      },

      getResponseIndex(productId) {
        return this.productXref[productId].responseIdx
      },

      getResponseErrors(productId) {
        if (!this.docErrors || !this.docErrors.responses) {
          return {}
        }

        const idx = this.getResponseIndex(productId)
        return this.docErrors.responses[idx] || {}
      },

      getResponseSchema(productId) {
        const idx = this.getResponseIndex(productId)
        return getFieldSchema(this.schema)(this.doc)(`responses.${idx}`)
      },

      runUpdateFieldValue(payload) {
        this.$emit('runUpdateFieldValue', payload)
      },

      runUpdate() {
        this.$emit('runUpdate')
      }
    },

    components: {
      'dy-product': SurveyFormProduct
    },

    mounted() {
      this.openList = getItem(LOCAL_STORAGE_KEY) || ''
      this.isOpen =
        (this.categoryIdx === 0 && this.openList === '') ||
        this.openList.includes(this.category.id)
    }
  }
</script>

<style lang="scss">
  .category__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    padding: 0.75em 0.25em 0.75em 1em;
    font-size: 1em;
    cursor: pointer;
    color: #efad65;
    text-transform: uppercase;

    svg {
      margin-right: 0.5em;
    }

    .icon--category {
      color: #efad65;
    }
  }
</style>
