<template>
  <dy-base-article>
    <template #header>
      <h1>{{ $t('survey.documents.profile') }}</h1>
    </template>

    <dy-base-suspense>
      <template v-slot:default>
        <p class="info">
          {{ $t('survey.help.profile') }}
        </p>

        <dy-survey
          :schema="schema"
          :doc="doc"
          :docErrors="docErrors"
          @runCancel="runReturn"
          @runUpdateFieldValue="runUpdateFieldValue"
          @runUpdate="runUpdate"
          @runNext="runNext"
        />
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import setupEditPage from './../../../widgets/page/edit/setup_edit_page'
  import getPropertyList from './../../../utils/document/get_property_list'

  import SurveyUserForm from './../components/SurveyUserForm.vue'
  import { Schema } from './../survey.schema'

  export default {
    name: 'EditSurveyUser',

    props: {
      id: {
        type: String,
        required: true
      }
    },

    setup(props) {
      const { schema, doc, docErrors, runUpdateFieldValue, runUpdate } =
        setupEditPage(Schema(true))(props)

      return {
        schema,
        doc,
        docErrors,
        runUpdateFieldValue,
        runUpdate
      }
    },

    components: {
      'dy-survey': SurveyUserForm
    },

    methods: {
      runNext() {
        return this.runUpdate().then(() => {
          if (getPropertyList(this.docErrors).length >= 1) {
            return
          }

          this.$router.push({ name: 'surveys:edit', params: { id: this.id } })
        })
      },

      runReturn() {
        return this.$router.push({ name: 'home' })
      }
    }
  }
</script>
