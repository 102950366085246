import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'
import { ASSIGN_UID } from './../../services/transform/transform.constants'

// eslint-disable-next-line no-useless-escape
const uidRe = RegExp(process.env.VUE_APP_uid_format)

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  const isString = typeof fieldValue === 'string'
  if (isBlank(fieldValue) && isString) {
    return true
  }

  if (isString === false) {
    return { fieldError: 'system.errors.invalid' }
  }

  if (fieldValue === ASSIGN_UID || uidRe.test(fieldValue)) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
