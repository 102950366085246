import transformIfBlank from './../../services/transform/transform_if_blank'

export const UID = '_new'

export const Schema = () => {
  const data = {
    dyLabel: 'auth.documents.authentication',
    dyName: 'email',
    dyRoute: 'signin',
    dyCollection: 'auth',
    dyType: 'object',

    id: {
      dyField: 1,
      dyLabel: 'system.fields.id',
      dyTransform: [transformIfBlank({ defaultValue: UID })],
      dyValidation: [{ rule: 'required' }],
      dyType: 'string'
    },

    email: {
      dyField: 1,
      dyLabel: 'system.fields.email',
      dyValidation: [{ rule: 'required' }],
      dyType: 'email'
    }
  }

  return data
}
