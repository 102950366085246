import { getApplication } from '../../../database_services/fbclient/fb_client'

const verifyAuthentication = () => {
  return getApplication().then(({ fbAuth }) => {
    const user = fbAuth.currentUser

    if (!user) {
      return Promise.resolve(false)
    }

    return fbAuth.currentUser.getIdTokenResult(true).then(tokenData => {
      const { claims = {} } = { ...tokenData }

      const userData = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        claims: {
          super: !!claims.super,
          administrator: !!claims.administrator
        }
      }
      return userData
    })
  })
}

export default verifyAuthentication
