<template>
  <dy-icon-sprite />
  <component :is="layout" />
</template>

<script>
  import { onAuthStateChanged } from 'firebase/auth'

  import AuthLayout from './AuthLayout.vue'
  import AppLayout from './AppLayout.vue'
  import GuestLayout from './GuestLayout.vue'
  import IconSprite from './../../../widgets/icons/IconSprite.vue'

  import {
    startApplication,
    stopApplication
  } from './../../../database_services/fbclient/fb_client'

  import './styles/app.scss'

  export default {
    name: 'App',

    data() {
      return {
        layout: null
      }
    },

    watch: {
      $route(to) {
        if (to.meta.layout !== undefined) {
          this.layout = to.meta.layout
        } else {
          this.layout = 'AppLayout'
        }
      }
    },

    components: {
      AppLayout,
      AuthLayout,
      GuestLayout,
      'dy-icon-sprite': IconSprite
    },

    created() {
      this.$store.commit('SET_LOADING')

      return startApplication()
        .then(({ fbAuth }) => {
          onAuthStateChanged(fbAuth, user => {
            if (!user) {
              return this.$store.dispatch('auth/runAuthenticationChange', user)
            }

            return fbAuth.currentUser
              .getIdTokenResult()
              .then(tokenData => {
                const { claims = {} } = { ...tokenData }
                const userData = {
                  uid: user.uid,
                  email: user.email,
                  displayName: user.displayName,
                  claims: {
                    super: !!claims.super,
                    administrator: !!claims.administrator
                  }
                }
                return userData
              })
              .then(user => {
                return this.$store
                  .dispatch('auth/runAuthenticationChange', user)
                  .then(() => this.$store.dispatch('runSetUp'))
                  .then(() => {
                    const url = this.$store.getters['getUrl']
                    return this.$router.push(url)
                  })
              })
          })
          return true
        })
        .then(() => {
          this.$store.commit('CLEAR_LOADING')
          return true
        })
        .catch(err => {
          this.$store.commit('CLEAR_LOADING')
          throw err
        })
    },

    unmounted() {
      return stopApplication().then(() => {
        return true
      })
    }
  }
</script>
