import { getApplication } from '../../../database_services/fbclient/fb_client'
import signOff from '../../../database_services/fbclient/signoff/sign_off'

const wrapSignOff = () => {
  return getApplication()
    .then(({ fbAuth }) => signOff(fbAuth))
    .then(() => true)
}

export default wrapSignOff
