import { isNumber } from '../data/is_number'
import getPropertyValue from '../../utils/document/get_property_value'

const sortIt = sortBy => docs => {
  if (docs.length <= 1) {
    return docs
  }

  const firstDoc = docs[0]

  const sortFields = getSortFields(sortBy)
  const sortFns = sortFields.map(x => {
    return isNumber(getPropertyValue(firstDoc)(x.field)(''))
      ? sortByNumber(x)
      : sortByString(x)
  })

  return docs.sort(sortTask(sortFns))
}

const sortTask = sortFns => (a, b) => {
  return sortFns.reduce((accum, fn) => (accum === 0 ? fn(a, b) : accum), 0)
}

const getSortFields = sortBy => {
  return getFields(sortBy).map(getFieldAndDirection)
}

const getFields = data => data.split(',')

const getFieldAndDirection = data => {
  const [field, direction] = data.split(':')
  return { field, direction, isAscending: direction === 'a' }
}

const sortByNumber = field => (a, b) => {
  const aValue = getPropertyValue(a)(field.field)(0)
  const bValue = getPropertyValue(b)(field.field)(0)

  return field.isAscending ? aValue - bValue : bValue - aValue
}

const sortByString = field => (a, b) => {
  const aValue = getPropertyValue(a)(field.field)('').toString().toLowerCase()
  const bValue = getPropertyValue(b)(field.field)('').toString().toLowerCase()
  const direction = field.isAscending ? 1 : -1

  if (aValue > bValue) {
    return direction * 1
  }

  if (aValue < bValue) {
    return direction * -1
  }

  return 0
}

export default sortIt
