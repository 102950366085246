import { isEmpty } from './../../utils/data/is_empty'
import { isBlank } from './../../utils/data/is_blank'

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  if (typeof fieldValue === 'string' && isBlank(fieldValue)) {
    return true
  }

  const useDate = fieldValue.getTime ? fieldValue : new Date(fieldValue)

  if (useDate.toString() === 'Invalid Date') {
    return { fieldError: 'system.errors.invalid' }
  }

  if (isNaN(useDate.getTime())) {
    return { fieldError: 'system.errors.invalid' }
  }

  return true
}

export default validate
