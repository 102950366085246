import isProperty from './is_property'
import setProperty from './set_property_value'

import cloneDocument from './clone_document'

/**
 * Set the value of a property
 *
 * Property must exist. Otherwise no changes are made.
 *
 * Nested properties can be accessed using a comma delimited list of property
 * names:
 *  - name
 *  - address.street
 *  - favourite.colours.1
 *
 * Property must exists otherwise no changes will be made.
 *
 * If document is null, undefined, or empty then an empty document {}
 * is returned
 *
 * @param {Object}  doc document
 * @param {String} property property
 * @param {Any} defaultValue default value
 * @returns {Object} updated document
 */
const setPropertyValue = doc => property => value => {
  return isProperty(doc)(property)
    ? setProperty(doc)(property)(value)
    : cloneDocument(doc)
}

export default setPropertyValue
