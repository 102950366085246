import * as auth from '../../../resources/auth/store/auth.store'
import * as category from '../../../resources/categories/store/category.store'
import * as error from '../../../widgets/errors/store/error.store'
import * as locale from '../../../system/labels/store/locale.store'
import * as notification from '../../../widgets/notifications/store/notification.store'
import * as survey from '../../../resources/surveys/store/survey.store'

const setClientStore = () => {
  return {
    auth,
    category,
    error,
    locale,
    notification,
    survey
  }
}

export default setClientStore
