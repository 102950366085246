import AuthenticationError from './authentication.error'
import AuthorizationError from './authorization.error'
import DatabaseError from './database.error'
import isKnownError from './is_known_error'
import NotFoundError from './not_found.error'
import ServerError from './server.error'
import ValidationError from './validation.error'

const ERRORS = {
  notAuthenticated: AuthenticationError,
  notAuthorized: AuthorizationError,
  databaseError: DatabaseError,
  notFound: NotFoundError,
  serverError: ServerError,
  validationError: ValidationError
}

const buildError = errorCode => err => {
  if (isKnownError(err)) {
    return err
  }

  const ErrorObject = ERRORS[errorCode] || ERRORS.serverError
  return err instanceof Error
    ? new ErrorObject({ message: err.message })
    : new ErrorObject(err)
}

export default buildError
