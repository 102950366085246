const getEditAction = readDocumentFn => (ctx, payload) => {
  const { commit } = ctx
  const { id } = payload
  return readDocumentFn(id).then(doc => {
    commit('setEditDocument', { doc })
    return doc
  })
}

export default getEditAction
