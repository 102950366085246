const getViewMutations = () => {
  const setView = (state, { view }) => {
    if (!view || !view.id) {
      return
    }

    state.view[view.id] = view
  }

  return { setView }
}

export default getViewMutations
