import validateAtLeastOne from './validate_at_least_one_item'
import validateInList from './validate_in_list'
import validateDateMinMax from './validate_date_min_max'
import validateDateRange from './validate_date_range'
import validateRange from './validate_range'
import validateMinLteMax from './validate_min_lte_max'
import validateRequired from './validate_required'
import validateUnknown from './validate_unknown'
import validateFileUpload from './validate_file_upload'

const VALIDATORS = {
  atleastone: validateAtLeastOne,
  dateminmax: validateDateMinMax,
  daterange: validateDateRange,
  fileupload: validateFileUpload,
  inlist: validateInList,
  minltemax: validateMinLteMax,
  range: validateRange,
  required: validateRequired,
  unknown: validateUnknown
}

const getValidator = item => {
  const { rule, ...options } = { rule: 'UNKNOWN', ...item }
  if (VALIDATORS[rule.toLowerCase()]) {
    return VALIDATORS[rule.toLowerCase()](options)
  }

  return VALIDATORS.unknown(item)
}

export default getValidator
