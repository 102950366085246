import Home from './Home.vue'
import Closed from './Closed.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuthentication: true }
  },

  {
    path: '/closed',
    name: 'closed',
    component: Closed,
    meta: { layout: 'GuestLayout' }
  }
]

export default routes
