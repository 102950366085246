<template lang="html">
  <dy-base-view>
    <dy-base-view-item :schema="schema" :doc="doc" fieldName="id" />

    <dy-base-view-item :schema="schema" :doc="doc" fieldName="name" />

    <dy-base-view-slot>
      <template v-slot:label>{{ $t('category.fields.products') }}</template>
      <template v-slot:value>
        <ol class="list--outside-alpha">
          <li v-for="product in doc.products" :key="product.id">
            {{ product.name }}
          </li>
        </ol>
      </template>
    </dy-base-view-slot>

    <dy-base-view-item :schema="schema" :doc="doc" fieldName="seq" />

    <dy-base-view-datetime :schema="schema" :doc="doc" fieldName="createdOn" />

    <dy-base-view-datetime :schema="schema" :doc="doc" fieldName="updatedOn" />

    <dy-base-view-button @click="runReturn">
      {{ $t('system.actions.return') }}
    </dy-base-view-button>
  </dy-base-view>
</template>

<script>
  import getFieldSchema from './../../../services/schema/get_field_schema'

  export default {
    name: 'CategoryView',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      }
    },

    emits: ['runReturn'],

    methods: {
      productSchema(idx) {
        return getFieldSchema(this.schema)(this.doc)(`products.${idx}`)
      },

      runReturn() {
        return this.$emit('runReturn')
      }
    }
  }
</script>
