import { SURVEY_ROUTE } from './../../../setup/collection.constants'

import ListSurveys from './ListSurveys.vue'
import ViewSurvey from './ViewSurvey.vue'
import EditSurveyUser from './EditSurveyUser.vue'
import EditSurvey from './EditSurvey.vue'
import Finished from './Finished.vue'

const routes = [
  {
    path: `/${SURVEY_ROUTE}`,
    name: SURVEY_ROUTE,
    component: ListSurveys,
    meta: { requiresSuperUser: true, requiresAdministrator: true }
  },

  {
    path: `/${SURVEY_ROUTE}/:id`,
    name: `${SURVEY_ROUTE}:view`,
    component: ViewSurvey,
    props: true,
    meta: { requiresSuperUser: true, requiresAdministrator: true }
  },

  {
    path: `/${SURVEY_ROUTE}/:id/user`,
    name: `${SURVEY_ROUTE}:user`,
    component: EditSurveyUser,
    props: true,
    meta: { requiresAuthentication: true }
  },

  {
    path: `/${SURVEY_ROUTE}/:id/edit`,
    name: `${SURVEY_ROUTE}:edit`,
    component: EditSurvey,
    props: true,
    meta: { requiresAuthentication: true }
  },

  {
    path: `/${SURVEY_ROUTE}/:finishedUid/finished`,
    name: `${SURVEY_ROUTE}:finished`,
    component: Finished,
    props: true,
    meta: { layout: 'GuestLayout' }
  }
]

export default routes
