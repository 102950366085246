// 1-905-263-4098 x123

import { isEmpty } from './../../utils/data/is_empty'
import { isBlank } from './../../utils/data/is_blank'

const transform = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return null
  }

  if (isBlank(fieldValue)) {
    return ''
  }

  let useValue = fieldValue
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\(\s*/g, '')
    .replace(/\s*\)\s*-*\s*/g, '-')
    .replace(/\s*-\s*/g, '-')
    .replace(/,/g, '')
    .replace(/:/g, '')
    .replace(/ext(\.)*/g, 'x')
    .replace(/poste(\.)*/g, 'x')
    .replace(/x(\.)*/g, 'x')
    .replace(/\s+x\s*/g, 'x')
    .replace(/\./g, '-')
    .replace(/(\s)(\1)+/g, '-')
    .replace(/\s/g, '-')

  // split on 'x' to obtain the extension
  let tokens = useValue.split('x')
  const extension = tokens.length > 1 ? tokens.pop() : ''

  tokens = tokens.filter(x => isEmpty(x) === false).join('')

  if (/^\d{10}$/.test(tokens)) {
    useValue = `1-${tokens.substr(0, 3)}-${tokens.substr(3, 3)}-${tokens.substr(
      6,
      4
    )}`
  } else if (/^\d{11}$/.test(tokens)) {
    useValue = `${tokens.substr(0, 1)}-${tokens.substr(1, 3)}-${tokens.substr(
      4,
      3
    )}-${tokens.substr(7, 4)}`
  } else {
    useValue = tokens
  }

  if (/^\d{3}-\d{3}-\d{4}$/.test(useValue)) {
    useValue = `1-${useValue}`
  }

  useValue = useValue.toUpperCase()

  return extension ? `${useValue} x${extension}` : useValue
}

export default transform
