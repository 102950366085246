<template>
  <article class="article">
    <header class="article-header">
      <h1>{{ $t('category.documents.category') }}</h1>
    </header>

    <dy-base-suspense>
      <template v-slot:default>
        <dy-category :schema="schema" :doc="doc" @runReturn="runReturn" />
      </template>
    </dy-base-suspense>
  </article>
</template>

<script>
  import setupViewPage from './../../../widgets/page/view/setup_view_page'

  import { Schema } from './../category.schema'
  import CategoryView from './../components/CategoryView'

  export default {
    name: 'ViewOutbox',

    props: {
      id: {
        type: String,
        required: true
      }
    },

    setup(props) {
      const { schema, doc, runReturn } = setupViewPage('view')(Schema)(props)

      return { schema, doc, runReturn }
    },

    components: {
      'dy-category': CategoryView
    }
  }
</script>
