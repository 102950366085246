import getProperty from './../../utils/document/get_property_value'
import runInSequence from './../../utils/promises/promise_sequential'

const DEFAULT_VALUE = null

/**
 * Run a validate function for a field passing the document and field value.
 *
 * If validation passes then return true; otherwise return an error:
 *  { fieldError: 'system.errors.required', fieldLabel: 'system.field.fieldName' },
 *
 * Returns a promise
 *
 * @param {object}   doc         document
 * @param {any}      fieldValue  field value
 * @param {function} fn          validate function
 * @return {boolean | object}    true if validation passess; otherwise an error.
 */
const runValidation = doc => fieldValue => fn => {
  return Promise.resolve(fn({ doc, fieldValue }))
}

/**
 * Run list of validate functions for a field.
 *
 * If validation passes then return true; otherwise return an error:
 * {
 *  fieldName: [
 *    { fieldError: 'system.errors.required', fieldLabel: 'system.field.fieldName' },
 *    { fieldError: 'system.errors.xxxx', fieldLabel: 'system.field.fieldName' },
 *  ]
 * }
 *
 * Returns a promise
 *
 * @param {object}   doc         document
 * @param {string}   fieldName   field
 * @param {string}   fieldLabel  field label
 * @param {string}   fieldValue  field value
 * @param {arrat}    fns         list of validate functions for field
 * @return {boolean | object}    true if validation passess; otherwise an error
 */

const validateField =
  doc => fieldName => fieldLabel => fieldValue => fns => () => {
    return Promise.resolve(fns.map(fn => runValidation(doc)(fieldValue)(fn)))
      .then(tasks => Promise.all(tasks))
      .then(results => {
        const errors = results
          .filter(x => x !== true)
          .map(x => {
            return { fieldLabel, ...x }
          })

        if (errors.length === 0) {
          return true
        }

        const result = {}
        result[fieldName] = errors
        return result
      })
  }

/**
 * validate a document
 *
 * apply list of validations to a document.
 *
 * Return true if no validation errors.
 *
 * Return a validation error object if one or more valdiation errors are detected.
 *
 *
 * {
 *  fieldName1: [{ key: 'system.errors.required, fieldLabel: 'system.field.fieldName1 }],
 *  fieldName2: [{ key: 'system.errors.required, fieldLabel: 'system.field.fieldName2 }]
 * }
 *
 * @param  {object} schema    document schema
 * @param  {object} doc       document
 * @return {boolean | object} true if no validation error; otherwise error object
 */
const validateDocument = doc => validators => {
  return Promise.resolve(true)
    .then(() => {
      const tasks = Object.getOwnPropertyNames(validators).map(fieldName => {
        const fieldLabel = validators[fieldName].fieldLabel
        const fns = validators[fieldName].fieldValidators

        if (fieldName === 'root') {
          return validateField(doc)('root')(fieldLabel)(doc)(fns)
        }

        const fieldValue = getProperty(doc)(fieldName)(DEFAULT_VALUE)
        return validateField(doc)(fieldName)(fieldLabel)(fieldValue)(fns)
      })

      return runInSequence(tasks)
    })
    .then(results => {
      const errors = results.reduce((result, error) => {
        if (error !== true) {
          result = { ...result, ...error }
        }
        return result
      }, {})

      return Object.getOwnPropertyNames(errors).length === 0 ? true : errors
    })
}

export default validateDocument
