import isValidationError from './../../../errors/is_validation_error'

const getUpdateAction = updateDocumentFn => (ctx, payload) => {
  const { doc } = payload

  return updateDocumentFn(doc)
    .then(updatedDoc => {
      ctx.commit('setDocument', { doc: updatedDoc })
      ctx.commit('setEditDocument', { doc: updatedDoc })
      return updatedDoc
    })
    .catch(err => {
      if (isValidationError(err)) {
        const payload = {
          doc: err.data.doc,
          errors: err.data.errors
        }
        ctx.commit(`setEditDocument`, payload)
        return doc
      }

      throw err
    })
}

export default getUpdateAction
