import { isEmpty } from '../../utils/data/is_empty'
import { isArray } from '../../utils/data/is_array'

const validate = options => payload => {
  const { fieldValue } = payload
  const label = options.label || 'system.labels.unknown'

  if (isEmpty(fieldValue)) {
    return true
  }

  if (isArray(fieldValue) === false) {
    return {
      fieldError: `atLeastOneItem: value must be a array - ${typeof fieldValue}`
    }
  }

  if (fieldValue.length >= 1) {
    return true
  }

  return {
    fieldError: 'system.errors.atLeastOneItem',
    itemLabel: label
  }
}

export default validate
