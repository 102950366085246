<template>
  <dy-base-form-field :isError="isError" :isStacked="isStacked">
    <template v-if="fieldLabel" #label>
      <label :for="fieldId">{{ fieldLabel }}</label>
    </template>

    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>

    <div class="checkbox">
      <span class="checkbox__input">
        <input
          :id="fieldId"
          :name="fieldName"
          :value="fieldValue"
          :checked="isChecked"
          type="checkbox"
          @change="updateFieldValue($event)"
        />
        <span class="checkbox__control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
    </div>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from '../setup_form_field.mixin'

  export default {
    name: 'BaseToggle',

    mixins: [SetupFormField],

    computed: {
      isChecked() {
        return this.fieldValue === 'true' || this.fieldValue === true
      }
    }
  }
</script>
