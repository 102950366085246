import validateArray from './validate_array'
import validateBoolean from './validate_boolean'
import validateDate from './validate_date'
import validateDatetime from './validate_datetime'
import validateEmail from './validate_email'
import validateNumber from './validate_number'
import validateObject from './validate_object'
import validatePhone from './validate_phone'
import validatePostalCode from './validate_postal_code'
import validateRid from './validate_rid'
import validateString from './validate_string'
import validateUid from './validate_uid'
import validateUrl from './validate_url'
import validateUnknown from './validate_unknown'

const DEFAULT_VALIDATORS = {
  array: validateArray,
  boolean: validateBoolean,
  code: validateString,
  date: validateDate,
  datetime: validateDatetime,
  email: validateEmail,
  number: validateNumber,
  object: validateObject,
  phone: validatePhone,
  postalCode: validatePostalCode,
  rid: validateRid,
  string: validateString,
  uid: validateUid,
  url: validateUrl,
  unknown: validateUnknown
}

const getDefaultValidator = fieldType => {
  return (
    DEFAULT_VALIDATORS[fieldType] || DEFAULT_VALIDATORS.unknown({ fieldType })
  )
}

export default getDefaultValidator
