import { isNull } from './is_null'
import { isUndefined } from './is_undefined'

function isEmpty(value) {
  return isUndefined(value) || isNull(value)
}

function isNotEmpty(value) {
  return !isEmpty(value)
}

export { isEmpty, isNotEmpty }
