import { isBlank, isNotBlank } from '../../utils/data/is_blank'
import pipe from '../../utils/fp/pipe'

import { getLocales } from './label.schema'
import translateError from './translate.error'

const DEFAULT_LOCALE = getLocales()[0]
const DEFAULT_KEY = 'system.errors.unknownKey'

/**
 * Translate a message key.
 *
 * @param  {Object} messages messages
 * @param  {String} locale current locale
 * @param  {String} key message key
 * @param  {Object} data replaceable parameters for message
 * @return {String}      localized message
 */
const translate =
  messages =>
  locale =>
  key =>
  (params = {}) => {
    try {
      return pipe(
        setLocale,
        setKey,
        assertField,
        formatKey,
        assertLocaleFound(messages),
        assertLabelFound(messages),
        formatStatusLabel(params),
        formatCountLabel(params),
        assignParameters(params),
        getLabel
      )(setup(locale, key))
    } catch (err) {
      if (err.message === 'label.errors.translate') {
        return err.label
      }

      return DEFAULT_KEY
    }
  }

const setup = (locale, key) => {
  return {
    locale,
    key,
    label: ''
  }
}

const setLocale = data => {
  return {
    ...data,
    locale: getLocales().includes(data.locale) ? data.locale : DEFAULT_LOCALE
  }
}

const setKey = data => {
  return {
    ...data,
    key: isNotBlank(data.key) ? data.key : DEFAULT_KEY
  }
}

const assertField = data => {
  if (data.key.hasOwnProperty('en') && data.key.hasOwnProperty('fr')) {
    throw new translateError({ label: data.key[data.locale] })
  }

  return data
}

const formatKey = data => {
  return {
    ...data,
    key: data.key.toString().trim().toLowerCase()
  }
}

const assertLocaleFound = messages => data => {
  if (isBlank(messages[data.locale])) {
    throw new translateError({ label: data.key })
  }
  return data
}

const assertLabelFound = messages => data => {
  if (isBlank(messages[data.locale][data.key])) {
    throw new translateError({ label: data.key.split('.').pop() })
  }
  return {
    ...data,
    label: messages[data.locale][data.key]
  }
}

const formatStatusLabel = params => data => {
  return {
    ...data,
    label: isNotBlank(params.iStatus)
      ? getLabelByState(data.label)(params.iStatus)
      : data.label
  }
}

const formatCountLabel = params => data => {
  return {
    ...data,
    label: isNotBlank(params.iCount)
      ? getLabelByState(data.label)(params.iCount === 1 ? 'one' : 'other')
      : data.label
  }
}

const getLabelByState = label => state => {
  const buffer = label
    .split(';')
    .filter(x => x.trim().indexOf(`${state}=`) === 0)
    .pop()

  if (!buffer) {
    return label
  }

  return buffer.trim().substring(state.length + 1)
}

const assignParameters = params => data => {
  return {
    ...data,
    label: Object.getOwnPropertyNames(params).reduce((accum, fieldName) => {
      const value = isBlank(params[fieldName]) ? 'UNKNOWN' : params[fieldName]
      return accum.replace(`{{${fieldName}}}`, value)
    }, data.label)
  }
}

const getLabel = data => data.label

export default translate
