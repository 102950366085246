<template lang="html">
  <dy-base-form>
    <dy-base-input
      v-if="isSuperUser"
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="id"
      :disabled="true"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="email"
      maxlength="120"
      :disabled="true"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="firstName"
      maxlength="60"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="lastName"
      maxlength="60"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="title"
      maxlength="80"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="company"
      maxlength="80"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-select
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="companyType"
      :options="companyTypes"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="city"
      maxlength="80"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="postalCode"
      maxlength="7"
      @updateFieldValue="runUpdateFieldValue"
    />

    <dy-base-toggle
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="newsletter"
      @updateFieldValue="runUpdateFieldValue"
    />

    <template #buttons>
      <dy-base-save-button @click="runUpdate">
        {{ $t('system.actions.save') }}
      </dy-base-save-button>

      <dy-base-save-button @click="runNext" :disabled="isSurveySetup === false">
        {{ $t('system.actions.next') }}
      </dy-base-save-button>

      <dy-base-button @click="runCancel">
        {{ $t('system.actions.cancel') }}
      </dy-base-button>
    </template>
  </dy-base-form>
</template>

<script>
  import { getCompanyTypes } from './../survey.schema'

  import { isSurveySetup } from './../survey'

  export default {
    name: 'SurveyUserForm',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      },

      docErrors: {
        type: Object,
        required: true
      }
    },

    emits: ['runCancel', 'runNext', 'runUpdateFieldValue', 'runUpdate'],

    computed: {
      isSuperUser() {
        return this.$store.getters['auth/isSuperUser']
      },

      companyTypes() {
        return getCompanyTypes()
      },

      isSurveySetup() {
        return isSurveySetup(this.doc)
      }
    },

    methods: {
      runCancel() {
        this.$emit('runCancel')
      },

      runUpdateFieldValue(payload) {
        this.$emit('runUpdateFieldValue', payload)
      },

      runUpdate() {
        this.$emit('runUpdate')
      },

      runNext() {
        this.$emit('runNext')
      }
    }
  }
</script>
