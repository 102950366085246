import cloneDocument from './../../utils/document/clone_document'

import getProperty from './../../utils/document/get_property_value'
import isProperty from './../../utils/document/is_property'
import setProperty from './../../utils/document/set_property_value'

const DEFAULT_VALUE = undefined

const runTransformers = transformers => doc => fieldValue => {
  try {
    return transformers.reduce((result, transformer) => {
      return transformer({ doc, fieldValue: result })
    }, fieldValue)
  } catch (err) {
    return fieldValue
  }
}

/**
 * transform a document.
 *
 * apply transforms to a document.
 *
 * transforms are defined in the document schema.
 *
 * Return an updated document.
 *
 * @param  {object} doc          document
 * @param  {object} transformers list of transforms for a document
 * @return {object}              updated document
 */
const transformDocument = doc => transformers => {
  return new Promise((resolve, reject) => {
    return Promise.resolve(cloneDocument(doc || {}))
      .then(useDoc => {
        let updatedDoc = useDoc
        Object.getOwnPropertyNames(transformers).forEach(fieldName => {
          if (isProperty(updatedDoc)(fieldName)) {
            const fieldValue = getProperty(updatedDoc)(fieldName)(DEFAULT_VALUE)
            const transformedValue = runTransformers(transformers[fieldName])(
              updatedDoc
            )(fieldValue)
            updatedDoc = setProperty(updatedDoc)(fieldName)(transformedValue)
          }
        })

        return resolve(updatedDoc)
      })
      .catch(err => {
        return reject(err)
      })
  })
}

export default transformDocument
