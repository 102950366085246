import { getApplication } from '../../../database_services/fbclient/fb_client'
import createDocument from '../../../database_services/fbclient/create/create_document'

import validateDocument from './validate_document.wrapper'

import getCollectionName from './../../../services/schema/get_document_collection'

export const wrapCreateDocument = schemaFn => document => {
  return validateDocument(schemaFn)(document)
    .then(() => getApplication())
    .then(({ fdb }) => {
      const schema = schemaFn(document)
      const collectionName = getCollectionName(schema)
      return createDocument(fdb)(collectionName)(document)
    })
    .catch(err => {
      throw err
    })
}

export default wrapCreateDocument
