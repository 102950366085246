<template>
  <article class="article">
    <header class="article-header">
      <h1>{{ $t('report.titles.surveySummary') }}</h1>
    </header>

    <dy-base-suspense>
      <template v-slot:default>
        {{ $t('survey.documents.surveys') }}: {{ doc.surveyCount }} <br />
        {{ $t('report.titles.surveysCompleted') }}: {{ doc.surveysCompleted }}
        <br />
        {{ $t('report.titles.surveysWithOneVote') }}:
        {{ doc.surveysWithAtLeastOneVote }}
        <br />
        {{ $t('report.titles.surveysWithNoVotes') }}:
        {{ doc.surveysWithNoVotes }} <br />
        {{ $t('report.titles.totalVotes') }}: {{ doc.voteCount }} <br />
      </template>
    </dy-base-suspense>
  </article>
</template>

<script>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'

  import runFunction from './../../../setup/client/crud/run_function.wrapper'

  export default {
    name: 'SurveySummary',

    setup() {
      const doc = ref({})

      const store = useStore()
      const router = useRouter()

      store.commit('SET_LOADING')
      runFunction('surveySummary')({})
        .then(summary => {
          doc.value = summary
          store.commit('CLEAR_LOADING')
          return true
        })
        .catch(err => {
          store.commit('SET_ERROR', err)
          router.push({ name: 'error' })
          store.commit('CLEAR_LOADING')
          return false
        })

      return { doc }
    }
  }
</script>
