import getProperty from './../../utils/document/get_property_value'
import { isEmpty } from '../../utils/data/is_empty'

const isArrayItem = field => /^\d+$/.test(field)

const isConditionalSchema = schema =>
  !!schema && schema.dySchema && schema.dySchemaOptions

const schemaReducer = (accum, field) => {
  if (accum.schema === false) {
    return accum
  }

  accum.doc = getProperty(accum.doc)(field)(null)

  if (isArrayItem(field) === false) {
    accum.schema = isEmpty(accum.schema[field]) ? false : accum.schema[field]
  }

  if (isConditionalSchema(accum.schema)) {
    const option = accum.schema.dySchema({ doc: accum.doc })
    if (option && accum.schema.dySchemaOptions[option]) {
      accum.schema = accum.schema.dySchemaOptions[option]
    }
  }

  return accum
}

const getFieldSchema = schema => doc => fields => {
  const fieldSchema = (fields || '')
    .split('.')
    .reduce(schemaReducer, { schema, doc })
  return fieldSchema.schema === false ? {} : fieldSchema.schema
}

export default getFieldSchema
