import { computed } from 'vue'
import { useStore } from 'vuex'

/**
 * Return computed properties to access a list of documents
 * - schema
 * - docs
 *
 * @param {Function} schemaFn function to return document schema
 * @returns {Object} document computed properties
 */
const getDocuments = schemaFn => {
  const store = useStore()
  const storeName = schemaFn({}).dyStore

  const schema = computed(() => {
    return schemaFn({})
  })

  const docs = computed(() => {
    return store.getters[`${storeName}/getDocuments`]
  })

  const documentCount = computed(() => {
    return store.getters[`${storeName}/getDocumentCount`]
  })

  return {
    schema,
    docs,
    documentCount
  }
}

export default getDocuments
