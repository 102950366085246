<template lang="html">
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 150 160"
    style="enable-background: new 0 0 150 160"
    xml:space="preserve"
  >
    <g>
      <path
        class="datayak--st0"
        d="M73.5,75.03c0,4.24-2.48,7.67-5.54,7.67c-3.06,0-5.54-3.43-5.54-7.67c0-4.24,2.48-7.67,5.54-7.67
		C71.02,67.35,73.5,70.79,73.5,75.03z"
      />
      <path
        class="datayak--st0"
        d="M85.17,74.2c0,4.33-2.39,7.83-5.33,7.83c-2.95,0-5.33-3.51-5.33-7.83c0-4.33,2.39-7.83,5.33-7.83
		C82.78,66.37,85.17,69.88,85.17,74.2z"
      />
      <g>
        <path
          class="datayak--st1"
          d="M49.38,105.69l-13.71,3.92c-0.11,0.04-0.23,0.07-0.37,0.08c-0.76,0.34-1.2,0.7-1.33,1.08
			c-0.09,0.26-0.04,0.76,0.14,1.47c0.31,0.64,0.51,1.09,0.6,1.33l-3.08,0.84c-0.54-1.48-1.34-3.01-2.38-4.59l4.03-1.38l-1.23-0.38
			c-0.8-0.24-1.49-0.53-2.08-0.84c-0.59-0.32-1.11-0.73-1.55-1.22c-0.44-0.49-0.8-1.1-1.06-1.81c-0.37-1-0.46-1.9-0.27-2.7
			c0.19-0.8,0.58-1.49,1.18-2.05s1.35-1.02,2.25-1.37c1.27-0.47,2.58-0.71,3.91-0.72c1.33-0.01,2.52,0.26,3.55,0.8
			c1.03,0.55,1.75,1.37,2.16,2.48c0.48,1.31,0.59,2.78,0.34,4.4l-0.16,0.89l4.15-1.34l-0.92-2.93l3.2-0.63
			C47.37,102.74,48.24,104.29,49.38,105.69z M36.44,107.14l1.26,0.03l-0.01-0.95c0.01-0.68-0.02-1.28-0.1-1.79
			c-0.08-0.51-0.22-1.13-0.43-1.85c-0.28-0.77-0.71-1.44-1.27-2c-0.56-0.56-1.19-0.94-1.89-1.15c-0.7-0.21-1.39-0.2-2.06,0.03
			c-0.01,0.02-0.02,0.04-0.03,0.06c-0.62,0.25-0.99,0.73-1.1,1.42c-0.12,0.7-0.04,1.42,0.24,2.18c0.45,1.22,1.18,2.19,2.21,2.9
			C34.3,106.75,35.36,107.12,36.44,107.14z"
        />
        <path
          class="datayak--st1"
          d="M48.31,120.79c-0.3,0.36-0.53,0.61-0.69,0.76c-0.47,0.57-0.73,1.04-0.78,1.41c-0.05,0.38,0.16,0.77,0.61,1.18
			c0.23,0.13,0.47,0.29,0.7,0.49l0.53,0.31c-1.1,0.94-1.91,1.68-2.43,2.2c-0.39-0.27-0.8-0.53-1.23-0.79
			c-0.43-0.26-0.92-0.55-1.47-0.87c-0.54-0.32-0.97-0.56-1.26-0.74l2.68-2.98c-1,0.35-1.87,0.59-2.61,0.72
			c-0.74,0.13-1.49,0.12-2.25-0.06c-0.76-0.18-1.52-0.58-2.28-1.21c-1.13-1.07-1.67-2.24-1.62-3.52c0.05-1.28,0.57-2.5,1.55-3.67
			c0.87-1.05,1.88-1.9,3.04-2.55c1.15-0.65,2.31-0.99,3.49-1c1.18-0.01,2.23,0.37,3.17,1.15c0.33,0.28,0.63,0.59,0.88,0.94
			c0.25,0.34,0.46,0.69,0.62,1.03c0.16,0.34,0.31,0.71,0.46,1.1c0.15,0.4,0.31,0.84,0.49,1.32l0.93-0.89l1.45,1.15L48.31,120.79z
			 M47,119.01l0.92-0.5c-0.78-1.8-1.78-3.25-2.99-4.35c-0.5-0.42-1.07-0.68-1.68-0.79c-0.62-0.1-1.22-0.04-1.82,0.17
			c-0.6,0.22-1.11,0.58-1.54,1.07c0.01,0.01,0.02,0.03,0.02,0.04c0,0.01-0.01,0.02-0.01,0.03c-0.9,1.07-0.6,2.23,0.88,3.46
			c0.63,0.5,1.3,0.88,2.02,1.14c0.72,0.26,1.45,0.37,2.17,0.33C45.7,119.58,46.37,119.38,47,119.01z"
        />
        <path
          class="datayak--st1"
          d="M56.03,118.2l1.91-3.72l2.04,0.38l-1.97,4.02l4.12,0.93c-0.69,1.04-1.31,2.16-1.86,3.34l-3.65-1.34
			l-0.37,0.74c-0.12,0.33-0.32,0.78-0.6,1.34c-0.35,0.94-0.46,1.68-0.35,2.21c0.11,0.53,0.45,0.91,1,1.15
			c0.69,0.2,1.76,0.27,3.22,0.2l-1.03,2.8c-0.58,0.16-1.16,0.31-1.74,0.46c-0.58,0.15-1.15,0.23-1.71,0.24
			c-0.56,0.01-1.12-0.09-1.68-0.29c-0.89-0.35-1.37-0.97-1.44-1.86c-0.08-0.89,0.1-1.95,0.52-3.17c0.23-0.57,0.55-1.28,0.95-2.11
			c0.4-0.83,0.65-1.36,0.74-1.59l0.44-0.93l-1.71-0.84l1.26-2.87L56.03,118.2z"
        />
        <path
          class="datayak--st1"
          d="M73.2,128.44c-0.08,0.46-0.16,0.79-0.23,1c-0.13,0.73-0.12,1.26,0.03,1.61c0.15,0.35,0.52,0.59,1.12,0.72
			c0.27,0,0.55,0.02,0.85,0.08l0.61,0.01c-0.48,1.36-0.82,2.4-1.02,3.12c-0.47-0.04-0.96-0.06-1.46-0.07
			c-0.5-0.01-1.07-0.02-1.7-0.02c-0.63,0-1.12-0.01-1.46-0.01l0.84-3.92c-0.7,0.8-1.33,1.44-1.9,1.92
			c-0.57,0.49-1.23,0.84-1.98,1.07c-0.75,0.23-1.61,0.25-2.58,0.08c-1.52-0.36-2.57-1.11-3.16-2.25c-0.59-1.13-0.75-2.45-0.49-3.96
			c0.24-1.34,0.69-2.58,1.37-3.72c0.67-1.14,1.52-2.01,2.53-2.6c1.02-0.6,2.12-0.79,3.32-0.58c0.43,0.08,0.84,0.2,1.23,0.37
			c0.39,0.17,0.74,0.37,1.04,0.58c0.31,0.22,0.63,0.46,0.95,0.73c0.33,0.27,0.69,0.57,1.08,0.9l0.37-1.23l1.83,0.27L73.2,128.44z
			 M71.18,127.55l0.55-0.89c-1.57-1.17-3.16-1.93-4.76-2.29c-0.65-0.11-1.26-0.06-1.85,0.15c-0.58,0.22-1.08,0.57-1.49,1.05
			c-0.41,0.49-0.68,1.05-0.81,1.69c0.02,0,0.03,0.01,0.04,0.02c0,0.01,0,0.02,0,0.03c-0.24,1.38,0.58,2.23,2.48,2.57
			c0.79,0.12,1.56,0.11,2.32-0.02c0.76-0.13,1.44-0.4,2.05-0.79C70.34,128.69,70.82,128.18,71.18,127.55z"
        />
        <path
          class="datayak--st1"
          d="M93.61,112.86l-2.1,13.47l3.01-1.43c0.36,1.31,0.8,2.47,1.31,3.5l-7.13,4.25l-1.88-4.1l2.38-1.03l0.21-4.16
			l-9.7-3.09l-1.57,0.63l-1.29-3.26l7.41-4.23l1.68,3.81l-2.72,1.26l6.54,2.66l0.57-6.5l-2.49,1.23l-1.72-3.24l7.29-4.41
			c0.38,1.33,0.93,2.66,1.66,4L93.61,112.86z"
        />
        <path
          class="datayak--st1"
          d="M106.66,111.22c0.33,0.34,0.55,0.59,0.68,0.77c0.51,0.53,0.95,0.84,1.32,0.93c0.37,0.09,0.78-0.07,1.24-0.48
			c0.16-0.22,0.34-0.43,0.56-0.64l0.37-0.49c0.82,1.19,1.46,2.08,1.92,2.66c-0.31,0.36-0.62,0.74-0.92,1.14
			c-0.3,0.4-0.64,0.86-1.02,1.36c-0.38,0.51-0.66,0.9-0.87,1.18l-2.67-2.99c0.24,1.03,0.38,1.92,0.44,2.67
			c0.05,0.75-0.05,1.49-0.3,2.23c-0.26,0.74-0.74,1.45-1.46,2.13c-1.19,1.01-2.41,1.42-3.67,1.23c-1.26-0.19-2.43-0.84-3.48-1.94
			c-0.95-0.98-1.68-2.08-2.21-3.3c-0.52-1.21-0.73-2.41-0.61-3.58c0.12-1.17,0.61-2.18,1.49-3.02c0.31-0.3,0.66-0.56,1.03-0.77
			c0.37-0.21,0.73-0.38,1.09-0.5c0.35-0.12,0.74-0.24,1.15-0.34c0.41-0.1,0.87-0.22,1.37-0.35l-0.78-1.02l1.3-1.32L106.66,111.22z
			 M104.75,112.32l-0.39-0.97c-1.87,0.58-3.42,1.42-4.65,2.5c-0.47,0.46-0.79,0.98-0.96,1.58s-0.18,1.21-0.02,1.82
			c0.15,0.62,0.45,1.16,0.89,1.65c0.01-0.01,0.03-0.02,0.04-0.01c0.01,0,0.02,0.01,0.03,0.02c0.97,1.01,2.15,0.84,3.54-0.49
			c0.56-0.57,1.01-1.2,1.35-1.89c0.34-0.69,0.53-1.4,0.57-2.12S105.05,112.99,104.75,112.32z"
        />
        <path
          class="datayak--st1"
          d="M101.45,97.11l9.21,7.17c-0.2-2.2-0.77-4.3-1.72-6.31l2.38-4c0.18,0.16,0.74,0.61,1.68,1.35
			c0.14,0.08,0.34,0.2,0.61,0.37c0.27,0.17,0.47,0.29,0.6,0.38c-0.05,0.07-0.29,0.36-0.72,0.87c-0.43,0.51-0.76,0.94-1,1.29
			c-0.49,0.72-0.82,1.44-0.98,2.16c-0.16,0.72-0.08,1.48,0.24,2.29c0.11-0.28,0.31-0.64,0.62-1.08c0.46-0.67,1.03-1.16,1.69-1.48
			c0.67-0.31,1.36-0.43,2.08-0.37c0.72,0.07,1.39,0.29,2.02,0.68l1.36-2.16l2.73,2.05c-0.63,0.93-1.29,2.14-1.95,3.64l-1.48-0.72
			c-2.97-1.36-5.07-1.14-6.32,0.68c-0.22,0.32-0.38,0.58-0.47,0.77l-0.22,0.5l2.29,1.75l1.25-1.91l2.52,1.95
			c-0.72,1.05-1.75,2.73-3.08,5.03l-2.95-2.2l1.2-1.48l-8.28-5.61c-0.47-0.32-0.92-0.44-1.33-0.33c-0.41,0.1-0.78,0.31-1.11,0.62
			c-0.32,0.31-0.68,0.72-1.07,1.22l-2.38-2.15C99.82,100.68,100.68,99.03,101.45,97.11z"
        />
      </g>
      <path
        class="datayak--st2"
        d="M77.89,102.97c-0.08,5.46-3,9.84-6.52,9.79c-3.52-0.05-6.31-4.52-6.22-9.98c0.08-5.46,3-9.84,6.52-9.79
		C75.18,93.04,77.97,97.51,77.89,102.97z"
      />
      <path
        class="datayak--st2"
        d="M69.47,73.07c-0.13,2.38-0.87,4.27-1.66,4.23s-1.33-2-1.21-4.38c0.13-2.38,0.87-4.27,1.66-4.23
		C69.06,68.73,69.6,70.69,69.47,73.07z"
      />
      <path
        class="datayak--st3"
        d="M40.89,24.88c1,0.2,2.01,0.41,3.01,0.61c6.8,2.51,7.29,4.54,9.11,11.02c0.03-0.08,0.06-0.16,0.08-0.23
		c-0.14,4.75,0.88,9.23,3.01,12.2c0.71,0.72,1.42,1.43,2.13,2.15c0.03,0.65,0.07,1.29,0.1,1.94c0.47,1.93,1.71,4.49,3.82,5.04
		c-0.03,0.1-0.06,0.19-0.09,0.29c-0.91,0.29-2.07,0.68-3.19,0.51c0,0.02-0.01,0.04-0.01,0.06c-0.01,0.08-0.02,0.16-0.02,0.24
		c0.75,0.92,2.67,0.79,3.22,1.48c-0.89,0.43-1.7,1.85-2.75,2.07c-0.8-0.24-1.6-0.49-2.4-0.73c-1.07-0.35-2.14-0.71-3.21-1.06
		c-3.8-1.95-7.38-5.51-7.91-10.35c-0.48-4.32,2.49-10.7,1.18-14.23c-1.97-5.3-7.05-6.48-10.59-10.09
		C37.67,25.12,39.32,25.25,40.89,24.88z"
      />
      <path
        class="datayak--st3"
        d="M111.8,30.29c0.08,0.03,0.15,0.07,0.23,0.1c-4,3.11-9.31,3.6-12.09,8.55c-1.54,2.75-0.38,8-0.38,10.95
		c0.01,9-8.81,14.06-16.49,12.68c0.93-1.05,2.84-1.67,2.92-3.65c-0.02,0-0.04-0.01-0.06-0.01c-1.6,0.24-4.32,0.66-6.15-0.3
		c0.01-0.04,0.01-0.08,0.02-0.12c0.58-0.65,1.15-1.31,1.73-1.96c-0.81,0.01-1.62,0.01-2.44,0.02c1.19-1.82,3.16-1.86,5.12-2.61
		c2.3-0.88,4.49-1.87,6.2-3.58c2.77-2.76,2.97-9.35,4.81-13.06c2.06-4.14,6.01-6.99,10.88-7.17C108,30.17,109.9,30.23,111.8,30.29z"
      />
      <g>
        <path
          class="datayak--st4"
          d="M59,59.05c0.43,0.11,0.86,0.23,1.3,0.34c-0.14,0.26-0.11,0.24,0.11,0.51c0.5,0.22,1,0.45,1.5,0.68
			c-0.01,0.06-0.02,0.12-0.03,0.18c-2.35,1.23-4.38,2.99-5.65,5.62c-0.83,1.7-0.76,3.91-0.8,5.89c-0.09,4.59-2.73,8.52-5.36,10.78
			c-2.46,2.11-10.15-0.11-9.59-1.67c0.9-0.4,1.81-0.79,2.71-1.19c2.21-1.16,4.13-3,5.73-4.94c2.04-2.46,1.42-6.94,2.77-9.94
			C53.41,61.47,56.03,61.03,59,59.05z"
        />
      </g>
      <path
        class="datayak--st5"
        d="M87.12,62.72c1.34,0.68,1.79,1.59,2.44,2.67c1.28,2.13,3.49,9.76,2.97,7.2c-0.16-0.8-0.81,2.65,1.03,9.24
		c0.41,1.46,1.49,2.78,2.44,3.94c-3.72-1.12-9.19-8-9.7-11.02c0.26-1.7,0.53-3.4,0.79-5.1C87.3,67.31,87.2,65.09,87.12,62.72z"
      />
      <path
        class="datayak--st5"
        d="M60.69,61.95c0.14,0.18-0.97,2.14-1.23,2.87c-0.19,1.25-0.37,2.49-0.56,3.74c-0.11,1.71-0.21,3.41-0.31,5.11
		c-0.35,0.53-1.43,1-1.9,1.6c-2.65,3.32-4.32,7.89-9.14,8.56c0.05-0.05,0.11-0.1,0.16-0.15c2.21-2.33,4.28-3.98,5.57-7.59
		c0.51-1.43,0.48-2.95,0.48-4.45c0.01-1.66,0.06-3.29,0.94-4.78c0.74-1.25,2.23-3.05,3.55-3.7C59.06,62.76,59.87,62.36,60.69,61.95z
		"
      />
      <path
        class="datayak--st4"
        d="M76.49,68.04c-0.62,2.7-1.17,5.34-0.93,8.12c0.16,0.98,0.33,1.96,0.49,2.94c-0.02,0.02-0.05,0.03-0.07,0.05
		c-1.94,0.06-3.87,0.12-5.81,0.18c0.63-2.01,1.65-4.1,1.63-6.42c-0.08-1.49-0.15-2.99-0.23-4.48c0.65,0.02,0.44-0.51-0.17-0.46
		c-0.76-2.9-5.72-2.93-6.38-2.29c-0.66,0.65-1.33,3.31-2.12,5.3c-0.81,0.45-0.68,0.78,0.05,0.43c-0.24,2.47-0.1,5.41,0.68,7.29
		c0.36,0.65,0.72,1.3,1.08,1.95c0,0.02-0.01,0.04-0.01,0.06c-1.76,0.62-3.53,1.23-5.29,1.85c-0.06-0.03-0.11-0.06-0.17-0.09
		c-1.41-4.74-1.94-14.24,1.07-19.9c1.37,0.09,4.03,0.15,4.02,0.19c-0.83,0.57,2.55-0.96,4.26-1.96c1-0.58,1.86-1.61,2.84-2.26
		c-0.07,0.71-0.1,2.09,0.46,2.39c5.02-4.6,5.29-0.62,10.81,0.42c1.78,0.34,2.65-0.87,3.83-0.88c0.65,0.15,1.3,0.3,1.95,0.45
		c2.8,0.17,4.33,1.03,5.6,2.89c3.82,5.61-1.17,15.5,6.12,19.16c1.8,0.91,3.98,0.46,5.73,0.66c0.03,0.08,0.05,0.17,0.08,0.26
		c-1.36,3.8-2.81,3.92-6.97,3.82c-1.55-0.04-3.16-0.38-3.97-1.22c-3.01-3.08-2.02-8.28-2.65-13.1c-0.32-2.46-1.3-4.87-2.28-6.89
		c-0.42-0.87-3.52-4.67-3.65-4.63c1.36,2.28,0.64,16.45-2.12,18.45c-0.99-0.23-1.97-0.45-2.96-0.68c0.87-2.42,2.14-4.74,2.73-7.82
		c0.1,0.02,0.48-0.29,0.05-0.66c0.43-3.78-5.74-5.71-5.74-5.71S75.84,67.87,76.49,68.04z"
      />
      <path
        class="datayak--st6"
        d="M85.02,57.3c2.89,0.69,5.08,1.2,7.46,0.96c-0.01,0.03-0.01,0.05-0.02,0.08c-2.77,5.29-7.5,5.46-12.26,2.16
		c-0.82-0.48-1.63-0.97-2.45-1.45c-0.8-0.55-5.53,2.25-5.81,2.41c-0.28,0.17-1.28-2.12-1.28-2.12s-1.96,1.32-2.53,1.82
		c-2.48,2.19-8.89,4.36-12.61,1.11c0.08-0.12,0.15-0.24,0.23-0.36c0.31-0.05,5.47-3.41,5.57-3.6c-1.59-0.06-3.7,0.24-5.09-1.07
		c1.63-0.48,3.22,0.1,4.37-1.2c-2.48-1.61-5.94-7.34-3.51-11.26c0.05,0.01,0.09,0.02,0.14,0.03c0.11,1.28,0.93,1.66,1.79,2.43
		c2.57,2.32,4.99,2.2,7.38,1.59c1.87-0.47,3.71-1.25,5.59-1.41c1.88-0.16,3.64,1.26,4.76,1.57c-0.31-1.59-0.99-4.9,0.58-6.76
		c0.05,0.01,0.09,0.02,0.14,0.03c-0.18,2.42,3.23,6.95,5.24,7.84c2.75-2.12,6.5-1.54,10.97-0.67c-1.65,1.51-9.27,1.65-9.28,5.44
		c0.03,0.06,0.07,0.12,0.1,0.18c0.76-0.1,1.28-0.38,2.21-0.39c0.02,0.03,0.04,0.06,0.05,0.09c-0.06,0.07-0.13,0.13-0.19,0.2
		C86,55.7,85.37,56.15,85.02,57.3z"
      />
      <g>
        <path
          class="datayak--st4"
          d="M76.42,104.06c0.33-1.64,0.73-3.2,1.65-4.21c0.91,0.2,1.76,0.37,2.29,0.87c-0.78,1.61-1.49,4.18-1.43,5.96
			c0.07,2.18,0.38,5.89-1.4,7.61c-1.31,1.27-3.19,1.45-5.12,1.51c-6.3,0.21-6.18-5.54-7.55-9.73c-0.59-1.8-1.65-3.86-2.82-5.28
			c0.01-0.04,0.01-0.08,0.02-0.12c0.97-0.54,3.17-1.41,4.26-1.11c0.57,0.89,1.32,1.6,1.61,2.71c0.65,2.49,1.18,11.02,5.79,8.27
			C75.59,109.43,75.91,106.65,76.42,104.06z"
        />
        <path
          class="datayak--st7"
          d="M79,79.59c1.79,0.36,3.57,0.72,5.36,1.08c1.16,0.48,2.31,0.96,3.47,1.44c4.89,3.49,12.51,12.46,4.88,18.71
			c-4.87,4-9.27,0.72-14.71-0.77c-1.44-0.4-2.96-0.67-4.58-0.64c-0.26,0-0.51,0.01-0.76,0.01c-3.17,0.06-5.94,0.22-8.73,1.07
			c-2.64,0.8-5.27,2.42-7.77,3.13c-1.76,0.5-5.54-0.93-6.38-2.23c-2.7-4.2-0.09-8.44,2.27-12.03c4.02-6.13,12.8-8.9,20.2-9.74
			C74.5,79.61,76.75,79.6,79,79.59z"
        />
      </g>
      <path
        class="datayak--st2"
        d="M81.42,73.65c-0.49,2.33-1.52,4.09-2.3,3.92c-0.78-0.16-1.01-2.18-0.51-4.52c0.49-2.33,1.52-4.09,2.3-3.92
		C81.68,69.3,81.91,71.33,81.42,73.65z"
      />
      <path
        class="datayak--st8"
        d="M80.53,73.98c-0.17,0.77-0.55,1.35-0.86,1.28c-0.3-0.06-0.41-0.75-0.24-1.52c0.17-0.78,0.55-1.35,0.86-1.28
		C80.59,72.52,80.7,73.2,80.53,73.98z"
      />
      <path
        class="datayak--st8"
        d="M68.71,73.45c-0.06,0.84-0.36,1.51-0.67,1.49c-0.31-0.02-0.51-0.72-0.46-1.57c0.06-0.84,0.36-1.51,0.67-1.49
		C68.56,71.9,68.77,72.61,68.71,73.45z"
      />
      <path
        class="datayak--st6"
        d="M63.38,68.02c0.94-2.18,2.83-3.68,4.86-4.08c2.66,0.35,4.16,2.15,3.9,4.42c0,0.02-0.01,0.05-0.01,0.07
		c-3.16,1-6.31,2.01-9.47,3.01c0.04-0.25,0.08-0.5,0.12-0.75C62.82,69.73,63.03,68.83,63.38,68.02z"
      />
      <path
        class="datayak--st6"
        d="M80.28,64.63c4,0.56,4.92,3.07,4.2,7.15c-0.02,0-0.05-0.01-0.07-0.01c-2.38-1.4-5.26-2.49-8.03-3.87
		C77.15,65.78,78.47,65.22,80.28,64.63z"
      />
      <g>
        <g>
          <path
            class="datayak--st2"
            d="M66.3,90.99c0,0,0.03-4.25-5.3-2.93c-3.49,2.97-1.35,5.5-1.35,5.5"
          />
        </g>
        <path
          class="datayak--st9"
          d="M57.95,95.18c3.29-3.97,10.03-4.53,10.03-4.53"
        />
      </g>
      <g>
        <path
          class="datayak--st2"
          d="M83.5,87.04c-3.48-0.5-4.45,3.13-4.45,3.13l6.85,2.24C85.91,92.4,87.5,88.1,83.5,87.04z"
        />
      </g>
      <path class="datayak--st9" d="M77.78,90.16c4.83-0.01,9.69,3.5,9.69,3.5" />
      <path
        class="datayak--st9"
        d="M79.23,62.72c2.29-1.04,6.37,1.81,6.94,3.73"
      />
      <path
        class="datayak--st9"
        d="M61.43,67.69c0.74-3.16,4.05-5.77,7.32-5.36"
      />
      <path
        class="datayak--st9"
        d="M59.26,73.13c2.38-1.28,4.94-1.69,7.36-2.66c2.04-0.82,4.82-1.91,7.02-1.62"
      />
      <path
        class="datayak--st9"
        d="M74.31,67.49c2.04,1.03,4.29,1.39,6.36,2.38c1.49,0.72,4.05,2.4,5.3,2.62"
      />
    </g>
  </svg>
</template>
