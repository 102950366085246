<template>
  <dy-base-form-field :isError="isError" :isStacked="isStacked">
    <template v-if="fieldLabel" #label>
      <label>{{ fieldLabel }}</label>
    </template>

    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>

    <template #default>
      <input
        v-bind="$attrs"
        :id="fieldId"
        :name="fieldName"
        :value="fieldValue"
        :placeholder="fieldPlaceholder"
        type="text"
        class="input"
        disabled="true"
      />
    </template>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from './setup_form_field.mixin'

  export default {
    name: 'BaseStaticText',

    mixins: [SetupFormField]
  }
</script>
