import SignOn from './SignOn.vue'
import SignOff from './SignOff.vue'
import Welcome from './Welcome.vue'

const routes = [
  {
    path: '/signon',
    name: 'signon',
    component: SignOn,
    meta: { requiresGuest: true, layout: 'AuthLayout' }
  },

  {
    path: '/signoff',
    name: 'signoff',
    component: SignOff,
    meta: { requiresAuthentication: true, layout: 'AuthLayout' }
  },

  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    meta: { requiresGuest: true, layout: 'AuthLayout' }
  }
]

export default routes
