<template>
  <input
    v-bind="$attrs"
    :checked="checked"
    :value="optionId"
    @change="updateFieldValue"
    type="radio"
  />
  <label>{{ $t(optionName) }}</label>
</template>

<script>
  export default {
    inheritAttrs: false,

    props: {
      optionId: {
        type: String,
        required: true
      },

      optionName: {
        type: String,
        required: true
      },

      checked: {
        type: Boolean,
        required: true
      }
    },

    emits: ['updateFieldValue'],

    methods: {
      updateFieldValue(evt) {
        console.log('event', evt.target)
        const payload = {
          optionId: evt.target.value
        }
        return this.$emit('updateFieldValue', payload)
      }
    }
  }
</script>
