/**
 * Sequentially run a list of promises and return results.
 *
 * Results are returned as an array. 1 item for each promise.
 *
 * reduce applies a function against an accumualtor and each element in
 * an array.
 *
 * - the accumlator (promiseResult) is an array of promise results.
 * - the initial value is a promise that returns an empty array.
 * - the element is a promise factory.
 *
 * the statement Array.prototype.concat.bind(result) is a shortcut for
 *   return promiseResult.then((results) => {
 *       return promiseFactory()
 *         .then((factoryResult) => {
 *            return results.concat(factoryResult);
 *         });
 *    });
 *
 *  it works because bind sets the this to promiseResult.
 *
 * @param  {function} promiseFactories - factory that returns a promise
 * @return {Array}                     - results for each promise
 */
const runInSequence = promiseFactories => {
  return promiseFactories.reduce((promiseResult, promiseFactory) => {
    return promiseResult.then(results => {
      return promiseFactory().then(result => {
        results.push(result)
        return results
      })
    })
  }, Promise.resolve([]))
}

export default runInSequence
