import { computed } from 'vue'
import { useStore } from 'vuex'

import i18n from './../translate'

export default {
  install: (app, _options) => {
    const locale = computed(() => {
      const store = useStore()
      const storeName = 'locale'
      return store.getters[`${storeName}/getCurrentLocale`] || 'en'
    })

    const messages = computed(() => {
      const store = useStore()
      const storeName = 'locale'
      return store.getters[`${storeName}/getMessages`]
    })

    function translate(key, params) {
      return i18n(messages.value)(locale.value)(key)(params)
    }

    app.config.globalProperties.$t = translate
    app.config.globalProperties.$locale = locale

    app.provide('i18n', {
      translate,
      locale,
      messages
    })
  }
}
