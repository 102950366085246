import { isEmpty } from './../../utils/data/is_empty'

import getFieldList from './../../services/schema/get_fields'
import { isPlainObject } from './../../utils/data/is_plain_object'

const checkForMissingFields = fieldSchema => fieldValue => {
  const fieldsInSchema = getFieldList(fieldSchema)

  const fieldsMissing = fieldsInSchema.reduce((result, field) => {
    if (Object.prototype.hasOwnProperty.call(fieldValue, field) === false) {
      result.push(field)
    }
    return result
  }, [])

  if (fieldsMissing.length > 0) {
    return {
      fieldError: 'system.errors.objectHasMissingFields',
      fields: fieldsMissing.join(',')
    }
  }

  return true
}

const checkForExtraFields = fieldSchema => fieldValue => {
  const fieldsInSchema = getFieldList(fieldSchema)

  const invalidFields = Object.getOwnPropertyNames(fieldValue).reduce(
    (result, field) => {
      if (fieldsInSchema.includes(field) === false) {
        result.push(field)
      }
      return result
    },
    []
  )

  if (invalidFields.length > 0) {
    return {
      fieldError: 'system.errors.objectHasExtraFields',
      fields: invalidFields.join(',')
    }
  }

  return true
}

const validate = fieldSchema => payload => {
  const { fieldValue } = payload
  if (isEmpty(fieldValue)) {
    return true
  }

  if (isPlainObject(fieldValue) === false) {
    return { fieldError: 'system.errors.notAnObject' }
  }

  const missingFields = checkForMissingFields(fieldSchema)(fieldValue)
  if (missingFields !== true) {
    return missingFields
  }

  const extraFields = checkForExtraFields(fieldSchema)(fieldValue)
  if (extraFields !== true) {
    return extraFields
  }

  return true
}

export default validate
