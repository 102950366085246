<template>
  <div class="poweredby" :alt="poweredBy" :title="poweredBy">
    <div class="poweredby__logo">
      <a href="https://www.datayak.com" target="_blank">
        <dy-datayak />
      </a>
    </div>
  </div>
</template>

<script>
  import DataYak from './DataYak.vue'

  export default {
    name: 'PoweredBy',

    props: {
      name: {
        type: String,
        required: true
      },

      version: {
        type: String,
        required: true
      }
    },

    computed: {
      poweredBy() {
        return `${this.name}: ${this.version}`
      }
    },

    components: {
      'dy-datayak': DataYak
    }
  }
</script>
