import {
  formatDate,
  formatDateTime,
  formatIt
} from '../../../utils/date/date.service'

/**
 * Register application filters
 *
 *
 * @param {Object} app Vue application
 */
const setFilters = app => {
  app.config.globalProperties.$filters = {
    formatDate,
    formatDateTime,
    formatMMMDD: value => formatIt('MMM dd')(value),
    formatName
  }
}

const formatName = doc => {
  return doc.initial
    ? `${doc.lastName}, ${doc.firstName} ${doc.initial}`
    : `${doc.lastName}, ${doc.firstName}`
}

export default setFilters
