import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'

const format = /^[a-z]\d[a-z]\s?\d[a-z]\d$/i

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  if (isBlank(fieldValue) && typeof fieldValue === 'string') {
    return true
  }

  if (format.test(fieldValue.toString())) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
