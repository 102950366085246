<template>
  <component
    v-if="$slots.default"
    :is="getComponent"
    :class="getClass"
    :data-name="getLabel"
  >
    <slot name="default" />
  </component>

  <component v-else :is="getComponent" :class="getClass">
    {{ getLabel }}
  </component>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: 'system.labels.unknown'
      },

      align: {
        type: String,
        default: 'left'
      },

      type: {
        type: String,
        default: 'td'
      }
    },

    computed: {
      getLabel() {
        return this.$t(this.label)
      },

      getComponent() {
        return this.type === 'th' ? 'th' : 'td'
      },

      getClass() {
        return {
          'listTable--right': this.align === 'right',
          'listTable--center': this.align === 'center'
        }
      }
    }
  }
</script>
