<template>
  <div class="fieldGroup__fieldset" :key="key">
    <div
      class="fieldGroup__legend"
      @click="toggle"
      :class="{ 'fieldGroup__legend--toggle': toggleDisplay }"
    >
      <div class="fieldGroup__legendName">
        {{ $t(name) }}
      </div>

      <div class="fieldGroup__legendControls" v-if="toggleDisplay">
        <dy-base-icon
          v-if="isOpen"
          icon="action/close_section"
          class="icon--primary"
        />
        <dy-base-icon v-else icon="action/open_section" class="icon--primary" />
      </div>
    </div>
    <div v-if="isOpen" class="fieldGroup__fields">
      <slot name="default" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseFieldGroup',

    data() {
      return {
        isOpen: true
      }
    },

    props: {
      name: {
        type: [String, Object],
        required: true
      },

      toggleDisplay: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      key() {
        return this.$t(this.name)
      }
    },

    methods: {
      toggle() {
        if (this.toggleDisplay) {
          this.isOpen = !this.isOpen
        } else {
          this.isOpen = true
        }
      }
    }
  }
</script>
