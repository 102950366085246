import getDocument from '../get_document'
import getViewDocument from './get_view_document'
import getReturnMethod from '../get_return_method'

import runReadDocument from './../edit/run_read_document'
import runViewDocument from './run_view_document'

/**
 * setup a view page for a document
 * - setup computed properties
 *    - schema
 *    - doc
 *
 *  - setup methods
 *    - runUpdate
 *    - runUpdateFieldValue
 *    - runReturn
 *
 * - dispatch a runRead request to retrieve document
 *
 * parameters {
 *   id:  id of a document
 *   beforeAction: store tasks to dispatch before running edit action
 * }
 *
 * @param {String} actionType 'read' or 'view'
 * @param {Function} schemaFn function to return schema for a document
 * @param {Object} params parameters
 * @returns
 */
const setupViewPage = actionType => schemaFn => params => {
  const useActionType = actionType || 'read'

  const { schema, doc, docErrors } =
    useActionType === 'read'
      ? getDocument(schemaFn)(params)
      : getViewDocument(schemaFn)(params)

  const { runReturn } = getReturnMethod(schema)

  if (useActionType === 'read') {
    runReadDocument(schemaFn)(params)
  } else {
    runViewDocument(schemaFn)(params)
  }

  return {
    schema,
    doc,
    docErrors,
    runReturn
  }
}

export default setupViewPage
