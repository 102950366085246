import getProperty from './../../utils/document/get_property_value'
import { isFunction } from './../../utils/data/is_function'

import { UNKNOWN } from './schema.constants'

const getDocumentName = schema => doc => {
  const name = getProperty(schema)('dyName')(UNKNOWN)
  if (name === UNKNOWN) {
    return name
  }

  if (isFunction(name)) {
    return name(doc)
  }

  return name.split(/\s/).reduce((result, property) => {
    const useProperty = property.replace(',', '')
    const value = getProperty(doc)(useProperty)('')
    if (value) {
      result = result.replace(useProperty, value)
    }

    return result
  }, name)
}

export default getDocumentName
