import { computed } from 'vue'
import { useStore } from 'vuex'

/**
 * Return computed properties to access a document.
 * - schema
 * - doc
 * - docErrors
 *
 * params = {
 *   id:  id of a document
 * }
 *
 * @param {Function} schemaFn function to return document schema
 * @param {Object} params parameters
 * @returns {Object} document computed properties
 */
const getViewDocument = schemaFn => params => {
  const { id } = params
  const store = useStore()
  const storeName = schemaFn({}).dyStore

  const schema = computed(() => {
    return schemaFn(doc.value)
  })

  const doc = computed(() => {
    return store.getters[`${storeName}/getView`](id)
  })

  return {
    schema,
    doc
  }
}

export default getViewDocument
