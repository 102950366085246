import { isEmpty } from './is_empty'

function isBlank(value) {
  return isEmpty(value) || value.toString().trim() === ''
}

function isNotBlank(value) {
  return !isBlank(value)
}

export { isBlank, isNotBlank }
