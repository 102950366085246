<template>
  <div class="menu__container">
    <nav class="menu">
      <ul class="menu__list">
        <dy-menu
          v-for="menu in menus"
          :key="menu.link"
          :menu="menu"
          @openLink="openLink"
        />
      </ul>
    </nav>
  </div>
</template>

<script>
  import Menu from './Menu.vue'

  export default {
    name: 'MenuList',

    props: {
      menus: {
        type: Array,
        required: true
      }
    },

    emits: ['openLink'],

    components: {
      'dy-menu': Menu
    },

    methods: {
      openLink() {
        return this.$emit('openLink')
      }
    }
  }
</script>
