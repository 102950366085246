import loadComponents from '../../../client/utils/load_base_components'

import RocketLoader from '../../../widgets/loaders/rocket/RocketLoader.vue'

/**
 * Register application filters
 *
 *
 * @param {Object} app Vue application
 */
const setComponents = app => {
  loadComponents(app)(
    require.context('./../../../widgets', true, /Base[A-Z]\w+\.(vue|js)$/)
  )
  app.component('dy-base-loader', RocketLoader)
}

export default setComponents
