import isValidationError from './../../errors/is_validation_error'
import isNotFoundError from './../../errors/is_not_found_error'

const runAction = params => actionFn => () => {
  const { store, router, actionType = 'LOADING' } = params

  store.commit(`SET_${actionType}`)

  return actionFn()
    .then(results => {
      store.commit(`CLEAR_${actionType}`)
      return results
    })
    .catch(err => {
      if (isValidationError(err) || isNotFoundError(err)) {
        store.commit('notification/PUSH_ERROR', err.title)
        store.commit(`CLEAR_${actionType}`)
        return false
      }

      store.commit('SET_ERROR', err)
      router.push({ name: 'error' })
      store.commit(`CLEAR_${actionType}`)
      return false
    })
}

export default runAction
