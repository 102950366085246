<template lang="html">
  <div class="rocket__container">
    <div class="rocket__body">
      <span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </span>
      <div class="rocket__base">
        <span></span>
        <div class="rocket__face"></div>
      </div>
    </div>
    <div class="rocket__longfazers">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="rocket__text">
      {{ message }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RocketLoader',

    props: {
      message: {
        type: String,
        default: 'Loading'
      }
    }
  }
</script>
