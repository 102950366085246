import getId from './../../services/schema/helpers/id.schema'
import getCreatedOn from './../../services/schema/helpers/created_on.schema'
import getUpdatedOn from './../../services/schema/helpers/updated_on.schema'
import setDefault from '../../services/schema/helpers/set_default'

/**
 * Labels are categorized as follows:
 *
 * actions:    used on a button or link
 * documents:  name of a document or resource
 * errors:     errors messages
 * help:       help or info text
 * fields:     name of a document field
 * labels:     miscellaneous labels on a page
 * messages:   status pass/fail messages
 *
 */
export const getCategories = () => {
  return [
    { id: 'actions', name: 'label.labels.actions' },
    { id: 'documents', name: 'label.labels.documents' },
    { id: 'errors', name: 'label.labels.errors' },
    { id: 'fields', name: 'label.labels.fields' },
    { id: 'help', name: 'label.labels.help' },
    { id: 'labels', name: 'label.labels.labels' },
    { id: 'messages', name: 'label.labels.messages' }
  ]
}

/**
 * Entry for each resource included in the application.
 *
 * common is used for labels that are common throughout the application.
 *
 */
export const getResources = () => {
  return process.env.VUE_APP_labels_resources.split(',').map(x => {
    return {
      id: x,
      name: `${x}.documents.${x}`
    }
  })
}

export const getLocales = () => process.env.VUE_APP_labels_locales.split(',')

export const getLocaleList = () =>
  getLocales().map(locale => {
    return { id: locale, name: `labels.fields.${locale}` }
  })

export const getLocaleSchema = () => {
  const locales = getLocales()
  const defaultLocale = locales[0]
  return locales.reduce((accum, locale) => {
    accum[locale] = {
      dyField: 1,
      dyLabel: `label.fields.${locale}`,
      dyValidation: locale === defaultLocale ? [{ rule: 'required' }] : [],
      dyType: 'string'
    }
    return accum
  }, {})
}

export const getKey = x => `${x.resource}.${x.category}.${x.code}`.toLowerCase()

export const toKey = resource => category => code => {
  return `${resource}.${category}.${code}`.toLowerCase()
}

export const fromKey = key => {
  const tokens = key.toString().split('.')
  return {
    resource: tokens[0] || '',
    category: tokens[1] || '',
    code: tokens[2] || ''
  }
}

export const Schema = () => {
  const data = {
    dyLabel: 'label.documents.label',
    dyName: getKey,
    dyCollection: 'labels',
    dyRoute: 'labels',
    dyStore: 'label',
    dyType: 'object',

    ...getId(),

    resource: {
      dyField: 1,
      dyLabel: 'label.fields.resource',
      dyValidation: [{ rule: 'inlist', list: getResources() }],
      dyType: 'string'
    },

    category: {
      dyField: 1,
      dyLabel: 'label.fields.category',
      dyValidation: [{ rule: 'inlist', list: getCategories() }],
      dyType: 'string'
    },

    code: {
      dyField: 1,
      dyLabel: 'system.fields.code',
      dyValidation: [{ rule: 'required' }],
      dyType: 'string'
    },

    ...getLocaleSchema(),

    guest: {
      dyField: 1,
      dyLabel: 'label.fields.guest',
      dyValidation: [],
      dyDefault: setDefault(false),
      dyType: 'boolean'
    },

    ...getCreatedOn(),

    ...getUpdatedOn()
  }

  return data
}
