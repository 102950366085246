<template>
  <dt>{{ fieldLabel }}</dt>
  <dd>
    <dl class="view-nested">
      <slot />
    </dl>
  </dd>
</template>

<script>
  import ViewMixin from './view.mixin'

  export default {
    name: 'BaseNestedView',

    mixins: [ViewMixin]
  }
</script>
