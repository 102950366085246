<template>
  <dt>{{ fieldLabel }}</dt>
  <dd>{{ $filters.formatDateTime(fieldValue) }}</dd>
</template>

<script>
  import ViewMixin from './view.mixin'

  export default {
    name: 'BaseViewDateTime',

    mixins: [ViewMixin]
  }
</script>
