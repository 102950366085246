import { isNotNull, isNull } from './../../utils/data/is_null'
import { isDefined, isUndefined } from './../../utils/data/is_undefined'

const fieldReducer = (result, field) => {
  return isDefined(result) && isNotNull(result) ? result[field] : result
}

const getValue = data => fields => defaultValue =>
  get(data)(fields)(defaultValue)

const get = doc => fields => defaultValue => {
  const useDefaultValue = defaultValue || null

  const tokens = (fields || '').split('.')
  const lastField = tokens.pop()

  const parent = tokens.reduce(fieldReducer, doc)
  if (isUndefined(parent) || isNull(parent)) {
    return useDefaultValue
  }

  const value = parent[lastField]
  return isDefined(value) ? value : useDefaultValue
}

const set = doc => fields => value => {
  const tokens = (fields || '').split('.')
  const lastField = tokens.pop()
  const result = tokens.reduce(fieldReducer, doc)
  if (isDefined(result) && isNotNull(result)) {
    result[lastField] = value
  }
}

const isProperty = doc => fields => {
  const tokens = (fields || '').split('.')
  const lastField = tokens.pop()

  const parent = tokens.reduce(fieldReducer, doc)
  if (isUndefined(parent) || isNull(parent)) {
    return false
  }

  return parent.hasOwnProperty(lastField)
}

export { get, getValue, set, isProperty }
