import { isBlank, isNotBlank } from '../../utils/data/is_blank'

/**
 *ensure fieldValue is between a startDate and an endDate
 *
 * USE:
 * {
 *   rule: 'dateRange',
 *   message: 'system.errors.dateRange',
 *   startDate: '2018-10-03T00:00:00.000Z',
 *   endDate: '2020-10-03T00:00:00.000Z'
 * }
 *
 * @param {object} options options
 * @param {object}  validateData { fieldValue, doc }
 */

const validate = options => payload => {
  const { fieldValue } = payload
  const fieldError = options.message || 'system.errors.dateRange'
  const startDate = options.startDate
  const endDate = options.endDate

  if (isBlank(fieldValue)) {
    return true
  }

  if (isBlank(startDate) && isBlank(endDate)) {
    return true
  }

  if (isNotBlank(startDate) && isBlank(endDate)) {
    return fieldValue >= startDate
      ? true
      : { startDate, fieldError: `${fieldError}StartDate` }
  }

  if (isBlank(startDate) && isNotBlank(endDate)) {
    return fieldValue <= endDate
      ? true
      : { endDate, fieldError: `${fieldError}EndDate` }
  }

  if (fieldValue >= startDate && fieldValue <= endDate) {
    return true
  }

  return {
    startDate,
    endDate,
    fieldError
  }
}

export default validate
