import makeRequired from './required.helper'

const id = options => {
  const { generateFn = false, transform = false, type = 'uid' } = { ...options }

  const id = {
    dyField: 1,
    dyLabel: 'system.fields.id',
    dyValidation: [...makeRequired()],
    dyType: type
  }

  if (generateFn) {
    id.dyId = generateFn
  }

  if (transform) {
    id.dyTransform = transform
  }

  return { id }
}

export default id
