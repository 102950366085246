<template>
  <dy-base-article>
    <template #header>
      <h1>{{ $t('category.documents.categories') }}</h1>
    </template>

    <dy-base-suspense>
      <template #default>
        <dy-outbox :schema="schema" :docs="docs" :docCount="docs.length" />
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import setupQueryPage from '../../../widgets/page/query/setup_query_page'

  import CategoryList from './../components/CategoryList.vue'
  import { Schema } from './../category.schema'

  export default {
    name: 'ListCategories',

    setup() {
      const { schema, docs } = setupQueryPage(Schema)

      return {
        schema,
        docs
      }
    },

    components: {
      'dy-outbox': CategoryList
    }
  }
</script>
