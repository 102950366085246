<template>
  <dy-base-input-slot :fieldLabel="fieldLabel">
    <template v-slot:label>
      <label v-if="fieldLabel">{{ fieldLabel }}</label>
    </template>
    <template v-slot:error>
      <p v-if="fieldError">
        {{ fieldError }}
      </p>
    </template>
    <template v-slot:control>
      <dy-base-checkbox
        v-for="option in options"
        :key="option.id"
        :optionId="option.id"
        :optionName="$t(option.name)"
        :checked="isChecked(option.id)"
        @updateFieldValue="updateFieldValue"
      />
    </template>
    {{ this.fieldValue }}
  </dy-base-input-slot>
</template>

<script>
  import { SetupFormField } from './../setup_form_field.mixin'

  export default {
    mixins: [SetupFormField],
    props: {
      options: {
        type: Array,
        required: true,
        validator(opts) {
          return !opts.find(opt => typeof opt !== 'object')
        }
      }
    },

    methods: {
      isChecked(_optionId) {
        // return this.fieldValue.includes(optionId)
        return false
      },

      updateFieldValue({ optionId, isChecked }) {
        const payload = {
          fieldName: this.fieldName,
          fieldValue: isChecked
            ? [...this.fieldValue, optionId]
            : this.fieldValue.filter(x => x !== optionId)
        }

        this.$emit('updateFieldValue', payload)
      }
    }
  }
</script>
