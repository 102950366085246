const getDocumentAction = readAllFn => (ctx, params) => {
  const query = ctx.state.query || false
  const options = {
    ...params,
    ...query
  }

  return readAllFn(options).then(({ docs, docCount }) => {
    const useDocCount = docCount || docs.length
    ctx.commit('setDocuments', { docs })
    ctx.commit('setDocumentCount', { docCount: useDocCount })
    return { docs, docCount: useDocCount }
  })
}

export default getDocumentAction
