<template>
  <dy-base-article>
    <template #header>
      <h1>{{ $t('survey.documents.survey') }}</h1>
    </template>

    <dy-base-suspense>
      <template v-slot:default>
        <dy-survey
          :schema="schema"
          :doc="doc"
          :xref="xref"
          @runReturn="runReturn"
        />
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import setupViewPage from './../../../widgets/page/view/setup_view_page'

  import { Schema } from './../survey.schema'
  import SurveyView from './../components/SurveyView'

  export default {
    name: 'ViewSurvey',

    props: {
      id: {
        type: String,
        required: true
      }
    },

    setup(props) {
      const { schema, doc, runReturn } = setupViewPage('view')(Schema(false))(
        props
      )

      return { schema, doc, runReturn }
    },

    computed: {
      xref() {
        return this.$store.getters['category/getProductXref']
      }
    },

    components: {
      'dy-survey': SurveyView
    }
  }
</script>
