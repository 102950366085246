<template lang="html">
  <p class="info">
    Please enter a brand name, product name, and an explanation of why you
    recommend the product. You must enter information in all 3 fields for your
    recommendation to count.
  </p>

  <p class="info">
    You have completed <strong>{{ votes }}</strong> out of a possible
    <strong>{{ questions }} </strong> recommendations.
  </p>

  <p v-if="!doc.completed" class="info">
    Have you completed the recommendations you'd like to make? If yes click
    <a class="action-link" href="#" @click.prevent="runAllDone"> All Done </a>
  </p>

  <div class="category__wrapper">
    <dy-category
      v-for="(category, idx) in categories"
      :key="category.id"
      :category="category"
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      :productXref="productXref"
      :categoryIdx="idx"
      @runUpdateFieldValue="runUpdateFieldValue"
      @runUpdate="runUpdate"
    />
  </div>

  <p v-if="!doc.completed" class="info">
    Have you completed the recommendations you'd like to make? If yes click
    <a class="action-link" href="#" @click.prevent="runAllDone"> All Done </a>
  </p>
</template>

<script>
  import SurveyFormCategory from './SurveyFormCategory.vue'

  import { getQuestionCount, getVoteCount } from './../../surveys/survey'

  export default {
    name: 'SurveyForm',

    props: {
      categories: {
        type: Array,
        required: true
      },

      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      },

      docErrors: {
        type: Object,
        required: true
      }
    },

    emits: ['runUpdateFieldValue', 'runUpdate', 'runAllDone'],

    computed: {
      questions() {
        return getQuestionCount(this.doc)
      },

      votes() {
        return getVoteCount(this.doc)
      },

      productXref() {
        return this.doc.responses.reduce((accum, response, idx) => {
          accum[response.productId] = {
            response,
            responseIdx: idx
          }
          return accum
        }, {})
      }
    },

    methods: {
      runUpdateFieldValue(payload) {
        this.$emit('runUpdateFieldValue', payload)
      },

      runUpdate() {
        this.$emit('runUpdate')
      },

      runAllDone() {
        this.$emit('runAllDone')
      }
    },

    components: {
      'dy-category': SurveyFormCategory
    }
  }
</script>

<style lang="scss">
  .category__wrapper {
    margin-bottom: 1.25em;
  }

  ul.faq {
    padding: 0;
    margin: 0 0 1em 1.5em;
    list-style-type: none;

    li {
      padding-left: 0;
      margin-bottom: 0;
      border-top: 1px solid #e5e5e5;
    }

    li:first-child {
      border-top: none;
    }

    li:last-child {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  div.faq__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    padding: 0.75em 0.25em 0.75em 1em;
    font-size: 1em;
    cursor: pointer;
    color: #5b5941;

    svg {
      width: 1em;
      margin-right: 0.25em;
    }
  }

  div.faq__title:hover {
    background-color: #f5f5f5;
    background-image: linear-gradient(
      90deg,
      #1077d0 0%,
      #1077d0 8px,
      transparent 8px,
      transparent 100%
    );
    background-position: 0 50%;
  }

  div.faq__message {
    background-color: #f5f5f5;
    display: none;
    padding-left: 2.35em;
    padding-right: 1em;
  }

  li.faq__active {
    div.faq__title {
      background-color: #f5f5f5;
      background-image: linear-gradient(
        90deg,
        #1077d0 0%,
        #1077d0 8px,
        transparent 8px,
        transparent 100%
      );
      background-position: 0 50%;
    }

    div.faq__message {
      display: block;
    }
  }
</style>
