import { REPORT_ROUTE } from '../../../setup/collection.constants'

import SurveySummary from './SurveySummary.vue'

const routes = [
  {
    path: `/${REPORT_ROUTE}`,
    name: REPORT_ROUTE,
    component: SurveySummary,
    meta: { requiresAdministrator: true }
  }
]

export default routes
