import { getApplication } from '../../../database_services/fbclient/fb_client'
import queryAll from '../../../database_services/fbclient/query/query_all'

import getCollectionName from './../../../services/schema/get_document_collection'

const wrapReadAll = schemaFn => options => {
  const schema = schemaFn({})
  const collectionName = getCollectionName(schema)

  return getApplication()
    .then(({ fdb }) => queryAll(fdb)(collectionName)(options))
    .then(docs => {
      return { docs, docCount: docs.length }
    })
}

export default wrapReadAll
