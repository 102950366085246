<template>
  <button class="button" :class="getClassList">
    <div class="button__text">
      <slot>{{ $t('system.actions.submit') }}</slot>
    </div>
  </button>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      getClassList() {
        return {
          'button--loading': this.loading
        }
      }
    }
  }
</script>
