<template>
  <li class="submenu" :class="{ 'submenu--open': isOpen }">
    <a tabindex="0" @click="toggleSubmenu" @blur="closeSubmenu">
      {{ $t(menu.name) }}
    </a>
    <ul class="submenu__list">
      <dy-submenu-item
        v-for="child in menu.children"
        :key="child.link"
        :menu="child"
        @openLink="openLink"
      />
    </ul>
  </li>
</template>

<script>
  import SubmenuItem from './SubmenuItem.vue'

  export default {
    name: 'Submenu',

    data: function () {
      return {
        isOpen: false
      }
    },

    props: {
      menu: {
        type: Object,
        required: true
      }
    },

    emits: ['openLink'],

    methods: {
      toggleSubmenu() {
        this.isOpen = !this.isOpen
      },

      closeSubmenu() {
        return setTimeout(() => (this.isOpen = false), 200)
      },

      openLink() {
        this.isOpen = false
        return this.$emit('openLink')
      }
    },

    components: {
      'dy-submenu-item': SubmenuItem
    }
  }
</script>
