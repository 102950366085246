import { isBlank } from './../../utils/data/is_blank'

import buildError from '../../errors/build_error'

const assertId = id => ref => {
  return new Promise((resolve, reject) => {
    if (isBlank(id)) {
      return reject(
        buildError('serverError')({ message: 'fbserver.errors.invalidId' })
      )
    }

    return resolve(ref)
  })
}

export default assertId
