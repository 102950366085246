import FileData from './FileData'

/**
 * Read the contents of all files included in a file list.
 *
 * Return an array of FileData objects.
 *
 * Contents of each file is returned as a base64 encoded string.
 *
 * @param {FileList} files file list
 * @returns {Array} file data
 */
export const getFileContents = files => {
  const tasks = []
  for (var idx = 0; idx < files.length; idx++) {
    tasks.push(getFileContent(files[idx]))
  }

  return Promise.all(tasks)
}

/**
 * Read the contents of a file
 *
 * Return a FileData object.
 *
 * Contents of each file is returned as a base64 encoded string.
 *
 * @param {File} file file
 * @returns {FileData} file data
 */
export const getFileContent = file => {
  return new Promise((resolve, reject) => {
    const fReader = new FileReader()

    fReader.onload = () => {
      const content = fReader.result.replace(`data:${file.type};base64,`, '')
      resolve(FileData(file)(content))
    }

    fReader.onerror = event => {
      reject(
        new Error(`Cannot read file ${file.name}: ${event.target.error.name}`)
      )
    }

    fReader.readAsDataURL(file)
  })
}
