import getValidators from './get_validators'
import validateDocument from './validate.service'
import buildError from './../../errors/build_error'

const filterByFieldName = fieldName => validators => {
  if (!fieldName) {
    return validators
  }

  const useValidators = {}
  Object.getOwnPropertyNames(validators).forEach(field => {
    if (field.indexOf(fieldName) === 0) {
      useValidators[field] = validators[field]
    }
  })

  return useValidators
}

const assertValidationError = doc => isValid => {
  if (isValid !== true) {
    throw buildError('validationError')({ doc, errors: isValid })
  }
  return doc
}

const validateField = schema => fieldName => doc => {
  return Promise.resolve(getValidators(schema)(doc))
    .then(filterByFieldName(fieldName))
    .then(validateDocument(doc))
    .then(assertValidationError(doc))
}

export default validateField
