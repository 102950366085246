const isLocalStorage = window && window.localStorage

export const getItem = key => {
  if (!isLocalStorage) {
    return null
  }

  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (_err) {
    return null
  }
}

export const setItem = key => value => {
  if (!isLocalStorage) {
    return false
  }

  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (err) {
    return false
  }
}

export const removeItem = key => {
  if (!isLocalStorage) {
    return null
  }

  localStorage.removeItem(key)
  return true
}
