import getDocument from '../../../widgets/page/get_document'
import getReturnMethod from '../../../widgets/page/get_return_method'

import runReadDocument from './run_read_document'
import getUpdateFieldMethod from './get_update_field_method'
import getUpdateMethod from './get_update_method'

/**
 * setup an edit page for a document
 * - setup computed properties
 *    - schema
 *    - doc
 *    - docErrors
 *
 *  - setup methods
 *    - runUpdate
 *    - runUpdateFieldValue
 *    - runReturn
 *
 * - dispatch a runRead request to retrieve document
 *
 * parameters {
 *   id:  id of a document
 *   beforeAction: store tasks to dispatch before running edit action
 * }
 *
 * @param {Function} schemaFn function to return a document schema
 * @param {Object} params parameters
 * @returns
 */
const setupEditPage = schemaFn => params => {
  const { doc, docErrors, schema } = getDocument(schemaFn)(params)

  const { runReturn } = getReturnMethod(schema)

  const { runUpdateFieldValue } = getUpdateFieldMethod(schema)(params)
  const { runUpdate } = getUpdateMethod(schema)(params)

  runReadDocument(schemaFn)(params)

  return {
    schema,
    doc,
    docErrors,
    runReturn,
    runUpdateFieldValue,
    runUpdate
  }
}

export default setupEditPage
