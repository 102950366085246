import isProperty from './is_property'

/**
 * Return the value of a document property
 *
 * Nested properties can be accessed using a comma delimited list of property
 * names:
 *  - name
 *  - address.street
 *  - favourite.colours.1
 *
 * @param {Object}  doc document
 * @param {String} property property name
 * @param {Any} defaultValue default value
 * @returns {Any} property value or default value if property is not found
 */
const getPropertyValue =
  doc =>
  property =>
  (defaultValue = null) => {
    if (isProperty(doc)(property) === false) {
      return defaultValue
    }

    return property.split('.').reduce(valueReducer, doc)
  }

const valueReducer = (result, property) => {
  return result[property]
}

export default getPropertyValue
