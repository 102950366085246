import { useStore } from 'vuex'

/**
 * Return method to update a field value.
 * - runUpdateFieldValue - update document field
 *
 * parameters {
 *   id:  id of a document
 * }
 *
 * @param {Object} schema document schema
 * @param {Object} params parameters
 * @returns
 */
const getUpdateFieldMethod = schema => params => {
  const { id } = params
  const store = useStore()
  const storeName = schema.value.dyStore

  const runUpdateFieldValue = payload => {
    return store.dispatch(`${storeName}/runUpdateFieldValue`, {
      doc: store.getters[`${storeName}/getEditDocument`](id),
      ...payload
    })
  }
  return {
    runUpdateFieldValue
  }
}

export default getUpdateFieldMethod
