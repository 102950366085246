import { isEmpty, isNotEmpty } from './../../utils/data/is_empty'
import isProperty from './../../utils/document/is_property'
import getFieldSchema from './../../services/schema/get_field_schema'
import getFieldType from './../../services/schema/get_field_type'
import getFieldList from './../../services/schema/get_fields'
import isArray from './../../services/schema/is_array'
import isObject from './../../services/schema/is_object'

import getDefaultTransformer from './get_default_transformer'

const getFieldTransformers = schema => fieldName => {
  return schema[fieldName].dyTransform || []
}

const getTransformersForAnObject = schema => doc => {
  const transformers = []

  if (isEmpty(schema)) {
    return transformers
  }

  getFieldList(schema).forEach(fieldName => {
    if (isProperty(doc)(fieldName)) {
      const fieldTransformers = []
      const fieldSchema = getFieldSchema(schema)(doc)(fieldName)
      const fieldType = getFieldType(fieldSchema)

      const defaultTransform = getDefaultTransformer(fieldType)
      if (defaultTransform) {
        fieldTransformers.push(defaultTransform)
      }

      getFieldTransformers(schema)(fieldName).forEach(item => {
        fieldTransformers.push(item)
      })

      const fieldValue = doc[fieldName]
      if (isNotEmpty(fieldValue)) {
        if (isObject(fieldType)) {
          getTransformersForAnObject(fieldSchema)(fieldValue).forEach(item => {
            transformers.push({
              fieldName: `${fieldName}.${item.fieldName}`,
              fieldTransformers: item.fieldTransformers
            })
          })
        } else if (isArray(fieldType)) {
          fieldValue.forEach((itemValue, idx) => {
            const itemName = `${fieldName}.${idx}`
            const itemSchema = getFieldSchema(schema)(doc)(itemName)
            getTransformersForAnObject(itemSchema)(itemValue).forEach(item => {
              transformers.push({
                fieldName: `${itemName}.${item.fieldName}`,
                fieldTransformers: item.fieldTransformers
              })
            })
          })
        }
      }

      if (fieldTransformers.length >= 1) {
        transformers.push({ fieldName, fieldTransformers })
      }
    }
  })

  return transformers
}

/**
 * Return transformers for a document
 *
 * Returns an object - key: fieldName, value: fieldTransformers
 *
 * {
 *   id: [uidTransformer],
 *   name: [objectTransformer],
 *   'name.en': [stringTransformer, customTransformer]
 * }
 *
 * @param  {object} schema  document schema
 * @param  {object} doc     document
 * @return {object}         transformers
 */
const getTransformers = schema => doc => {
  const useDoc = doc || {}
  return getTransformersForAnObject(schema)(useDoc).reduce(
    (transformers, { fieldName, fieldTransformers }) => {
      transformers[fieldName] = fieldTransformers
      return transformers
    },
    {}
  )
}

export default getTransformers
