import {
  SURVEY_ROUTE,
  SURVEY_STORE,
  SURVEY_COMPLETIONS
} from '../../setup/collection.constants'

import getId from '../../services/schema/helpers/id.schema'
import getCreatedOn from '../../services/schema/helpers/created_on.schema'

export const Schema = () => {
  const data = {
    dyLabel: 'survey.documents.survey',
    dyName: 'finishedId',
    dyCollection: SURVEY_COMPLETIONS,
    dyRoute: SURVEY_ROUTE,
    dyStore: SURVEY_STORE,
    dyType: 'object',

    ...getId(),

    finishedUid: {
      dyField: 1,
      dyLabel: 'survey.fields.finishUid',
      dyValidation: [{ rule: 'required' }],
      dyType: 'string'
    },

    processedOn: {
      dyField: 1,
      dyLabel: 'survey.fields.processOn',
      dyValidation: [],
      dyType: 'datetime'
    },

    processedError: {
      dyField: 1,
      dyLabel: 'survey.fields.processError',
      dyValidation: [],
      dyType: 'string'
    },

    ...getCreatedOn()
  }

  return data
}
