import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import runAction from '../run_action'

/**
 * Dispatch a runRead action to read a document.
 *
 * parameters {
 *   id:  id of a document
 * }
 *
 * @param {Function} schemaFn function to return document schema
 * @param {Object} params parameters
 * @returns
 */
const runViewDocument = schemaFn => params => {
  const { id } = params
  const storeName = schemaFn({}).dyStore
  const store = useStore()
  const actionName = `${storeName}/runView`

  const router = useRouter()

  const setupFn = () => {
    return store.dispatch(actionName, { id })
  }

  runAction({ store, router })(setupFn)()

  return true
}

export default runViewDocument
