import { createApp } from 'vue'

import {
  createApplication,
  setupApplication
} from '../setup/client/setup_client'

const app = createApplication(createApp)

setupApplication(app)

app.mount('#app')
