import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import runAction from '../run_action'

/**
 * Return methods to update a document:
 * - runUpdate - update document
 *
 * parameters {
 *   id:  id of a document
 * }
 *
 * @param {Object} Schema document schema
 * @param {Object} params parameters
 * @returns
 */
const getUpdateMethod = schema => params => {
  const { id } = params
  const store = useStore()
  const storeName = schema.value.dyStore

  const router = useRouter()

  const runUpdate = (action = 'runUpdate') => {
    const payload = { doc: store.getters[`${storeName}/getEditDocument`](id) }
    const updateFn = () => {
      return store.dispatch(`${storeName}/${action}`, payload)
    }

    return runAction({ store, router, actionType: 'SAVING' })(updateFn)()
  }

  return {
    runUpdate
  }
}

export default getUpdateMethod
