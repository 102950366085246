import getFieldSchema from './../../services/schema/get_field_schema'
import getFieldLabel from './../../services/schema/get_label'
import getFieldType from './../../services/schema/get_field_type'

import { get } from './../document'

const ViewMixin = {
  inheritAttrs: false,

  props: {
    schema: {
      type: Object,
      required: true
    },

    doc: {
      type: Object,
      required: true
    },

    fieldName: {
      type: String,
      required: true
    }
  },

  computed: {
    fieldSchema() {
      return getFieldSchema(this.schema)(this.doc)(this.fieldName)
    },

    fieldLabel() {
      return this.$t(getFieldLabel(this.fieldSchema))
    },

    fieldValue() {
      return get(this.doc)(this.fieldName)()
    },

    fieldType() {
      return getFieldType(this.fieldSchema)
    }
  }
}

export default ViewMixin
