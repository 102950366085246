const getDocumentGetters = () => {
  return {
    getDocuments: state => {
      return state.document.list.map(id => state.document.data[id])
    },

    getDocumentCount: state => {
      return state.docCount
    },

    getDocument: state => id => {
      return state.document.data[id] || false
    }
  }
}

export default getDocumentGetters
