import sortIt from '../../utils/sort/sort_it'

/**
 * Create a productId vs product cross-reference.
 *
 * The following data is included for each productId
 *  {
 *    categoryId: '1FGlHbYzoK0zzEzKvw2a',
 *    categoryName:  'makeup products',
 *    productId: '2hvn-bi9z-y39p-udbd',
 *    productName: 'Mass Lip Colour',
 *    seq: 17
 *  }
 *
 *
 * @param {Array} categories list of categories
 * @returns {Object} product cross-reference
 */

const getProductXref = categories => {
  const { xref } = sortBySequence(categories).reduce(buildXref, {
    xref: {},
    seq: 0
  })
  return xref
}

const buildXref = (accum, category) => {
  sortBySequence(category.products).forEach(product => {
    accum.seq += 1
    accum.xref[product.id] = {
      categoryId: category.id,
      categoryName: category.name,
      productId: product.id,
      productName: product.name,
      seq: accum.seq
    }
  })

  return accum
}

const sortBySequence = docs => sortIt('seq:a')(docs)

export default getProductXref
