import buildError from '../../../errors/build_error'

export const namespaced = false

export const state = {
  error: false
}

export const getters = {
  getError(state) {
    return state.error
  }
}
export const mutations = {
  SET_ERROR(state, err) {
    const error = buildError('serverError')(err)
    state.error = {
      code: error.code,
      title: error.title,
      data: error.data,
      errorOn: error.errorOn
    }
    state.error = error
  },

  CLEAR_ERROR(state) {
    state.error = false
  }
}
