<template>
  <span class="icon" :title="getLabel">
    <svg class="icon__svg" :class="{ 'icon__svg--spin': spin }">
      <use :xlink:href="`#${icon}`" />
    </svg>
  </span>
</template>

<script>
  const toCamelCase = str => {
    return str
      .replace('_', ' ')
      .replace('/', ' ')
      .replace(/\W+(.)/g, function (_match, chr) {
        return chr.toUpperCase()
      })
  }

  export default {
    name: 'BaseSvgIcon',

    props: {
      icon: {
        type: String,
        default: 'alert/unknown'
      },

      spin: {
        type: Boolean,
        default: false
      },

      title: {
        type: String,
        default: ''
      }
    },

    computed: {
      getLabel() {
        if (this.title) {
          return this.$t(this.title)
        }
        return this.$t(`system.icon.${toCamelCase(this.icon)}`)
      }
    }
  }
</script>
