import transformUpdatedOn from './transform_updated_on'

const updatedOn = () => {
  return {
    updatedOn: {
      dyField: 1,
      dyLabel: 'system.fields.updatedOn',
      dyType: 'datetime',
      dyTransform: [transformUpdatedOn]
    }
  }
}

export default updatedOn
