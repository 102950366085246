import { getApplication } from '../../../database_services/fbclient/fb_client'
import { httpsCallable } from 'firebase/functions'
import AuthorizationError from '../../../errors/authorization.error'
import AuthenticationError from '../../../errors/authentication.error'

/**
 * Call a firebase callable function.
 *
 * @param {String}    functionName name of a callable function
 * @param {Object}    data         data to be passed to callable function
 * @returns {Object}               data returned by a callable function
 */
const runFunction = functionName => data => {
  return getApplication()
    .then(({ fbFn }) => {
      const fn = httpsCallable(fbFn, functionName)
      return fn(data)
    })
    .then(result => result.data)
    .catch(err => {
      const { details } = err

      if (details && details.code === '401') {
        throw new AuthorizationError({})
      }

      if (details && details.code === '403') {
        throw new AuthenticationError({})
      }

      throw err
    })
}

export default runFunction
