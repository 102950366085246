<template>
  <a href="" :class="getTodoType" class="todo__link" @click.prevent="goto">
    <div class="todo__slider"></div>
    <dy-base-card :icon="icon" :width="64" :height="64">
      <template #title>
        <h3>{{ title }}</h3>
      </template>
      <template #description>
        <p class="info">
          {{ description }}
        </p>
      </template>
    </dy-base-card>
  </a>
</template>

<script>
  export default {
    name: 'ToDo',

    props: {
      icon: {
        type: String,
        required: false,
        default: 'icon-facebook'
      },

      title: {
        type: String,
        required: true
      },

      description: {
        type: String,
        required: true
      },

      link: {
        type: [Object],
        required: true
      },

      type: {
        type: String,
        default: 'todo'
      }
    },

    computed: {
      getTodoType() {
        return {
          'todo__link--setup': this.type === 'setup',
          'todo__link--todo': this.type === 'todo',
          'todo__link--review': this.type === 'review'
        }
      }
    },

    methods: {
      goto() {
        return this.$router.push(this.link)
      }
    }
  }
</script>
