<template>
  <div id="layout__auth" class="page__wrapper page--container">
    <dy-content>
      <template #notification>
        <dy-notification />
      </template>
    </dy-content>
  </div>
</template>

<script>
  import Content from './../../../widgets/contents/components/Content.vue'
  import Notification from './../../../widgets/notifications/components/Notification.vue'

  export default {
    name: 'AuthLayout',

    setup() {
      document.title = process.env.VUE_APP_app_name

      return {}
    },

    computed: {
      applicationName() {
        return process.env.VUE_APP_app_name
      },

      applicationDescription() {
        return process.env.VUE_APP_app_description
      },

      applicationVersion() {
        return process.env.VUE_APP_app_version
      },

      copyright() {
        return process.env.VUE_APP_copyright
      }
    },

    components: {
      'dy-content': Content,
      'dy-notification': Notification
    }
  }
</script>
