<template lang="html">
  <li :class="{ faq__active: isOpen }">
    <div class="faq__title" @click.prevent="toggleOpen">
      <div class="vote__name">
        <dy-base-icon
          v-if="isSaving === false && isOpen === true"
          icon="faq/down"
          class="icon--product icon--md"
        />

        <dy-base-icon
          v-if="isSaving === false && isOpen === false"
          icon="faq/right"
          class="icon--product icon--md"
        />

        <dy-base-icon
          v-if="isSaving"
          class="icon--saving icon--md"
          icon="action/saving"
        />

        {{ product.name }}
      </div>

      <div class="vote__status">
        <dy-base-icon
          :class="voteClass"
          :icon="voteIcon"
          class="icon--md"
          :title="voteTitle"
        />
      </div>
    </div>
    <div class="faq__message">
      <dy-base-form>
        <dy-input
          :schema="schema"
          :doc="doc"
          :docErrors="docErrors"
          fieldName="brand"
          maxlength="120"
          @updateFieldValue="runUpdateFieldValue"
        />

        <dy-input
          :schema="schema"
          :doc="doc"
          :docErrors="docErrors"
          fieldName="product"
          maxlength="60"
          @updateFieldValue="runUpdateFieldValue"
        />

        <dy-text-area
          :schema="schema"
          :doc="doc"
          :docErrors="docErrors"
          fieldName="comment"
          @updateFieldValue="runUpdateFieldValue"
        />
        <div class="survey__buttons">
          <dy-base-save-button @click="runUpdate">
            {{ $t('system.actions.save') }}
          </dy-base-save-button>
        </div>
      </dy-base-form>
    </div>
  </li>
</template>

<script>
  import { getItem, setItem } from './../../../services/store/get_local_storage'
  import { isNotBlank } from './../../../utils/data/is_blank'

  import SurveyInput from './SurveyInput.vue'
  import SurveyTextArea from './SurveyTextArea.vue'

  const LOCAL_STORAGE_KEY = 'openProducts'

  export default {
    name: 'SurveyFormProduct',

    data() {
      return {
        openList: '',
        isOpen: false
      }
    },

    props: {
      product: {
        type: Object,
        required: true
      },

      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      },

      docErrors: {
        type: Object,
        required: true
      },

      docIdx: {
        type: Number,
        required: true
      },

      productIdx: {
        type: Number,
        required: true
      }
    },

    emits: ['runUpdateFieldValue', 'runUpdate'],

    computed: {
      isSaving() {
        return this.$store.getters.isSaving
      },

      voteStatus() {
        const { completed, brand, product, comment } = this.doc
        if (completed) {
          return 2
        }

        return isNotBlank(brand) || isNotBlank(product) || isNotBlank(comment)
          ? 1
          : 0
      },

      voteIcon() {
        const iconList = [
          'comment/notstarted',
          'comment/pending',
          'comment/completed'
        ]
        return iconList[this.voteStatus]
      },

      voteTitle() {
        const iconList = [
          'survey.help.voteNotStarted',
          'survey.help.voteNotFinished',
          'survey.help.voteCompleted'
        ]
        return iconList[this.voteStatus]
      },

      voteClass() {
        const classList = [
          'icon--notstarted',
          'icon--pending',
          'icon--completed'
        ]
        return classList[this.voteStatus]
      }
    },

    methods: {
      toggleOpen() {
        this.isOpen = !this.isOpen
        const updatedList = this.isOpen
          ? this.openList.split(',').concat(this.product.id).join(',')
          : this.openList
              .split(',')
              .filter(x => x !== this.product.id)
              .join(',')

        setItem(LOCAL_STORAGE_KEY)(updatedList)
        this.openList = updatedList
      },

      runUpdateFieldValue(payload) {
        const data = {
          ...payload,
          fieldName: `responses.${this.docIdx}.${payload.fieldName}`
        }
        this.$emit('runUpdateFieldValue', data)
      },

      runUpdate() {
        this.$emit('runUpdate')
      }
    },

    components: {
      'dy-input': SurveyInput,
      'dy-text-area': SurveyTextArea
    },

    mounted() {
      this.openList = getItem(LOCAL_STORAGE_KEY) || ''
      this.isOpen =
        (this.productIdx === 0 && this.openList === '') ||
        this.openList.includes(this.product.id)
    }
  }
</script>

<style lang="scss" scoped>
  .vote__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .form__container {
    background-color: inherit;
    padding: 0 0 0.5em 0;
  }

  .survey__buttons {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }
  .button {
    width: 60%;
    margin: 0 auto;
  }

  .icon--product {
    color: #5b5941;
  }
  .icon--saving {
    color: #efad65;
  }
  .icon--completed {
    color: #2b8f35;
    margin-left: 0.5em;
  }

  .icon--pending {
    color: #8b0000;
    margin-left: 0.5em;
  }

  .icon--notstarted,
  .icon--disabled {
    color: #c2c2c2;
    margin-left: 0.5em;
  }
</style>
