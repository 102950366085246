import { isEmpty } from '../../utils/data/is_empty'

/**
 * Return list of document properties.
 *
 * @param {Object} doc document
 * @returns {Array} list of document properties
 */
const getPropertyList = doc => {
  if (isEmpty(doc)) {
    return []
  }

  return Object.getOwnPropertyNames(doc).filter(removeCustomFields)
}

const removeCustomFields = x => x !== '__ob__'

export default getPropertyList
