class translateError extends Error {
  constructor(payload) {
    const { label = 'unknown', message = 'label.errors.translate' } = {
      ...payload
    }

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, translateError)
    }

    this.message = message
    this.label = label
  }
}

export default translateError
