<template>
  <dy-base-form-field
    :isError="isError"
    :isStacked="isStacked"
    class="toggle__field"
  >
    <template v-if="fieldLabel" #label>
      <label :for="fieldId">{{ fieldLabel }}</label>
    </template>

    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>

    <template #default>
      <div class="toggle__container">
        <input
          :id="fieldId"
          :name="fieldName"
          :value="fieldValue"
          :checked="isChecked"
          type="checkbox"
          class="checkbox"
          v-bind="$attrs"
          @change="updateFieldValue($event)"
        />
        <label :for="fieldId" class="toggle__slider"></label>
      </div>
    </template>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from './../setup_form_field.mixin'

  export default {
    name: 'BaseToggle',

    mixins: [SetupFormField],

    computed: {
      isChecked() {
        return this.fieldValue === 'true' || this.fieldValue === true
      }
    }
  }
</script>
