import {
  SURVEY_ROUTE,
  SURVEY_STORE,
  SURVEYS
} from '../../setup/collection.constants'

import getCreatedOn from '../../services/schema/helpers/created_on.schema'
import getUpdatedOn from '../../services/schema/helpers/updated_on.schema'
import setDefault from '../../services/schema/helpers/set_default'
import selectSchema from './../../services/schema/select_schema'

import { isNotBlank } from './../../utils/data/is_blank'

import validateUserUid from './validate_user_uid'

const companyTypes = [
  { name: 'Department Store', id: 'deparmentstore' },
  { name: 'Drug Store', id: 'drugstore' },
  { name: 'Specialty Store/Other', id: 'other' }
]

const isIncluded = _response => true

export const isResponseCompleted = rsp =>
  rsp.included &&
  isNotBlank(rsp.brand) &&
  isNotBlank(rsp.product) &&
  isNotBlank(rsp.comment)

export const completedResponseCount = responses =>
  responses.filter(isResponseCompleted).length

export const includedResponseCount = responses =>
  responses.filter(isIncluded).length

export const getCompanyTypes = () => {
  return companyTypes.map(x => x)
}

export const checkIfCompleted = ({ fieldValue }) => {
  return fieldValue.map(x => {
    return {
      ...x,
      completed: isResponseCompleted(x)
    }
  })
}

export const Schema = isNew => () => {
  const isRequired = !isNew

  const data = {
    dyLabel: 'survey.documents.survey',
    dyName: 'lastName, firstName',
    dyCollection: SURVEYS,
    dyRoute: SURVEY_ROUTE,
    dyStore: SURVEY_STORE,
    dyType: 'object',

    id: {
      dyField: 1,
      dyLabel: 'system.fields.id',
      dyValidation: [{ rule: 'required' }, { rule: validateUserUid }],
      dyRestrictUpdate: true,
      dyType: 'string'
    },

    lastName: {
      dyField: 1,
      dyLabel: 'system.fields.lastName',
      dyValidation: isRequired ? [{ rule: 'required' }] : [],
      dyPlaceholder: 'Smith',
      dyType: 'string'
    },

    firstName: {
      dyField: 1,
      dyLabel: 'system.fields.firstName',
      dyPlaceholder: 'Jane',
      dyValidation: isRequired ? [{ rule: 'required' }] : [],
      dyType: 'string'
    },

    email: {
      dyField: 1,
      dyLabel: 'system.fields.email',
      dyValidation: [{ rule: 'required' }],
      dyPlaceholder: 'jsmith@someplace.com',
      dyType: 'email'
    },

    title: {
      dyField: 1,
      dyLabel: 'survey.fields.title',
      dyValidation: isRequired ? [{ rule: 'required' }] : [],
      dyPlaceholder: 'Beauty Consultant',
      dyType: 'string'
    },

    company: {
      dyField: 1,
      dyLabel: 'survey.fields.company',
      dyValidation: isRequired ? [{ rule: 'required' }] : [],
      dyPlaceholder: 'Janes Beauty Boutique',
      dyType: 'string'
    },

    companyType: {
      dyField: 1,
      dyLabel: 'survey.fields.companyType',
      dyValidation: isRequired
        ? [
            { rule: 'required' },
            {
              rule: 'inList',
              list: getCompanyTypes()
            }
          ]
        : [
            {
              rule: 'inList',
              list: getCompanyTypes()
            }
          ],
      dyType: 'string'
    },

    city: {
      dyField: 1,
      dyLabel: 'survey.fields.city',
      dyValidation: isRequired ? [{ rule: 'required' }] : [],
      dyPlaceholder: 'Toronto',
      dyType: 'string'
    },

    postalCode: {
      dyField: 1,
      dyLabel: 'survey.fields.postalCode',
      dyValidation: isRequired ? [{ rule: 'required' }] : [],
      dyPlaceholder: 'M1T 2C5',
      dyType: 'postalCode'
    },

    newsletter: {
      dyField: 1,
      dyLabel: 'survey.fields.newsletter',
      dyType: 'boolean'
    },

    completed: {
      dyField: 1,
      dyLabel: 'survey.fields.completed',
      dyType: 'boolean'
    },

    finishedUid: {
      dyField: 1,
      dyLabel: 'survey.fields.finishUid',
      dyValidation: [],
      dyType: 'string'
    },

    responses: {
      dyField: 1,
      dyLabel: 'survey.fields.responses',
      dyTransform: [checkIfCompleted],
      dyType: 'array',
      dySchema: selectSchema({ schema: 'response' }),
      dySchemaOptions: {
        response: {
          dyField: 1,
          dyLabel: 'survey.fields.response',
          dyType: 'object',
          brand: {
            dyField: 1,
            dyLabel: 'survey.fields.brand',
            dyPlaceholder: 'survey.placeholders.brand',
            dyType: 'string'
          },
          product: {
            dyField: 1,
            dyLabel: 'survey.fields.product',
            dyPlaceholder: 'survey.placeholders.product',
            dyType: 'string'
          },
          comment: {
            dyField: 1,
            dyLabel: 'survey.fields.comment',
            dyPlaceholder: 'survey.placeholders.comment',
            dyType: 'string'
          },
          included: {
            dyField: 1,
            dyLabel: 'survey.fields.included',
            dyDefault: setDefault(0),
            dyType: 'boolean'
          },
          completed: {
            dyField: 1,
            dyLabel: 'survey.fields.completed',
            dyType: 'boolean'
          },
          productId: {
            dyField: 1,
            dyLabel: 'survey.fields.product',
            dyValidation: [{ rule: 'required' }],
            dyType: 'rid'
          }
        }
      }
    },

    ...getCreatedOn(),
    ...getUpdatedOn()
  }

  return data
}
