<template>
  <button
    class="hamburger__toggle"
    aria-label="toggle navigation"
    @click="toggleNavigation"
  >
    <span class="hamburger"></span>
  </button>
</template>

<script>
  export default {
    name: 'Hamburger',

    emits: ['toggleNavigation'],

    methods: {
      toggleNavigation() {
        return this.$emit('toggleNavigation')
      }
    }
  }
</script>
