import { createStore } from 'vuex'
import getStores from './get_stores'

import { getItem, setItem } from './../../../services/store/get_local_storage'

const DEFAULT_URL = { name: 'home' }
const LOCAL_STORE_KEY = 'url'

const setupStore = app => {
  app.use(getStore())
}

const getLastUrl = () => {
  return getItem(LOCAL_STORE_KEY) || DEFAULT_URL
}

export const getStore = () => {
  const store = {
    state: {
      url: getLastUrl(),
      loading: false,
      saving: false,
      nominationsOpen: false
    },

    getters: {
      getUrl() {
        return getLastUrl()
      },
      isLoading: state => state.loading,
      isSaving: state => state.saving,
      areNominationsOpen: state => state.nominationsOpen
    },

    mutations: {
      setUrl(_ctx, { url }) {
        setItem(LOCAL_STORE_KEY)(url || DEFAULT_URL)
      },

      SET_LOADING(state) {
        state.loading = true
      },

      CLEAR_LOADING(state) {
        state.loading = false
      },

      SET_SAVING(state) {
        state.saving = true
      },

      CLEAR_SAVING(state) {
        state.saving = false
      }
    },

    actions: {
      runSetUp: ctx => {
        return Promise.resolve(true)
          .then(() => ctx.dispatch('locale/runReadAll', null, { root: true }))
          .then(() => ctx.dispatch('category/runReadAll', null, { root: true }))
          .then(() => true)
      },

      runTearDown: _ctx => {
        return Promise.resolve(true)
      }
    },

    modules: {
      ...getStores()
    }
  }

  return createStore(store)
}

export default setupStore
