import AuthenticationError from './authentication.error'
import AuthorizationError from './authorization.error'
import DatabaseError from './database.error'
import NotFoundError from './not_found.error'
import ServerError from './server.error'
import ValidationError from './validation.error'

const isKnownError = err => {
  return (
    err instanceof ValidationError ||
    err instanceof NotFoundError ||
    err instanceof AuthorizationError ||
    err instanceof AuthenticationError ||
    err instanceof DatabaseError ||
    err instanceof ServerError
  )
}

export default isKnownError
