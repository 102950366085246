<template>
  <article class="article">
    <header v-if="$slots.header" class="article__header">
      <slot name="header" />
    </header>

    <div v-if="$slots.default" class="article__content">
      <slot name="default" />
    </div>

    <footer v-if="$slots.footer" class="article__footer">
      <slot name="footer" />
    </footer>
  </article>
</template>
