import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import runAction from '../run_action'

/**
 * Return method to query a list of documents
 * - runQuery - query documents
 *
 * @param {Function} schemaFn function to return document schema
 * @returns {Object} runQuery method
 */
const getQueryMethod = schemaFn => {
  const storeName = schemaFn({}).dyStore
  const store = useStore()

  const router = useRouter()

  const runQuery = runAction({ store, router })(() => {
    return store.dispatch(`${storeName}/runReadAll`)
  })

  return { runQuery }
}

export default getQueryMethod
