const getDocumentState = () => {
  return {
    document: {
      list: [],
      data: {}
    },
    docCount: 0
  }
}

export default getDocumentState
