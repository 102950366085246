import { LABELS } from './../../../setup/collection.constants'

import readAllFn from './../../../setup/client/crud/read_all.wrapper'
import queryByFn from './../../../setup/client/crud/query.wrapper'

import { getLocales } from '../label.schema'
import getLabelXref from './../get_label_xref'

export const namespaced = true

export const state = () => ({
  currentLocale: getLocales()[0],
  defaultLocale: getLocales()[0],
  locales: getLocales(),
  messages: {},
  loaded: false
})

export const getters = {
  getCurrentLocale: state => {
    return state.currentLocale
  },

  getDefaultLocale: state => {
    return state.defaultLocale
  },

  getLocales: state => {
    return state.locales
  },

  getMessages: state => {
    return state.messages
  },

  areLabelsLoaded: state => {
    return state.loaded
  }
}

export const mutations = {
  setCurrentLocale: (state, locale) => {
    state.currentLocale = state.locales.includes(locale)
      ? locale
      : state.defaultLocale
  },

  setMessages: (state, { docs }) => {
    state.messages = getLabelXref(docs)
  },

  setLoaded: state => {
    state.loaded = true
  }
}

export const actions = {
  runReadAll: ctx => {
    const Schema = () => {
      return {
        dyCollection: LABELS
      }
    }

    return readAllFn(Schema)({}).then(({ docs }) => {
      ctx.commit('setLoaded')
      ctx.commit('setMessages', { docs })
      return { docs, docCount: docs.length }
    })
  },

  runReadGuest: ctx => {
    const Schema = () => {
      return {
        dyCollection: LABELS
      }
    }

    const options = {
      where: [
        {
          field: 'guest',
          op: '==',
          value: true
        }
      ]
    }

    return queryByFn(Schema)(options).then(({ docs }) => {
      ctx.commit('setMessages', { docs })
      return { docs, docCount: docs.length }
    })
  }
}
