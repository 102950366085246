import buildError from '../../../errors/build_error'
import getTransformers from '../../../services/transform/get_transformers'
import transform from '../../../services/transform/transform.service'
import getValidators from '../../../services/validate/get_validators'
import validate from '../../../services/validate/validate.service'

import { isBlank } from './../../../utils/data/is_blank'

const validateDocument = schemaFn => doc => {
  const schema = schemaFn(doc)

  return assertDbFields(schema)(doc)
    .then(transformIt(schema))
    .then(validateIt(schema))
    .catch(err => {
      throw err
    })
}

const assertDbFields = schema => doc => {
  const useDoc = isBlank(doc) ? {} : doc

  const dbFields = {}

  if (schema.id && isBlank(useDoc.id)) {
    dbFields.id = ''
  }

  if (schema.createdOn && isBlank(useDoc.createdOn)) {
    dbFields.createdOn = ''
  }

  if (schema.updatedOn && isBlank(useDoc.updatedOn)) {
    dbFields.updatedOn = ''
  }

  return Promise.resolve({ ...useDoc, ...dbFields })
}

const transformIt = schema => doc => {
  return Promise.resolve(true)
    .then(() => getTransformers(schema)(doc))
    .then(transform(doc))
}

const validateIt = schema => doc => {
  return Promise.resolve(true)
    .then(() => getValidators(schema)(doc))
    .then(validate(doc))
    .then(isValid => {
      if (isValid === true) {
        return doc
      }

      throw buildError('validationError')({ doc, errors: isValid })
    })
}

export default validateDocument
