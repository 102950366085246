import { isEmpty } from './../../utils/data/is_empty'
import { isNotNull, isNull } from './../../utils/data/is_null'
import { isDefined, isUndefined } from './../../utils/data/is_undefined'

/**
 * Return true if a document contains a value
 *
 * Names of nested properties a separated by a '.'
 *
 * @param {Object} doc document
 * @param {String} property property name
 * @return Boolean true if document contains property
 */
const isProperty = doc => property => {
  if (isEmpty(doc)) {
    return false
  }

  const tokens = (property || '').split('.')
  const lastField = tokens.pop()

  const parent = tokens.reduce(propertyReducer, doc)
  if (isUndefined(parent) || isNull(parent)) {
    return false
  }

  return Object.prototype.hasOwnProperty.call(parent, lastField)
}

const propertyReducer = (result, property) => {
  return isDefined(result) && isNotNull(result) ? result[property] : result
}

export default isProperty
