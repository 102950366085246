import { isBlank } from '../data/is_blank'

const isDate = date => {
  if (isBlank(date)) {
    return false
  }

  if (typeof date.getUTCDate !== 'function') {
    return false
  }

  if (typeof date.getTime !== 'function') {
    return false
  }

  if (date.toString() === 'Invalid Date' || isNaN(date.getTime())) {
    return false
  }

  return true
}

const isNotDate = date => !isDate(date)

export { isDate, isNotDate }
