<template>
  <slot v-if="isLoading" name="fallback">
    <dy-base-loader :message="message" />
  </slot>
  <slot v-else name="default" />
</template>
<script>
  export default {
    name: 'BaseSuspense',

    props: {
      message: {
        type: String,
        default: 'Loading'
      }
    },

    computed: {
      isLoading() {
        return this.$store.getters['isLoading']
      }
    }
  }
</script>
