<template>
  <form @submit.prevent="$emit('submit')" class="form__container">
    <slot name="default" />

    <dy-base-form-buttons v-if="$slots.buttons">
      <slot name="buttons" />
    </dy-base-form-buttons>
  </form>
</template>
<script>
  export default {
    name: 'BaseForm',

    emits: ['submit']
  }
</script>
