<template>
  <a class="button" :href="href" :arget="_blank">
    <slot />
  </a>
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
        required: true
      }
    }
  }
</script>
