const getDocumentXref = docs => docs.reduce(buildXref, {})

const buildXref = (xref, doc) => {
  if (!doc.id) {
    return xref
  }
  xref[doc.id] = doc
  return xref
}

export default getDocumentXref
