<template>
  <dy-base-article>
    <dy-base-suspense :message="displayMessage">
      <template #default> </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import ServerError from './../../../errors/server.error'

  const getAuthEmail = store => message => {
    return new Promise((resolve, reject) => {
      const emailFromStore = store.getters['auth/getAuthEmail']
      if (emailFromStore) {
        return resolve(emailFromStore)
      }

      const emailFromPrompt = window.prompt(message)
      if (emailFromPrompt) {
        return resolve(emailFromPrompt)
      }

      return reject(new Error('fbauth.errors.emailNotFound'))
    })
  }

  const getUrl = () => {
    if (window && window.location) {
      return window.location.href
    }

    return `${process.env.VUE_APP_app_url}/wecome`
  }

  export default {
    name: 'Welcome',

    computed: {
      displayMessage() {
        return 'Connecting'
      }
    },

    mounted() {
      const message = 'Confirm email address.'
      return getAuthEmail(this.$store)(message)
        .then(email => {
          this.$store.commit('SET_LOADING')
          const payload = {
            url: getUrl(),
            email
          }

          return this.$store.dispatch('auth/runSignOn', payload)
        })
        .then(() => {
          this.$store.commit('auth/setAuthEmail', '')
          this.$store.commit('auth/setAuthenticationError', '')
          const route = this.$store.getters['getUrl']
          this.$router.push(route)

          return true
        })
        .catch(err => {
          if (err.message && err.message.startsWith('fbauth.errors.')) {
            this.$store.commit('auth/setAuthenticationError', err.message)
            return this.$router.push({ name: 'signon' })
          }

          this.$store.commit('SET_ERROR', new ServerError(err))
          this.$router.push({ name: 'error' })
          return true
        })
    }
  }
</script>
