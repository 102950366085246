import i18n from '../../../system/labels/plugins/i18n.plugin'

/**
 * Register application filters
 *
 *
 * @param {Object} app Vue application
 */
const setPlugins = app => {
  app.use(i18n)
}

export default setPlugins
