import { signOut } from 'firebase/auth'

/**
 * Sign Off the current user.
 *
 * @param {Object} fbAuth firebase authentication
 * @returns {Boolean} true
 */
const signOff = fbAuth => {
  return signOut(fbAuth)
    .then(() => true)
    .catch(() => {
      return true
    })
}

export default signOff
