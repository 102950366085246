<template>
  <a class="help__link" target="_blank" :href="link">
    {{ linkName }}
  </a>
</template>

<script>
  export default {
    name: 'Help',

    props: {
      link: {
        type: String,
        required: true
      },

      linkName: {
        type: String,
        required: true
      }
    }
  }
</script>
