import { initializeApp, deleteApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

let app = false
let fdb = false
let fbAuth = false
let fbFn = false

export const startApplication = () => {
  return new Promise(resolve => {
    if (app && fdb && fbAuth && fbFn) {
      return resolve({ fdb, fbAuth, fbFn })
    }

    app = initializeApp({
      apiKey: process.env.VUE_APP_fbclient_apiKey,
      authDomain: process.env.VUE_APP_fbclient_authDomain,
      projectId: process.env.VUE_APP_fbclient_projectId,
      storageBucket: process.env.VUE_APP_fbclient_storage_bucket,
      messagingSenderId: process.env.VUE_APP_fbclient_messaging_sender_id,
      appId: process.env.VUE_APP_fbclient_app_id,
      measurementId: process.env.VUE_APP_fbclient_measurement_id
    })

    fdb = getFirestore()
    if (process.env.VUE_APP_fbclient_firestore_emulator) {
      connectFirestoreEmulator(
        fdb,
        'localhost',
        process.env.VUE_APP_fbclient_firestore_emulator
      )
    }

    fbAuth = getAuth()
    fbFn = getFunctions(app)
    if (process.env.VUE_APP_fbclient_function_emulator) {
      connectFunctionsEmulator(
        fbFn,
        'localhost',
        process.env.VUE_APP_fbclient_function_emulator
      )
    }
    return resolve({ fbAuth, fdb, fbFn })
  })
}

export const getApplication = () => {
  return new Promise((resolve, reject) => {
    if (!app || !fdb || !fbAuth || !fbFn) {
      return reject(new Error('Firebase application has not been started'))
    }

    return resolve({ fdb, fbAuth, fbFn })
  })
}

export const stopApplication = () => {
  if (!app) {
    return Promise.resolve(true)
  }

  return deleteApp(app)
    .then(() => {
      app = false
      return true
    })
    .catch(() => {
      return true
    })
}
