import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'

import { isBlank } from '../../../utils/data/is_blank'

/* eslint-disable no-useless-escape */
const format =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/* eslint-enable no-useless-escape */

/**
 * Sign on a user using an email address and sign on link.
 *
 * On error throw an exception.
 *
 * @param {Object} payload sign on data
 * @returns {Object} user
 */
const signOnWithLink = fbAuth => payload => {
  const { email, url } = { ...payload }

  return verifyEmail(email)
    .then(() => verifyUrl(fbAuth)(url))
    .then(results => {
      return results
    })
    .then(() => signInWithEmailLink(fbAuth, email, url))
    .then(result => {
      return result
    })
    .then(result => result.user)
    .catch(err => {
      if (err.message && err.message.startsWith('fbauth.errors.')) {
        throw err
      }

      throw new Error('fbauth.errors.invalidSignOn')
    })
}

const verifyEmail = email => {
  if (isBlank(email)) {
    return Promise.reject(new Error('fbauth.errors.requiredEmail'))
  }

  if (format.test(email) === false) {
    return Promise.reject(new Error('fbauth.errors.invalidEmail'))
  }

  return Promise.resolve(email)
}

const verifyUrl = fbAuth => url => {
  if (isBlank(url)) {
    return Promise.reject(new Error('fbauth.errors.requiredUrl'))
  }

  if (isSignInWithEmailLink(fbAuth, url) === false) {
    return Promise.reject(new Error('fbauth.errors.invalidUrl'))
  }

  return Promise.resolve(url)
}

export default signOnWithLink
