import getTransformers from './get_transformers'
import transformDocument from './transform.service'

/**
 * Transform a document.
 *
 * @param {Object} schema      document schema
 * @param {string} fieldName   fieldName
 * @param {Object} doc         document
 * @return {Object}            transformed document
 */

const transformField = schema => fieldName => doc => {
  return Promise.resolve(getTransformers(schema)(doc))
    .then(filterByFieldName(fieldName))
    .then(transformDocument(doc))
}

const filterByFieldName = fieldName => transformers => {
  if (!fieldName) {
    return transformers
  }

  const useTransformers = {}
  Object.getOwnPropertyNames(transformers).forEach(field => {
    if (field.indexOf(fieldName) === 0) {
      useTransformers[field] = transformers[field]
    }
  })

  return useTransformers
}

export default transformField
