import { doc, deleteDoc } from 'firebase/firestore'

import assertCollection from '../assert_collection'
import assertId from '../assert_id'
import assertError from '../assert_error'

/**
 * Use the Id to delete a document from a collection.
 *
 * If document cannot be found then return Id of document.
 *
 * @param {Object} fdb firestore connection
 * @param {String} collectionName name of a collection
 * @param {String} id id of a document
 * @returns {String} id
 */
const deleteDocument = fdb => collectionName => id => {
  return assertCollection(collectionName)
    .then(assertId(id))
    .then(() => deleteDoc(doc(fdb, collectionName, id)))
    .then(() => id)
    .catch(assertError)
}

export default deleteDocument
