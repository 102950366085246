import { isBlank } from './../utils/data/is_blank'
import { isEmpty } from './../utils/data/is_empty'

/**
 * Document errors are returned using deflated keys. For example,
 *
 * {
 *  sections.1.questions.0.description.fr: ['errorMessage|description']
 * }
 *
 * This methods inflates the keys of the error object. For example:
 *
 * {
 *  sections: {
 *    1: {
 *      questions: {
 *        0: {
 *          description: {
 *            fr: ['errorMessage|description']
 *          }
 *        }
 *      }
 *    }
 *  }
 * }
 *
 *
 * @param  {object} errors error object
 * @return {object}        inflated error object
 */
const inflateErrors = errors => {
  if (isEmpty(errors)) {
    return {}
  }

  if (isBlank(errors)) {
    return errors
  }

  return Object.getOwnPropertyNames(errors).reduce(expandErrors(errors), {})
}

const expandErrors = errors => (result, fieldName) => {
  return getError(result, fieldName.split('.'), errors[fieldName])
}

const getError = (result, fields, error) => {
  const key = fields.slice(0, 1).pop()
  const keys = fields.slice(1)

  if (keys.length === 0) {
    if (isEmpty(result[key])) {
      result[key] = error
    }
    return result
  }
  if (isEmpty(result[key]) || Array.isArray(result[key])) {
    result[key] = {}
  }

  result[key] = getError(result[key], keys, error)
  return result
}
export default inflateErrors
