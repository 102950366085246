import { useRouter } from 'vue-router'

/**
 * Return method to run a return route.
 * - runRoute
 *
 * params = {
 *   id:  id of a document
 * }
 *
 * @param {Object} schema document schema
 * @returns {Object} document computed properties
 */
const getReturnRoute = schema => {
  const router = useRouter()

  const runReturn = () => router.push({ name: schema.value.dyRoute })

  return {
    runReturn
  }
}

export default getReturnRoute
