import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import runAction from '../run_action'

/**
 * Return method to delete a document
 * - runDelete - delete a document
 *
 * @param {Function} schemaFn function to return document schemaFn
 * @returns {Object} runQuery method
 */

const getDeleteMethod = schemaFn => payload => {
  const { id } = payload
  const schema = schemaFn({})

  const store = useStore()
  const storeName = schema.dyStore

  const router = useRouter()
  const routeName = schema.dyRoute

  const runDelete = () => {
    const payload = { id }
    const deleteFn = () => {
      return store.dispatch(`${storeName}/runDelete`, payload).then(() => {
        return router.push({
          name: routeName
        })
      })
    }
    runAction({ store, router })(deleteFn)()
  }

  return { runDelete }
}

export default getDeleteMethod
