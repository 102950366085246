import { isBlank } from '../../utils/data/is_blank'
import { isArray } from '../../utils/data/is_array'

const inList = fieldValue => x => {
  return Object.prototype.hasOwnProperty.call(x, 'id')
    ? x.id === fieldValue
    : x === fieldValue
}

/**
 * validate of fieldValue is include in a list.
 *
 *
 * USE:
 * {
 *   rule: 'inList',
 *   list: ['ab', 'bc']
 * }
 *
 * @param {object} options options
 * @param {objec}  validateData { fieldValue, doc }
 */
const validate = options => data => {
  const { list } = options
  const { fieldValue } = data

  if (isArray(list) === false) {
    return {
      fieldError: `inList: list must be an array - ${typeof list}`
    }
  }

  if (isBlank(fieldValue) && Array.isArray(fieldValue) === false) {
    return true
  }

  return list.filter(inList(fieldValue)).length >= 1
    ? true
    : { fieldError: 'system.errors.notInList' }
}

export default validate
