import path from 'path'

/**
 * Register Base components with an application.
 *
 * A base component with a file name of BaseInput.vue is registed as
 * component dy-base-input.
 *
 * @param {Object} app Vue application
 * @param {Object} components list of components
 */
const loadBaseComponents = app => components => {
  components.keys().forEach(fileName => {
    const component = components(fileName)
    const componentName = `dy-${getComponentName(fileName)}`

    app.component(componentName, component.default || component)
  })
}

/**
 * Convert the file name of a component to a component name
 *
 * example: BaseInput ===> base-input
 *
 * @param {String} fileName
 * @returns {String} components
 */
const getComponentName = fileName =>
  path
    .basename(fileName)
    .replace(/\.(vue|js)/, '')
    .split('.')
    .shift()
    .match(/[A-Z]+[^A-Z]*|[^A-Z]+/g)
    .map(x => x.toLowerCase())
    .join('-')

export default loadBaseComponents
