import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import runAction from '../run_action'

/**
 * Dispatch a runReadAll action to retrieve a list of documents.
 *
 * Documents are placed in the store.
 *
 * @param {Function} schemaFn function to return document schema
 * @returns {Boolean} true
 */
const runReadAll = schemaFn => {
  const storeName = schemaFn({}).dyStore
  const store = useStore()

  const router = useRouter()

  const setupFn = () => {
    return store.dispatch(`${storeName}/runReadAll`)
  }

  runAction({ store, router })(setupFn)()

  return true
}

export default runReadAll
