<template>
  <div class="notification__bar" :class="notificationTypeClass">
    <p>{{ notification.message }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      notification: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        timeout: null
      }
    },

    computed: {
      notificationTypeClass() {
        return `notification__bar--${this.notification.type}`
      }
    },

    mounted() {
      this.timeout = setTimeout(
        () => this.$store.commit('notification/POP', this.notification),
        5000
      )
    },

    beforeUnmount() {
      clearTimeout(this.timeout)
    }
  }
</script>
