class AuthenticationError extends Error {
  constructor(payload) {
    const { message = 'error.messages.authenticationError' } = { ...payload }

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthenticationError)
    }

    this.code = '403'
    this.title = 'error.labels.authenticationError'
    this.data = {}
    this.errorOn = new Date().toJSON()
  }
}

export default AuthenticationError
