<template>
  <div id="layout__app" class="page__wrapper page--container">
    <dy-header
      :name="applicationName"
      :description="applicationDescription"
      :class="{ 'hamburger--open': false }"
    >
      <template #logo>
        <img
          :src="`/logo.png?v=${applicationVersion}`"
          :alt="applicationName"
          :title="applicationName"
        />
      </template>
    </dy-header>

    <dy-content>
      <template #notification>
        <dy-notification />
      </template>
    </dy-content>

    <dy-footer :copyright="copyright">
      <template #logo>
        <img
          :src="`/footer_logo.png?v=${applicationVersion}`"
          alt="Cosmetics Alliance Canada"
          title="Cosmetics Alliance Canada"
        />
      </template>

      <template #help>
        <dy-help />
      </template>

      <template #poweredby>
        <dy-poweredby :name="applicationName" :version="applicationVersion" />
      </template>
    </dy-footer>
  </div>
</template>

<script>
  import Header from './headers/components/Header.vue'
  import Content from '../../../widgets/contents/components/Content.vue'
  import Notification from '../../../widgets/notifications/components/Notification.vue'
  import Footer from '../../../widgets/footers/components/Footer.vue'
  import Help from '../../../widgets/help/components/Help.vue'
  import PoweredBy from '../../../widgets/poweredby/components/PoweredBy.vue'

  export default {
    name: 'GuestLayout',

    setup() {
      document.title = process.env.VUE_APP_app_name
      return
    },

    inject: ['dyMenuList'],

    computed: {
      applicationName() {
        return process.env.VUE_APP_app_name
      },

      applicationDescription() {
        return process.env.VUE_APP_app_description
      },

      applicationVersion() {
        return process.env.VUE_APP_app_version
      },

      copyright() {
        return process.env.VUE_APP_copyright
      }
    },

    components: {
      'dy-header': Header,
      'dy-content': Content,
      'dy-notification': Notification,
      'dy-footer': Footer,
      'dy-help': Help,
      'dy-poweredby': PoweredBy
    }
  }
</script>
