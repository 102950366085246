<template>
  <dt>{{ fieldLabel }}</dt>
  <dd>{{ $t(getYesNo) }}</dd>
</template>

<script>
  import ViewMixin from './view.mixin'

  export default {
    name: 'BaseViewItem',

    mixins: [ViewMixin],

    computed: {
      getYesNo() {
        return this.fieldValue ? 'system.labels.yes' : 'system.labels.no'
      }
    }
  }
</script>
