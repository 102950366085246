import { isEmpty } from './../../utils/data/is_empty'

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  if (typeof fieldValue === 'string') {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
