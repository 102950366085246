import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore'

import toModels from '../to_models'
import assertError from '../assert_error'
import assertCollection from '../assert_collection'

/**
 * Query documents from a collectionName.
 * @param {Object} fdb firebase connection
 * @param {String} collectionName name of a collectionName
 * @param {Object} options query options
 * @returns [Array] list of documents
 */
const queryBy = fdb => collectionName => options => {
  return assertCollection(collectionName)
    .then(() => [])
    .then(addWhere(options))
    .then(addOrderBy(options))
    .then(addLimit(options))
    .then(constraints => {
      const q = query(collection(fdb, collectionName), ...constraints)
      return getDocs(q)
    })
    .then(toModels)
    .catch(assertError)
}

const addWhere = options => constraints => {
  return (options.where || []).reduce((accum, clause) => {
    const { field, op, value } = clause
    accum.push(where(field, op, value))
    return accum
  }, constraints)
}

const addOrderBy = options => constraints => {
  return (options.orderBy || []).reduce((accum, clause) => {
    const { field, direction } = clause
    accum.push(orderBy(field, direction))
    return accum
  }, constraints)
}

const addLimit = options => constraints => {
  return options.limit && options.limit > 0
    ? [...constraints, limit(options.limit)]
    : constraints
}

export default queryBy
