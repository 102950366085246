<template>
  <dy-base-article>
    <template #header>
      <h1>
        {{ $t('auth.titles.signOff') }}
      </h1>
    </template>

    <template #default>
      <dy-base-loader :message="displayMessage" />
    </template>
  </dy-base-article>
  <article class="article"></article>
</template>

<script>
  import runAction from './../../../widgets/page/run_action'

  export default {
    name: 'SignOff',

    computed: {
      displayMessage() {
        return this.$t('auth.help.waitShutdown')
      }
    },

    mounted() {
      const params = {
        store: this.$store,
        router: this.$router
      }

      const actionFn = () => this.$store.dispatch('auth/runSignOff')
      return runAction(params)(actionFn)()
        .then(() => {
          this.$router.push({ name: 'signon' })
          return true
        })
        .catch(() => {
          this.$router.push({ name: 'signon' })
          return true
        })
    }
  }
</script>
