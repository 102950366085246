<template>
  <footer class="footer">
    <div v-if="$slots.help" class="footer__administrator">
      <slot name="help" />
    </div>

    <div class="footer__content">
      <div v-if="$slots.logo" class="footer__logo">
        <slot name="logo" />
      </div>
      <div class="footer__copyright">
        <p v-html="copyright"></p>
      </div>
      <div class="footer__version">
        <div v-if="$slots.poweredby" class="footer__poweredby">
          <slot name="poweredby" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',

    props: {
      copyright: {
        type: String,
        default: '© Copyright 2021 dataYak.com'
      }
    }
  }
</script>
