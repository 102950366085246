import getDocumentXref from './../../../utils/document/get_document_xref'
import getDocumentIds from './../../../utils/document/get_document_ids'

const getDocumentMutations = () => {
  const setDocument = (state, { doc }) => {
    if (!doc || !doc.id) {
      return
    }

    if (!state.document.list.includes(doc.id)) {
      state.document.list = state.document.list.concat([doc.id])
    }
    state.document.data[doc.id] = doc
  }

  const setDocuments = (state, { docs = [] }) => {
    if (!docs) {
      return
    }

    state.document.list = getDocumentIds(docs)
    state.document.data = getDocumentXref(docs)
  }

  const setDocumentCount = (state, { docCount = 0 }) => {
    state.docCount = docCount
  }

  return { setDocument, setDocuments, setDocumentCount }
}

export default getDocumentMutations
