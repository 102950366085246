import {
  CATEGORY_ROUTE,
  CATEGORY_STORE,
  CATEGORIES
} from '../../setup/collection.constants'

import getId from '../../services/schema/helpers/id.schema'
import getRandomId from '../../services/schema/helpers/random_id.schema'
import getCreatedOn from '../../services/schema/helpers/created_on.schema'
import getUpdatedOn from '../../services/schema/helpers/updated_on.schema'
import setDefault from '../../services/schema/helpers/set_default'
import selectSchema from './../../services/schema/select_schema'

export const Schema = () => {
  const data = {
    dyLabel: 'category.documents.category',
    dyName: 'name',
    dyCollection: CATEGORIES,
    dyRoute: CATEGORY_ROUTE,
    dyStore: CATEGORY_STORE,
    dyType: 'object',

    ...getId(),

    name: {
      dyField: 1,
      dyLabel: 'system.fields.name',
      dyValidation: [{ rule: 'required' }],
      dyType: 'string'
    },

    products: {
      dyField: 1,
      dyLabel: 'category.fields.products',
      dyValidation: [{ rule: 'required' }],
      dyType: 'array',
      dySchema: selectSchema({ schema: 'product' }),
      dySchemaOptions: {
        product: {
          dyField: 1,
          dyLabel: 'category.fields.product',
          dyValidation: [],
          dyType: 'object',
          ...getRandomId({
            key: 'id',
            label: 'system.fields.uid',
            restrictUpdate: true
          }),
          name: {
            dyField: 1,
            dyLabel: 'system.fields.name',
            dyValidation: [{ rule: 'required' }],
            dyType: 'string'
          }
        }
      }
    },

    seq: {
      dyField: 1,
      dyLabel: 'system.fields.sequence',
      dyValidation: [{ rule: 'required' }],
      dyDefault: setDefault(0),
      dyType: 'number'
    },

    ...getCreatedOn(),

    ...getUpdatedOn()
  }

  return data
}
