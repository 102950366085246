<template>
  <div class="todo__container">
    <dy-base-todo-item
      v-for="item in items"
      :key="item.title"
      :icon="item.icon"
      :title="item.title"
      :description="item.description"
      :link="item.link"
      :type="item.type"
    />
  </div>
</template>

<script>
  export default {
    name: 'BaseTodo',

    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>
