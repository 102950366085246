import { isBlank } from './../../utils/data/is_blank'
import sortByMonthNumber from './../../utils/sort/sort_by_field'

const date_format = process.env.VUE_APP_date_format
const DATETIME_FORMAT = process.env.VUE_APP_date_time_format
const YEAR_AND_MONTH_FORMAT = process.env.VUE_APP_date_year_and_month_format
const MONTH_AND_YEAR_FORMAT = process.env.VUE_APP_date_month_and_year_format
const MONTHS = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}

export const getMonths = () => {
  const months = Object.getOwnPropertyNames(MONTHS).map(field => {
    return {
      id: Number(field),
      name: MONTHS[field]
    }
  })
  return sortByMonthNumber(months)('id')
}

const getMonthName = month => MONTHS[Number(month)]

export const formatIt = pattern => value => {
  const format = pattern || date_format

  try {
    if (isBlank(value)) {
      return ''
    }

    const date = new Date(value)
    if (date.toString() === 'Invalid Date' || isNaN(date.getTime())) {
      throw new Error('Invalid date')
    }

    const dateValue = date.toLocaleString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    })

    const tokens = dateValue.split(', ')
    const [year, month, day] = tokens[0].split('-')
    const [hour, minute, second] = tokens[1].split(':')

    return format
      .replace('yyyy', year)
      .replace('MMM', getMonthName(month))
      .replace('MM', month)
      .replace('dd', day)
      .replace('HH', hour === '24' ? '00' : hour)
      .replace('mm', minute)
      .replace('ss', second)
  } catch (err) {
    return err.message
  }
}

export const formatDate = value => formatIt(date_format)(value)
export const formatDateTime = value => formatIt(DATETIME_FORMAT)(value)
export const formatYearAndMonth = value =>
  formatIt(YEAR_AND_MONTH_FORMAT)(value)

export const formatMonthAndYear = value =>
  formatIt(MONTH_AND_YEAR_FORMAT)(value)
