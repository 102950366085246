import pipe from '../../utils/fp/pipe'
import removeProperty from './../../utils/document/remove_property'
import { isEmpty } from '../../utils/data/is_empty'

export const getDefaultFileCount = () => 1

export const getDefaultFileTypes = () => 'application/json'

export const getDefaultFileSize = () => 1000000

export const getDefaultOptions = () => {
  return {
    maxFileCount: getDefaultFileCount(),
    fileTypes: getDefaultFileTypes(),
    maxFileSize: getDefaultFileSize()
  }
}

export const getOptions = validationRules => {
  const defaultRules = getDefaultOptions()
  const rule = (validationRules || [])
    .filter(x => x.rule === 'fileupload')
    .pop()

  return removeProperty({
    ...defaultRules,
    ...rule
  })('rule')
}

/**
 * validate file upload
 *
 * USE:
 * {
 *   rule: 'fileupload',
 *   maxFileCount: 1,
 *   maxFileSize: 1000000,
 *   fileTypes: 'application/json, application/pdf',
 * }
 *
 * Return true if validation passed.
 *
 * Return error object if validation failed.
 *
 * @param {object} options options
 * @param {object}  validateData { doc, fieldValue }
 * @returns {Boolean | Array} return true if validation passed' otherwise a list of errors
 */
const validate = options => validateData => {
  const { fieldValue } = validateData
  if (isEmpty(fieldValue) || Array.isArray(fieldValue) === false) {
    return true
  }

  const result = pipe(
    validateFileCount(options)(fieldValue),
    validateFileTypes(options)(fieldValue),
    validateFileSizes(options)(fieldValue)
  )([])

  return result.length === 0 ? true : result
}

const validateFileCount = options => files => req => {
  const maxFileCount = options.maxFileCount || getDefaultFileCount()
  if (files.length > maxFileCount) {
    return req.concat({
      fieldError: 'file.error.maximumFileCount',
      maxFileCount: maxFileCount,
      fileCount: files.length
    })
  }

  return req
}

const validateFileTypes = options => files => req => {
  const fileTypes = (options.fileTypes || getDefaultFileTypes())
    .split(',')
    .map(x => x.trim().toLowerCase())

  const errors = []

  for (var idx = 0; idx < files.length; idx++) {
    const file = files[idx]
    if (fileTypes.includes(file.contentType) === false) {
      errors.push({
        fieldError: 'file.error.contentType',
        fileName: file.name,
        fileContentType: file.contentType
      })
    }
  }

  return req.concat(errors)
}

const validateFileSizes = options => files => req => {
  const errors = []

  const maxFileSize = options.maxFileSize || getDefaultFileSize()

  for (var idx = 0; idx < files.length; idx++) {
    const file = files[idx]
    if (file.size > maxFileSize) {
      errors.push({
        fieldError: 'file.error.maximumFileSize',
        maxFileSize: maxFileSize,
        fileName: file.name,
        fileSize: file.size
      })
    }
  }

  return req.concat(errors)
}

export default validate
