import transformBoolean from './transform_boolean'
import transformDate from './transform_date'
import transformDatetime from './transform_datetime'
import transformEmail from './transform_email'
import transformNumber from './transform_number'
import transformPhone from './transform_phone'
import transformPostalCode from './transform_postal_code'
import transformRid from './transform_rid'
import transformString from './transform_string'
import transformUid from './transform_uid'
import transformUrl from './transform_url'

const DEFAULT_TRANSFORMERS = {
  boolean: transformBoolean,
  date: transformDate,
  datetime: transformDatetime,
  email: transformEmail,
  number: transformNumber,
  phone: transformPhone,
  postalCode: transformPostalCode,
  rid: transformRid,
  string: transformString,
  uid: transformUid,
  url: transformUrl
}

const getDefaultTransformer = fieldType => {
  return DEFAULT_TRANSFORMERS[fieldType] || false
}

export default getDefaultTransformer
