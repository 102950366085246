import { isEmpty } from './../../utils/data/is_empty'
import { isBlank } from './../../utils/data/is_blank'

const transform = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return null
  }

  if (isBlank(fieldValue)) {
    return ''
  }

  const useValue = fieldValue.toString().replace(/\s/g, '').toUpperCase()

  if (useValue.length === 6) {
    return useValue.substr(0, 3) + ' ' + useValue.substr(3, 3)
  }

  return useValue
}

export default transform
