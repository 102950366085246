<template>
  <dt>&nbsp;</dt>
  <dd class="view__buttons">
    <button class="button" v-bind="$attrs">
      <div class="button__text">
        <slot>{{ $t('system.actions.submit') }}</slot>
      </div>
    </button>
  </dd>
</template>

<script>
  export default {
    name: 'BaseViewButton',
    inheritAttrs: false
  }
</script>
