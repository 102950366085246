import getUid from '../../utils/uid/get_uid'
import sortIt from './../../utils/sort/sort_it'

const getFinishUid = () => `${getUid()}${getUid()}`.replace(/[-]/g, '')

const spawn = user => categories => {
  const doc = {
    id: user.uid || '',
    lastName: user.lastName || '',
    firstName: user.firstName || '',
    email: user.email || '',
    title: '',
    company: '',
    companyType: '',
    city: '',
    postalCode: '',
    newsletter: false,
    completed: false,
    finishedUid: getFinishUid(),
    responses: getResponses(categories),
    createdOn: new Date().toJSON(),
    updatedOn: new Date().toJSON()
  }
  return doc
}

const getResponses = categories => {
  const responses = []
  categories.forEach(ctgy => {
    ctgy.products.forEach(product => {
      responses.push(getResponse(product.id))
    })
  })
  return sortIt('productId:a')(responses)
}

const getResponse = productId => {
  return {
    brand: '',
    product: '',
    comment: '',
    completed: false,
    included: true,
    productId
  }
}

export default spawn
