const getViewActions = readDocumentFn => (ctx, payload) => {
  const { commit } = ctx
  const { id } = payload
  return readDocumentFn(id).then(view => {
    commit('setView', { view })
    return view
  })
}

export default getViewActions
