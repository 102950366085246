<template>
  <header class="header">
    <div
      v-if="$slots.logo"
      class="header__logo"
      :name="name"
      :description="description"
    >
      <slot name="logo" />
    </div>
    <div class="header__hamburger" v-if="$slots.hamburger">
      <slot name="hamburger" />
    </div>
  </header>
</template>

<script>
  export default {
    name: 'Header',

    emits: ['toggleNavigation'],

    props: {
      name: {
        type: String,
        default: 'ApplicationName'
      },

      description: {
        type: String,
        default: 'This is the application description'
      }
    },

    methods: {
      toggleNavigation() {
        return this.$emit('toggleNavigation')
      }
    }
  }
</script>
