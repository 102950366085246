<template>
  <dy-base-form-field :isError="isError" :isStacked="isStacked">
    <template v-if="fieldLabel" #label>
      <label :for="fieldId">{{ fieldLabel }}</label>
    </template>

    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>
    <template #default>
      <div class="select">
        <select
          :id="fieldId"
          :name="fieldName"
          :value="fieldValue"
          v-bind="$attrs"
          @change="updateFieldValue"
        >
          <option
            v-for="option in getOptions"
            :value="option.id"
            :key="option.id"
            :selected="option.id === this.fieldValue"
          >
            {{ this.$t(option.name) }}
          </option>
        </select>
        <span class="select__focus"></span>
      </div>
    </template>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from './../setup_form_field.mixin'

  export default {
    name: 'BaseSelect',

    mixins: [SetupFormField],

    props: {
      options: {
        type: Array,
        required: true,
        validator(opts) {
          return !opts.find(opt => typeof opt !== 'object')
        }
      }
    },

    computed: {
      getOptions() {
        return [{ id: '', name: 'system.labels.choose' }, ...this.options]
      }
    }
  }
</script>
