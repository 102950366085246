import App from './application/App.vue'

import setupStore from './store/setup_store'
import setupRouter from './router/setup_router'

import setupComponents from './components/setup_components'
import setupFilters from './filters/setup_filters'
import setupPlugins from './plugins/setup_plugins'
import setupMenus from './menus/setup_menus'

export const createApplication = createFn => {
  return createFn(App)
}

export const setupApplication = app => {
  setupStore(app)
  setupRouter(app)
  setupComponents(app)
  setupFilters(app)
  setupPlugins(app)
  setupMenus(app)
}
