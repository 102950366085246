export const namespaced = true

/**
 *  notifications: [
 *    {
 *      id: 3,
 *      type: 'success',
 *      message: 'This is it.'
 *    },
 *    {
 *      id: 4,
 *      type: 'error',
 *      message: 'This is the error that has happened.'
 *    }
 *  ]
 */

export const state = {
  notifications: []
}

export const getters = {
  getNotifications(state) {
    return state.notifications
  }
}

let nextId = 1

export const mutations = {
  PUSH(state, message) {
    state.notifications.push({
      id: nextId++,
      type: 'success',
      message
    })
  },

  PUSH_ERROR(state, message) {
    state.notifications.push({
      id: nextId++,
      type: 'error',
      message
    })
  },

  POP(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationToRemove.id
    )
  }
}
