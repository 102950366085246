import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  const fieldType = typeof fieldValue
  if (fieldType === 'boolean') {
    return true
  }

  if (fieldType === 'string' && isBlank(fieldValue)) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
