import isField from './is_field'

const filterFields = schema => field => {
  return schema[field] && isField(schema[field])
}

const getFields = schema => {
  return Object.getOwnPropertyNames(schema).filter(filterFields(schema))
}

export default getFields
