// firestore and local collections
export const CATEGORIES = 'categories'
export const LABELS = 'labels'
export const MAIL_QUEUE = 'mail_queue'
export const NOTIFICATIONS = 'notifications'
export const REMINDERS = 'reminders'
export const SURVEY_COMPLETIONS = 'survey_completions'
export const SURVEYS = 'surveys'
export const USERS = 'users'

// routes
export const CATEGORY_ROUTE = 'categories'
export const REPORT_ROUTE = 'reports'
export const SURVEY_ROUTE = 'surveys'

// stores
export const CATEGORY_STORE = 'category'
export const SURVEY_STORE = 'survey'
