import { isBlank } from './../../utils/data/is_blank'

export const isSurveySetup = survey => {
  const { lastName, firstName, title, company, companyType, city, postalCode } =
    survey

  const missingData =
    isBlank(lastName) ||
    isBlank(firstName) ||
    isBlank(title) ||
    isBlank(company) ||
    isBlank(companyType) ||
    isBlank(city) ||
    isBlank(postalCode)

  return !missingData
}

export const isSurveyCompleted = survey => !!survey.completed

export const getQuestionCount = survey => {
  return survey ? survey.responses.length : 0
}

export const getVoteCount = survey => {
  return survey
    ? survey.responses.reduce((accum, response) => {
        return response.completed ? accum + 1 : accum
      }, 0)
    : 0
}
