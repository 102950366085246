<template>
  <dy-base-form>
    <dy-base-input
      :schema="schema"
      :doc="doc"
      :docErrors="docErrors"
      fieldName="email"
      type="email"
      :stacked="true"
      @updateFieldValue="runUpdateFieldValue"
    />

    <template #buttons>
      <dy-base-button @click="runSignOn">
        {{ $t('auth.actions.signon') }}
      </dy-base-button>
    </template>
  </dy-base-form>
</template>

<script>
  export default {
    name: 'SignOnForm',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      },

      docErrors: {
        type: Object,
        required: true
      }
    },

    emits: ['runSignOn', 'runUpdateFieldValue'],

    methods: {
      runUpdateFieldValue(payload) {
        return this.$emit('runUpdateFieldValue', payload)
      },

      runSignOn() {
        return this.$emit('runSignOn')
      }
    }
  }
</script>
