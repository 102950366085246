<template>
  <dy-base-article>
    <template #header>
      <h1>Cast Your Vote!</h1>
    </template>

    <dy-base-suspense>
      <template #default>
        <p class="info">
          Welcome to the 2024 <strong>”I Recommend"</strong> Awards survey. Your
          expertise is <strong>SO</strong> important to us and we are thrilled
          you’re here to share the products you recommend most.
        </p>

        <p v-if="isSurveyCompleted === true" style="text-align: center">
          <strong>
            Congratulations! You have completed your I Recommend survey.
          </strong>
        </p>

        <dy-base-todo :items="todos" />

        <p class="info">
          Please submit your recommendations by
          <strong>Friday April 5, 2024</strong>.
        </p>
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  import setupViewPage from './../../../widgets/page/view/setup_view_page'

  import { Schema } from './../../surveys/survey.schema'

  import {
    isSurveySetup,
    isSurveyCompleted,
    getQuestionCount,
    getVoteCount
  } from './../../surveys/survey'

  export default {
    name: 'Home',

    setup() {
      const store = useStore()

      const currentUser = computed(() => {
        return store.getters['auth/getCurrentUser']
      })
      const { schema, doc } = setupViewPage('read')(Schema(true))({
        id: currentUser.value.uid
      })

      return { currentUser, schema, doc }
    },

    computed: {
      isSurveySetup() {
        return isSurveySetup(this.doc)
      },

      isSurveyCompleted() {
        return isSurveyCompleted(this.doc)
      },

      questions() {
        return getQuestionCount(this.doc)
      },

      votes() {
        return getVoteCount(this.doc)
      },

      todos() {
        const todos = []

        if (this.isSurveySetup === false) {
          todos.push({
            icon: 'action/profile',
            title: 'Create your profile',
            description: 'Your first step is to create your profile.',
            link: {
              name: 'surveys:user',
              params: { id: this.currentUser.uid }
            },
            type: 'setup'
          })
        }

        if (this.isSurveySetup === true && this.isSurveyCompleted === true) {
          todos.push({
            icon: 'action/view',
            title: 'Review your recommendations',
            description:
              'Congratulations! You have completed your I Recommend survey.',
            link: {
              name: 'surveys:edit',
              params: { id: this.currentUser.uid }
            },
            type: 'review'
          })
        }

        if (this.isSurveySetup === true && this.isSurveyCompleted === false) {
          todos.push({
            icon: 'comment/pending',
            title: 'Cast your votes',
            description: `You have completed ${this.votes} out of a possible ${this.questions} recommendations.`,
            link: {
              name: 'surveys:edit',
              params: { id: this.currentUser.uid }
            },
            type: 'todo'
          })
        }

        if (this.isSurveySetup === true) {
          todos.push({
            icon: 'action/profile',
            title: 'Review your profile',
            description: 'Update your profile.',
            link: {
              name: 'surveys:user',
              params: { id: this.currentUser.uid }
            },
            type: 'review'
          })
        }
        return todos
      }
    }
  }
</script>
