import { addDoc, collection, doc, setDoc } from 'firebase/firestore'

import assertError from './../assert_error'
import assertCollection from './../assert_collection'

/**
 * Create a new document.
 * - auto-generate id
 *
 * Return document with id.
 *
 * @param {Object} fdb firestore connection
 * @param {String} collectionName name of a collectionName
 * @param {Object} document a document
 * @returns {Object}    document
 */
const createDocument = fdb => collectionName => document => {
  const { id, ...docWithoutId } = document

  if (!id) {
    return assertCollection(collectionName)
      .then(() => addDoc(collection(fdb, collectionName), document))
      .then(ref => {
        return {
          id: ref.id,
          ...document
        }
      })
      .catch(assertError)
  }

  return assertCollection(collectionName)
    .then(() => setDoc(doc(fdb, collectionName, id), docWithoutId))
    .then(() => document)
    .catch(assertError)
}

export default createDocument
