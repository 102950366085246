<template>
  <div
    v-if="areLabelsLoaded"
    id="layout__app"
    class="page__wrapper page--container"
  >
    <dy-header
      :name="applicationName"
      :description="applicationDescription"
      :class="{ 'hamburger--open': isNavigationOpen }"
      @toggleNavigation="toggleNavigation"
    >
      <template #logo>
        <img
          :src="`/logo.png?v=${applicationVersion}`"
          :alt="applicationName"
          :title="applicationName"
        />
      </template>

      <template #hamburger>
        <dy-hamburger @toggleNavigation="toggleNavigation" />
      </template>
    </dy-header>

    <dy-navigation
      :class="{ 'menu--open': isNavigationOpen }"
      :menus="getActiveMenues"
      @openLink="openLink"
    />

    <dy-content>
      <template #notification>
        <dy-notification />
      </template>
    </dy-content>

    <dy-footer :copyright="copyright">
      <template #logo>
        <img
          :src="`/footer_logo.png?v=${applicationVersion}`"
          alt="Cosmetics Alliance Canada"
          title="Cosmetics Alliance Canada"
        />
      </template>

      <template #help>
        <dy-help />
      </template>

      <template #poweredby>
        <dy-poweredby :name="applicationName" :version="applicationVersion" />
      </template>
    </dy-footer>
  </div>
</template>

<script>
  import { ref } from 'vue'

  import Header from './headers/components/Header.vue'
  import Hamburger from './../../../widgets/hamburger/components/Hamburger.vue'
  import MenuList from './../../../widgets/menus/components/MenuList.vue'
  import Content from './../../../widgets/contents/components/Content.vue'
  import Notification from './../../../widgets/notifications/components/Notification.vue'
  import Footer from './../../../widgets/footers/components/Footer.vue'
  import Help from './../../../widgets/help/components/Help.vue'
  import PoweredBy from './../../../widgets/poweredby/components/PoweredBy.vue'

  export default {
    name: 'App',

    setup() {
      const toggleNavigation = () => {
        isNavigationOpen.value = !isNavigationOpen.value
      }
      const isNavigationOpen = ref(false)
      const openLink = () => {
        isNavigationOpen.value = false
      }

      document.title = process.env.VUE_APP_app_name

      return { isNavigationOpen, toggleNavigation, openLink }
    },

    inject: ['dyMenuList'],

    computed: {
      applicationName() {
        return process.env.VUE_APP_app_name
      },

      applicationDescription() {
        return process.env.VUE_APP_app_description
      },

      applicationVersion() {
        return process.env.VUE_APP_app_version
      },

      copyright() {
        return process.env.VUE_APP_copyright
      },

      currentUser() {
        return this.$store.getters['auth/getCurrentUser']
      },

      isAuthenticated() {
        return this.$store.getters['auth/isAuthenticated']
      },

      isAdministrator() {
        return this.$store.getters['auth/isAdministrator']
      },

      isSuperUser() {
        return this.$store.getters['auth/isSuperUser']
      },

      areLabelsLoaded() {
        return this.$store.getters['locale/areLabelsLoaded']
      },

      getActiveMenues() {
        const payload = {
          isAuthenticated: this.isAuthenticated,
          isSuperUser: this.isSuperUser,
          isAdministrator: this.isAdministrator
        }
        return this.dyMenuList
          .map(x => {
            const fn = x.isActive || true
            return fn === true || fn(payload) ? x : false
          })
          .filter(x => x)
      }
    },

    components: {
      'dy-header': Header,
      'dy-hamburger': Hamburger,
      'dy-navigation': MenuList,
      'dy-content': Content,
      'dy-notification': Notification,
      'dy-footer': Footer,
      'dy-help': Help,
      'dy-poweredby': PoweredBy
    }
  }
</script>
