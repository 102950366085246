<template>
  <label class="radio radio-gradient">
    <span class="radio__input">
      <input
        :name="name"
        :checked="checked"
        :value="optionId"
        type="radio"
        v-bind="$attrs"
        @change="updateFieldValue"
      />
      <span class="radio__control"></span>
    </span>
    <span class="radio__label">{{ optionName }}</span>
  </label>
</template>

<script>
  export default {
    inheritAttrs: false,

    props: {
      name: {
        type: String,
        required: true
      },

      optionId: {
        type: String,
        required: true
      },

      optionName: {
        type: String,
        required: true
      },

      checked: {
        type: Boolean,
        required: true
      }
    },

    emits: ['updateFieldValue'],

    methods: {
      updateFieldValue(evt) {
        const payload = {
          optionId: evt.target.value
        }
        return this.$emit('updateFieldValue', payload)
      }
    }
  }
</script>
