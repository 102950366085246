<template>
  <dy-base-form-field :isError="isError" :isStacked="true">
    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>

    <template #default>
      <input
        class="input"
        :id="fieldId"
        :name="fieldName"
        :value="fieldValue"
        :placeholder="fieldPlaceholder"
        :maxlength="characterLimit"
        :type="type"
        v-bind="$attrs"
        @change="updateFieldValue"
      />
    </template>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from './../../../widgets/forms/setup_form_field.mixin'

  export default {
    name: 'SurveyInput',

    mixins: [SetupFormField],

    props: {
      type: {
        type: String,
        default: 'text',
        validator(type) {
          return ['text', 'email', 'password', 'number'].includes(type)
        }
      }
    },

    computed: {
      characterLimit() {
        const rangeRule = this.fieldValidation
          .filter(x => x.rule === 'range' && x.maximum > 0)
          .pop()
        return rangeRule ? rangeRule.maximum : 90
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form__field--stacked {
    display: grid;
    grid-template-columns: 8em 1fr;
    grid-template-areas: 'control control';
    grid-gap: 1em;
    margin-bottom: 0.75em;
  }
</style>
