<template>
  <svg
    width="0"
    height="0"
    style="display: none"
    v-html="$options.iconSprite"
  />
</template>

<script>
  /**
   * Build an SVG sprite out of individual svg files.
   * - remove title and style tags from svg files
   * - remove fill attributes
   * - search this folder recursively for all *.svg files
   * - svg id is folder/fileName  e.g. action/open_section
   */

  const context = require.context(
    '!svg-inline-loader?' +
      'removeTags=true' +
      '&removeSVGTagAttrs=true' +
      '&removingTagAttrs=fill' +
      '!./',
    true,
    /\w+\.svg$/i
  )

  const symbols = context.keys().map(path => {
    const content = context(path)

    const id = path.replace(/^\.\/(.*)\.\w+$/, '$1')

    return content
      .replace('<svg', `<symbol id="${id}"`)
      .replace('svg>', 'symbol>')
  })
  export default {
    name: 'IconSprite',
    iconSprite: symbols.join('\n')
  }
</script>
