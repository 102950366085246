<template>
  <a href="" class="action-link" @click.prevent="goto">
    <dy-base-icon icon="action/view" class="icon--primary icon--md" />
  </a>
</template>

<script>
  export default {
    name: 'BaseViewLink',

    emits: ['gotoView'],

    methods: {
      goto() {
        return this.$emit('gotoView')
      }
    }
  }
</script>
