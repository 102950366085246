import { isEmpty } from './../../utils/data/is_empty'
import { isBlank } from './../../utils/data/is_blank'
import { isDate } from '../../utils/date/is_date'

const transform = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return null
  }

  if (isBlank(fieldValue)) {
    return null
  }

  if (isDate(fieldValue)) {
    return fieldValue.toJSON()
  }

  const useDate = new Date(fieldValue.toString())

  return isDate(useDate) ? useDate.toJSON() : fieldValue.toString()
}

export default transform
