const getDeleteAction = deleteDocumentFn => (ctx, payload) => {
  const { commit } = ctx
  const { id } = payload

  // TODO: retrieve document from store
  const doc = { id }

  return deleteDocumentFn(doc).then(result => {
    commit('deleteDocument', { id })
    return result
  })
}

export default getDeleteAction
