const getUpdateFieldAction = validateFieldFn => (ctx, payload) => {
  const { commit } = ctx
  const { doc, fieldName, fieldValue } = payload

  return validateFieldFn(doc)(fieldName)(fieldValue).then(result => {
    commit('setEditDocumentField', result)
  })
}

export default getUpdateFieldAction
