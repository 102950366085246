import { getApplication } from '../../../database_services/fbclient/fb_client'
import deleteDocument from '../../../database_services/fbclient/delete/delete_document'

import getCollectionName from './../../../services/schema/get_document_collection'

const wrapDeleteDocument = schemaFn => doc => {
  const schema = schemaFn(document)
  const collectionName = getCollectionName(schema)

  return getApplication().then(({ fdb }) =>
    deleteDocument(fdb)(collectionName)(doc.id)
  )
}

export default wrapDeleteDocument
