<template>
  <dy-base-article>
    <template #header>
      <h2>Nominate your most recommended products.</h2>
    </template>

    <dy-base-suspense>
      <template v-slot:default>
        <dy-survey
          :categories="categories"
          :schema="schema"
          :doc="doc"
          :docErrors="docErrors"
          @runUpdateFieldValue="runUpdateSurvey"
          @runUpdate="runUpdate"
          @runAllDone="runAllDone"
        />
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import setupEditPage from './../../../widgets/page/edit/setup_edit_page'

  import SurveyForm from './../components/SurveyForm.vue'
  import { Schema } from './../survey.schema'

  export default {
    name: 'EditSurvey',

    props: {
      id: {
        type: String,
        required: true
      }
    },

    setup(props) {
      const {
        schema,
        doc,
        docErrors,
        runReturn,
        runUpdateFieldValue,
        runUpdate
      } = setupEditPage(Schema(true))(props)

      return {
        schema,
        doc,
        docErrors,
        runReturn,
        runUpdateFieldValue,
        runUpdate
      }
    },

    components: {
      'dy-survey': SurveyForm
    },

    computed: {
      categories() {
        return this.$store.getters['category/getDocuments']
      }
    },

    methods: {
      runAllDone() {
        const payload = {
          fieldName: 'completed',
          fieldValue: true
        }
        console.log('runAllDone', payload)

        return this.runUpdateFieldValue(payload)
          .then(() => this.runUpdate())
          .then(() => {
            return this.$router.push({ name: 'home' })
          })
      },

      runUpdateSurvey(payload) {
        return this.runUpdateFieldValue(payload).then(() => {
          return this.runUpdate()
        })
      }
    }
  }
</script>
