import getValue from '../../utils/document/get_property_value'

import { UID } from './auth.schema'

const spawn = data => {
  return {
    id: getValue(data)('id')(UID),
    email: getValue(data)('email')('')
  }
}

export default spawn
