const validRid = /[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/

const s4 = () => Math.random().toString(36).substring(2, 6)

const transform = ({ fieldValue }) => {
  return validRid.test(fieldValue)
    ? fieldValue
    : `${s4()}-${s4()}-${s4()}-${s4()}`.toLowerCase()
}

export default transform
