/**
 * Setup menus
 *
 * populate a global variable 'dyMenuList' with menu items
 * included in application
 *
 * @param {Object} app Vue application
 */

const setupNavigation = app => {
  app.provide('dyMenuList', getMenuList())
}

const getMenuList = () => {
  return [
    {
      link: '/',
      name: 'home.labels.home',
      isActive: payload => payload.isAuthenticated
    },

    {
      link: '/reports',
      name: 'report.documents.reports',
      isActive: payload => payload.isAdministrator
    },

    {
      link: '/categories',
      name: 'category.documents.categories',
      isActive: payload => payload.isSuperUser || payload.isAdministrator
    },

    {
      link: '/surveys',
      name: 'survey.documents.surveys',
      isActive: payload => payload.isSuperUser
    },

    {
      link: '/signoff',
      name: 'auth.actions.signOff',
      isActive: payload => payload.isAuthenticated
    },

    {
      link: '/signon',
      name: 'auth.actions.signOn',
      isActive: payload => !payload.isAuthenticated
    }
  ]
}

export default setupNavigation
