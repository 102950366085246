<template>
  <dy-base-form-field :isError="isError" :isStacked="isStacked">
    <template v-if="fieldLabel" #label>
      <label>{{ fieldLabel }}</label>
    </template>

    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>

    <template #default>
      <div class="form__field__date">
        <select @change="changeYear" class="input form__field__year">
          <option
            v-for="yy in years"
            :key="yy.id"
            :value="yy.id"
            :selected="yy.id === year"
          >
            {{ $t(yy.name.toString()) }}
          </option>
        </select>

        <select @change="changeMonth" class="input form__field__month">
          <option
            v-for="mm in months"
            :key="mm.id"
            :value="mm.id"
            :selected="mm.id === month"
          >
            {{ $t(mm.name) }}
          </option>
        </select>

        <select @change="changeDay" class="input form__field__day">
          <option key="0" value="0" :selected="0 === day">
            {{ $t('system.labels.choose') }}
          </option>
          <option
            v-for="i in daysInMonth"
            :key="i"
            :value="i"
            :selected="i === day"
          >
            {{ i }}
          </option>
        </select>
      </div>
    </template>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from './setup_form_field.mixin'

  const months = [
    { id: 0, name: 'system.labels.choose' },
    { id: 1, name: 'system.labels.january' },
    { id: 2, name: 'system.labels.february' },
    { id: 3, name: 'system.labels.march' },
    { id: 4, name: 'system.labels.april' },
    { id: 5, name: 'system.labels.may' },
    { id: 6, name: 'system.labels.june' },
    { id: 7, name: 'system.labels.july' },
    { id: 8, name: 'system.labels.august' },
    { id: 9, name: 'system.labels.september' },
    { id: 10, name: 'system.labels.october' },
    { id: 11, name: 'system.labels.november' },
    { id: 12, name: 'system.labels.december' }
  ]

  const emptyDate = () => {
    return {
      getFullYear: () => 0,
      getMonth: () => -1,
      getDate: () => 0
    }
  }

  export default {
    name: 'BaseDate',

    mixins: [SetupFormField],

    props: {
      numberOfYears: {
        type: Number,
        default: 20
      }
    },

    data() {
      return {
        months: months
      }
    },

    computed: {
      currentDate() {
        return this.fieldValue ? new Date(this.fieldValue) : emptyDate()
      },

      year() {
        return this.currentDate.getFullYear()
      },

      month() {
        return this.currentDate.getMonth() + 1
      },

      daysInMonth() {
        return new Date(this.year, this.month, 0).getDate()
      },

      day() {
        return this.currentDate.getDate()
      },

      startingYear() {
        return this.year ? this.year - 15 : new Date().getFullYear() - 15
      },

      endingYear() {
        return this.startingYear + this.numberOfYears
      },

      years() {
        const years = [{ id: 0, name: 'system.lable.choose' }]
        for (let ii = this.startingYear; ii < this.endingYear; ii++) {
          years.push({ id: ii, name: ii })
        }

        return years
      }
    },

    methods: {
      changeYear(evt) {
        const year = evt.target.value
        const month = this.month === 0 ? new Date().getMonth() + 1 : this.month
        const lastDay = new Date(year, month, 0).getDate()
        const day =
          this.day === 0
            ? new Date().getDate()
            : this.day <= lastDay
            ? this.day
            : lastDay

        const payload = {
          fieldName: this.fieldName,
          fieldValue: new Date(year, month - 1, day)
        }
        this.$emit('updateFieldValue', payload)
      },

      changeMonth(evt) {
        const year = this.year === 0 ? new Date().getFullYear() : this.year
        const month = evt.target.value
        const lastDay = new Date(year, month, 0).getDate()
        const day =
          this.day === 0
            ? new Date().getDate()
            : this.day <= lastDay
            ? this.day
            : lastDay

        const payload = {
          fieldName: this.fieldName,
          fieldValue: new Date(year, month - 1, day)
        }
        this.$emit('updateFieldValue', payload)
      },

      changeDay(evt) {
        const year = this.year === 0 ? new Date().getFullYear() : this.year
        const month = this.month === 0 ? new Date().getMonth() + 1 : this.month
        const day = evt.target.value

        const payload = {
          fieldName: this.fieldName,
          fieldValue: new Date(year, month - 1, day)
        }
        this.$emit('updateFieldValue', payload)
      }
    }
  }
</script>
