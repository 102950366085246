import getProperty from '../../utils/document/get_property_value'
import { isBlank } from '../../utils/data/is_blank'

/**
 * ensure the startDate is on or before the endDate
 *
 *
 * USE:
 * {
 *   rule: 'dateMinMax',
 *   message: 'system.errors.dateRange',
 *   endDateField: 'options.endDate
 * }
 *
 * @param {object} options options
 * @param {object}  validateData { fieldValue, doc }
 */

const validate =
  options =>
  ({ fieldValue, doc }) => {
    const message = options.message || 'system.errors.dateMinMax'
    const endDateField = options.endDateField

    if (!endDateField) {
      return { fieldError: 'dateRange: An end date field must be specified.' }
    }

    const endDate = getProperty(doc)(endDateField)(undefined)

    if (isBlank(fieldValue) || isBlank(endDate)) {
      return true
    }

    if (fieldValue <= endDate) {
      return true
    }

    return {
      fieldError: message,
      startDate: fieldValue,
      endDate
    }
  }

export default validate
