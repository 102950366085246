import transform from '../../../services/transform/transform'
import validate from '../../../services/validate/validate'
import cloneDocument from '../../../utils/document/clone_document'
import { get, set } from '../../../utils/object/object.service'
import inflateErrors from './../../../errors/inflate_errors'

const validateField = schemaFn => doc => fieldName => fieldValue => {
  const schema = schemaFn(doc)

  return updateDocumentField(doc)(fieldName)(fieldValue)
    .then(transform(schema)(fieldName))
    .then(validate(schema)(fieldName))
    .then(doc => {
      const payload = {
        id: doc.id,
        fieldName,
        fieldValue: get(doc)(fieldName)(fieldValue),
        fieldErrors: []
      }
      return payload
    })
    .catch(err => {
      const value =
        err.code === '400'
          ? get(err.data.doc)(fieldName)(fieldValue)
          : fieldValue

      const errors =
        err.code === '400'
          ? inflateErrors(err.data.errors)
          : inflateErrors({
              [fieldName]: [
                {
                  fieldLabel: 'system.fields.unknown',
                  fieldError: err.message
                }
              ]
            })

      const payload = {
        id: doc.id,
        fieldName,
        fieldValue: value,
        fieldErrors: errors[fieldName]
      }

      return payload
    })
}

const updateDocumentField = doc => fieldName => fieldValue => {
  return Promise.resolve(cloneDocument(doc)).then(doc => {
    set(doc)(fieldName)(fieldValue)
    return doc
  })
}
export default validateField
