import { getApplication } from '../../../database_services/fbclient/fb_client'
import readDocument from '../../../database_services/fbclient/read/read_document'

import getCollectionName from './../../../services/schema/get_document_collection'

const wrapReadDocument = schemaFn => id => {
  const schema = schemaFn({})
  const collectionName = getCollectionName(schema)

  return getApplication().then(({ fdb }) =>
    readDocument(fdb)(collectionName)(id)
  )
}

export default wrapReadDocument
