<template>
  <router-link :to="menu.link" custom v-slot="{ href, navigate }">
    <li class="menuitem" @click="openLink">
      <a :href="href" class="menuitem__link" @click="navigate">
        {{ $t(menu.name) }}
      </a>
    </li>
  </router-link>
</template>

<script>
  export default {
    name: 'MenuItem',

    props: {
      menu: {
        type: Object,
        required: true
      }
    },

    emits: ['openLink'],

    methods: {
      openLink() {
        return this.$emit('openLink')
      }
    }
  }
</script>
