import { isBlank } from './../../utils/data/is_blank'

import { ASSIGN_UID } from './transform.constants'

const transform = ({ fieldValue }) => {
  if (isBlank(fieldValue)) {
    return ASSIGN_UID
  }

  return fieldValue.trim()
}

export default transform
