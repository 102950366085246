import { createRouter, createWebHistory } from 'vue-router'

import getRoutes from './get_routes'

import { getStore } from '../store/setup_store'

import AuthenticationError from '../../../errors/authentication.error'
import AuthorizationError from '../../../errors/authorization.error'
import isAuthenticationError from '../../../errors/is_authentication_error'

const setupRouter = app => {
  app.use(getRouter())
}

const getRouter = () => {
  const store = getStore()

  const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_router_base),
    routes: [...getRoutes()]
  })

  router.beforeEach((to, from, next) => {
    const areNominationsOpen = store.getters['areNominationsOpen']
    if (areNominationsOpen === false) {
      if (to.name === 'closed' || to.name === 'signoff') {
        store.commit('SET_LOADING')
        return Promise.resolve(next())
      }

      return Promise.resolve(next({ name: 'closed' }))
    }

    return Promise.resolve({ to, from })
      .then(checkAuthentication)
      .then(assertAuthentication)
      .then(assertAuthorization)
      .then(() => {
        store.commit('SET_LOADING')
        return next()
      })
      .catch(err => {
        if (isAuthenticationError(err)) {
          store.commit('SET_LOADING')
          store.commit('setUrl', { url: to })
          next({ name: 'signon' })
          return
        }

        store.commit('SET_ERROR', err)
        return next({ name: 'error' })
      })
  })

  const checkAuthentication = payload => {
    return Promise.resolve(true).then(() => {
      const isAuthenticationRequired = payload.to.matched.some(x => {
        return (
          x.meta.requiresAuthentication ||
          x.meta.requiresAdministrator ||
          x.meta.requiresSuperUser
        )
      })

      if (isAuthenticationRequired === false) {
        return payload
      }

      const isAuthenticated = store.getters['auth/isAuthenticated']

      if (isAuthenticated === true) {
        return payload
      }

      return store.dispatch('auth/runVerifyAuthentication').then(isVerified => {
        if (isVerified === false) {
          return payload
        }

        return store.dispatch('runSetUp').then(() => payload)
      })
    })
  }

  const assertAuthentication = payload => {
    return Promise.resolve(true).then(() => {
      const requiresAuthentication = payload.to.matched.some(
        x => x.meta.requiresAuthentication
      )
      const isAuthenticated = store.getters['auth/isAuthenticated']
      if (requiresAuthentication && isAuthenticated === false) {
        throw new AuthenticationError({})
      }

      return payload
    })
  }

  const assertAuthorization = payload => {
    return Promise.resolve(true).then(() => {
      const requiresAuthorization = payload.to.matched.some(x => {
        return x.meta.requiresAdministrator || x.meta.requiresSuperUser
      })

      if (requiresAuthorization === false) {
        return payload
      }

      const requiresAdministrator = payload.to.matched.some(
        x => x.meta.requiresAdministrator
      )
      const isAdministrator = store.getters['auth/isAdministrator']

      if (requiresAdministrator && isAdministrator) {
        return payload
      }

      const requiresSuperUser = payload.to.matched.some(
        x => x.meta.requiresSuperUser
      )
      const isSuperUser = store.getters['auth/isSuperUser']

      if (requiresSuperUser && isSuperUser) {
        return payload
      }

      throw new AuthorizationError({})
    })
  }

  return router
}

export default setupRouter
