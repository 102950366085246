class AuthorizationError extends Error {
  constructor(payload) {
    const { message = 'error.messages.authorizationError' } = { ...payload }

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthorizationError)
    }

    this.code = '401'
    this.title = 'error.labels.authorizationError'
    this.data = {}
    this.errorOn = new Date().toJSON()
  }
}

export default AuthorizationError
