<template>
  <table class="listTable">
    <thead v-if="$slots.header" class="listTable__header">
      <slot name="header" />
    </thead>

    <tbody v-if="$slots.default" class="listTable__body">
      <slot name="default" />
    </tbody>

    <tfoot v-if="$slots.footer" class="listTable__footer">
      <slot name="footer" />
    </tfoot>
  </table>
</template>
