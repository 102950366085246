import { doc, getDoc } from 'firebase/firestore'

import buildError from '../../../errors/build_error'

import assertCollection from '../assert_collection'
import assertId from '../assert_id'
import assertError from './../assert_error'
import toModel from './../to_model'

/**
 * Use the Id to read a document from a collection.
 *
 * If document cannot be found then throw a NotFoundError.
 *
 * @param {Object} fdb firebase database connection
 * @param {String} collectionName name of a collection
 * @param {String} id id of a document
 * @returns {Object} document
 */
const readDocument = fdb => collectionName => id => {
  return assertCollection(collectionName)
    .then(assertId(id))
    .then(() => getDoc(doc(fdb, collectionName, id)))
    .then(toModel)
    .then(doc => {
      if (doc === false) {
        throw buildError('notFound')({
          message: `Document in collection ${collectionName} with id ${id} cannot be found.`,
          url: `/${collectionName}/${id}`
        })
      }
      return doc
    })
    .catch(assertError)
}

export default readDocument
