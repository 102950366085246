import { getApplication } from '../../../database_services/fbclient/fb_client'
import signOnWithLink from '../../../database_services/fbclient/signon/sign_on_with_link'

const wrapSignOn = payload => {
  return getApplication()
    .then(({ fbAuth }) => signOnWithLink(fbAuth)(payload))
    .then(user => user)
}

export default wrapSignOn
