import toModel from './to_model'

const toModels = snapshots => {
  const docs = []

  if (snapshots.empty) {
    return docs
  }

  snapshots.forEach(snapshot => {
    docs.push(toModel(snapshot))
  })

  return docs
}

export default toModels
