import { isNotNull } from '../../utils/data/is_null'
import { isDefined } from '../../utils/data/is_undefined'

import cloneDocument from './clone_document'

/**
 * Set the value of a property
 *
 * Nested properties can be accessed using a comma delimited list of property
 * names:
 *  - name
 *  - address.street
 *  - favourite.colours.1
 *
 * Parents of nested properties must exists; otherwise no changes are made.
 *
 * If document is null, undefined, or empty then an empty document {}
 * is returned
 *
 * @param {Object}  doc document
 * @param {String} property property
 * @param {Any} defaultValue default value
 * @returns {Object} updated document
 */
const setPropertyValue = doc => property => value => {
  const updatedDoc = cloneDocument(doc)

  const fields = property.split('.')
  const lastField = fields.pop()

  const result = fields.reduce(fieldReducer, updatedDoc)
  if (isDefined(result) && isNotNull(result)) {
    result[lastField] = value
  }

  return updatedDoc
}

const fieldReducer = (result, field) => {
  return isDefined(result) && isNotNull(result) ? result[field] : result
}

export default setPropertyValue
