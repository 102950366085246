import axios from 'axios'

import buildError from '../../../errors/build_error'

import signOn from './../../../setup/client/crud/sign_on.wrapper'
import signOff from './../../../setup/client/crud/sign_off.wrapper'
import verifyAuthentication from './../../../setup/client/crud/verify_authentication.wrapper'

import { getItem, setItem } from './../../../services/store/get_local_storage'
import { isNotBlank } from '../../../utils/data/is_blank'

const sendSignOnLink = payload => {
  const { email } = { ...payload }
  const headers = {
    accept: 'application/json',
    'content-type': 'application/json'
  }
  const url = process.env.VUE_APP_whoami_url
  const params = {
    apiToken: process.env.VUE_APP_whoami_token
  }
  const data = {
    email,
    url: `${process.env.VUE_APP_app_url}/welcome`
  }

  return axios
    .post(url, data, { headers, params })
    .then(() => true)
    .catch(err => {
      if (err.response) {
        throw buildError('serverError')({
          statusCode: err.response.status,
          message: err.response.data
        })
      } else if (err.request) {
        throw buildError('serverError')({
          statusCode: err.request.status,
          message: err.request.toString()
        })
      }

      throw buildError('serverError')({ message: err.message })
    })
}

export const namespaced = true

export const state = {
  currentUser: {},
  authError: ''
}

export const getters = {
  isAuthenticationError: state => isNotBlank(state.authError),

  getAuthenticationError: state =>
    isNotBlank(state.authError) ? state.authError : '',

  isSuperUser: state => {
    return !!(state.currentUser && state.currentUser.isSuperUser === true)
  },

  isAdministrator: state => {
    return !!(state.currentUser && state.currentUser.isAdministrator === true)
  },

  isAuthenticated: state => {
    return state.currentUser && !!state.currentUser.uid
  },

  getCurrentUser: state => {
    return state.currentUser ? state.currentUser : {}
  },

  getUserLocale: _state => {
    return 'en'
  },

  getAuthEmail: _state => {
    const email = getItem('authEmail') || ''
    return email
  }
}

export const mutations = {
  setAuthenticationError: (state, message) => {
    state.authError = isNotBlank(message) ? message : ''
  },

  setCurrentUser: (state, payload = {}) => {
    const {
      uid,
      email,
      displayName,
      claims = {}
    } = {
      ...payload
    }
    if (uid && email) {
      state.currentUser = {
        uid,
        email,
        name: displayName || '',
        isSuperUser: !!claims.super,
        isAdministrator: !!claims.administrator
      }
    }
  },

  resetCurrentUser: state => {
    state.currentUser = false
    state.authEmail = {}
  },

  setAuthEmail: (_state, email) => {
    setItem('authEmail')(email)
  }
}

export const actions = {
  runVerifyAuthentication: ({ commit }) => {
    return verifyAuthentication().then(user => {
      if (user) {
        commit('setCurrentUser', user)
        return true
      }

      commit('resetCurrentUser')
      return false
    })
  },

  runAuthenticationChange: ({ commit }, user) => {
    return new Promise(resolve => {
      if (user) {
        commit('setCurrentUser', user)
      } else {
        commit('resetCurrentUser')
      }
      return resolve(true)
    })
  },

  runSendSignOnLink: ({ commit }, payload) => {
    return sendSignOnLink(payload)
      .then(() => {
        commit('setAuthEmail', payload.email)
        return true
      })
      .catch(_err => {
        return false
      })
  },

  runSignOn: (_ctx, payload) => {
    return signOn(payload).then(() => {
      return true
    })
  },

  runSignOff: () => {
    return signOff()
  }
}
