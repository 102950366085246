import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'
import { isPlainObject } from './../../utils/data/is_plain_object'

const isEmptyObject = x => isPlainObject(x) && Object.keys(x).length === 0

const validate = () => payload => {
  const { fieldValue } = payload
  if (isEmpty(fieldValue)) {
    return { fieldError: 'system.errors.required' }
  }

  if (isEmptyObject(fieldValue)) {
    return { fieldError: 'system.errors.required' }
  }

  if (isBlank(fieldValue)) {
    return { fieldError: 'system.errors.required' }
  }

  return true
}

export default validate
