<template>
  <a href="#" class="listTable__button" @click.prevent="gotoCreatePage">
    <dy-base-icon icon="action/add" class="icon--md" />
    {{ $t('system.actions.createDocument', { label: $t(schema.dyLabel) }) }}
  </a>
</template>

<script>
  import { useRouter } from 'vue-router'

  export default {
    name: 'BaseDocumentAdd',

    props: {
      schema: {
        type: Object,
        required: true
      }
    },

    setup(props) {
      const router = useRouter()
      const route = `${props.schema.dyRoute}:new`

      const gotoCreatePage = () => router.push({ name: route })

      return { gotoCreatePage }
    }
  }
</script>
