<template lang="html">
  <tr>
    <td :data-name="$t('system.fields.id')">
      {{ doc.id }}
    </td>

    <td :data-name="$t('system.fields.name')">
      {{ doc.name }}
    </td>

    <td :data-name="$t('category.fields.products')">
      {{ doc.products.length }}
    </td>

    <td :data-name="$t('system.fields.sequence')">
      {{ doc.seq }}
    </td>

    <td :data-name="$t('system.fields.actions')">
      <dy-base-view-link @gotoView="gotoViewPage" />
    </td>
  </tr>
</template>

<script>
  export default {
    name: 'CategoryListItem',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      }
    },

    methods: {
      gotoViewPage() {
        return this.$router.push({
          name: 'categories:view',
          params: { id: this.doc.id }
        })
      }
    }
  }
</script>
