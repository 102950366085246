<template>
  <dy-base-form-field :isError="isError" :isStacked="true">
    <template v-if="fieldError" #error>
      {{ fieldError }}
    </template>

    <div class="textarea__container">
      <textarea
        v-bind="$attrs"
        :id="fieldId"
        v-model="currentValue"
        :placeholder="fieldPlaceholder"
        class="input"
        @change="updateFieldValue"
      >
      </textarea>

      <div class="textarea__wordcount textarea--alert" :class="getAlert">
        {{ characterCount }} of {{ characterLimit }} characters ({{ wordCount }}
        words)
      </div>
    </div>
  </dy-base-form-field>
</template>

<script>
  import { SetupFormField } from './../../../widgets/forms/setup_form_field.mixin'

  export default {
    name: 'SurveyTextArea',

    mixins: [SetupFormField],

    data() {
      return {
        currentValue: ''
      }
    },

    computed: {
      characterLimit() {
        const rangeRule = this.fieldValidation
          .filter(x => x.rule === 'range' && x.maximum > 0)
          .pop()
        return rangeRule ? rangeRule.maximum : 99999
      },

      characterCount() {
        const crlfCount = this.currentValue.match(/[\r\n]/g)
        return this.currentValue.length + (crlfCount ? crlfCount.length : 0)
      },

      wordCount() {
        const wordCount = this.currentValue.match(/\b\w+\b/g)
        return wordCount ? wordCount.length : 0
      },

      getAlert() {
        return { 'textarea--alert': this.characterCount > this.characterLimit }
      }
    },

    created() {
      this.currentValue = this.fieldValue.toString()
    },

    watch: {
      fieldValue: function (newValue, _oldValue) {
        this.currentValue = newValue
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form__field--stacked {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'control';
    grid-gap: 1em;
    margin-bottom: 0.75em;
  }

  .textarea__container {
    display: flex;
    flex-direction: column;
  }

  .textarea--alert {
    font-size: 0.9em;
    color: darkblue;
  }

  textarea {
    height: 10em;
  }
</style>
