<template>
  <div :class="getContainerClass" class="form__field">
    <slot name="default" />

    <div v-if="$slots.error" class="form__field__error">
      <slot name="error" />
    </div>

    <slot v-if="$slots.label" name="label" />
  </div>
</template>
<script>
  export default {
    props: {
      isError: {
        type: Boolean,
        default: false
      },

      isStacked: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      getContainerClass() {
        return {
          'form__field--stacked': this.isStacked,
          'form__field--alert': this.isError
        }
      }
    }
  }
</script>
