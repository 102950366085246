import { isEmpty } from './../../utils/data/is_empty'
import { isBlank } from './../../utils/data/is_blank'
import { isDate } from '../../utils/date/is_date'

const transform = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return null
  }

  if (isBlank(fieldValue)) {
    return null
  }

  // remove hours, minutes, and secods
  if (isDate(fieldValue)) {
    return new Date(
      fieldValue.getFullYear(),
      fieldValue.getMonth(),
      fieldValue.getDate()
    )
  }

  const useValue = fieldValue.toString()

  const useDate =
    useValue.includes('-') || useValue.includes('/') || useValue.includes('.')
      ? getDate(
          useValue.substring(0, 4),
          useValue.substring(5, 7),
          useValue.substring(8, 10)
        )
      : getDate(
          useValue.substring(0, 4),
          useValue.substring(4, 6),
          useValue.substring(6, 8)
        )

  return isDate(useDate) ? useDate : fieldValue.toString()
}

const getDate = (yyyy, mm, dd) => {
  const year = Number(yyyy)
  const month = Number(mm)
  const day = Number(dd)

  if (isNaN(year) || isNaN(month) || isNaN(dd)) {
    return false
  }

  return new Date(year, month - 1, day)
}

export default transform
