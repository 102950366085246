<template>
  <main class="content">
    <slot name="notification" />

    <router-view />
  </main>
</template>

<script>
  export default {
    name: 'Content'
  }
</script>
