import removeProperty from '../../../utils/document/remove_property'
import removeItem from './../../../utils/list/remove_item_from_list'

const getDeleteMutations = () => {
  const deleteDocument = (state, { id }) => {
    if (!id) {
      return
    }

    if (state.document && state.document.list) {
      state.document.list = removeItem(state.document.list)(id)
    }

    if (state.document && state.document.data) {
      state.document.data = removeProperty(state.document.data)(id)
    }

    if (state.view) {
      state.view = removeProperty(state.view)(id)
    }

    if (state.edit) {
      state.edit = removeProperty(state.edit)(id)
    }

    if (state.editError) {
      state.editError = removeProperty(state.editError)(id)
    }
  }

  return {
    deleteDocument
  }
}

export default getDeleteMutations
