import inflateErrors from './../../../errors/inflate_errors'

const getEditGetters = () => {
  return {
    getEditDocument: state => id => {
      return state.edit[id] || false
    },

    getEditError: state => id => {
      return state.editError[id] ? inflateErrors(state.editError[id]) : {}
    }
  }
}

export default getEditGetters
