<template>
  <dy-base-article>
    <template #header>
      <h1>{{ $t('survey.documents.surveys') }}</h1>
    </template>

    <dy-base-suspense>
      <template #default>
        <dy-survey :schema="schema" :docs="docs" :docCount="docs.length" />
      </template>
    </dy-base-suspense>
  </dy-base-article>
</template>

<script>
  import setupQueryPage from '../../../widgets/page/query/setup_query_page'

  import SurveyList from './../components/SurveyList.vue'
  import { Schema } from './../survey.schema'

  export default {
    name: 'ListSurveys',

    setup() {
      const { schema, docs } = setupQueryPage(Schema(false))

      return {
        schema,
        docs
      }
    },

    components: {
      'dy-survey': SurveyList
    }
  }
</script>
