<template lang="html">
  <tr>
    <td :data-name="$t('system.fields.email')">
      {{ doc.email }}
    </td>

    <td :data-name="$t('system.fields.name')">
      {{ $filters.formatName(doc) }}
    </td>

    <td :data-name="$t('survey.fields.votes')">
      {{ completedCount }}
    </td>

    <td :data-name="$t('survey.fields.completed')">
      {{ doc.completed }}
    </td>

    <td :data-name="$t('system.fields.actions')">
      <dy-base-view-link @gotoView="gotoViewPage" />
      <dy-base-delete-link :schema="schema" :doc="doc" @runDelete="runDelete" />
    </td>
  </tr>
</template>

<script>
  import { Schema } from './../survey.schema'
  import setupDeleteMethod from './../../../widgets/page/delete/get_delete_method'

  export default {
    name: 'SurveyListItem',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      }
    },

    setup(props) {
      const { runDelete } = setupDeleteMethod(Schema(false))(props.doc)

      return { runDelete }
    },

    computed: {
      completedCount() {
        return this.doc.responses.reduce((accum, response) => {
          if (response.completed) {
            accum += 1
          }
          return accum
        }, 0)
      }
    },

    methods: {
      gotoViewPage() {
        return this.$router.push({
          name: 'surveys:view',
          params: { id: this.doc.id }
        })
      }
    }
  }
</script>
