<template lang="html">
  <dy-base-view>
    <dy-base-view-slot>
      <template v-slot:label>
        {{ $t('survey.fields.user') }}
      </template>

      <template v-slot:value>
        <div v-html="user" />
      </template>
    </dy-base-view-slot>

    <dy-base-view-slot>
      <template v-slot:label>
        {{ $t('survey.fields.responses') }}
      </template>

      <template v-slot:value>
        <dy-response
          v-for="payload in completedResponses"
          :key="payload.response.id"
          :schema="responseSchema(payload.idx)"
          :doc="payload.response"
        />
      </template>
    </dy-base-view-slot>

    <dy-base-view-datetime :schema="schema" :doc="doc" fieldName="createdOn" />

    <dy-base-view-datetime :schema="schema" :doc="doc" fieldName="updatedOn" />

    <dy-base-view-button @click="runReturn">
      {{ $t('system.actions.return') }}
    </dy-base-view-button>
  </dy-base-view>
</template>

<script>
  import sortIt from '../../../utils/sort/sort_it'
  import getFieldSchema from './../../../services/schema/get_field_schema'

  import SurveyViewResponse from './SurveyViewResponse'

  export default {
    name: 'SurveyView',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      },

      xref: {
        type: Object,
        required: true
      }
    },

    emits: ['runReturn'],

    computed: {
      user() {
        const buffer = []
        buffer.push(this.doc.email)
        buffer.push(this.$filters.formatName(this.doc))
        if (this.doc.company) {
          buffer.push(this.doc.company)
        }

        if (this.doc.title) {
          buffer.push(this.doc.title)
        }

        if (this.doc.companytype) {
          buffer.push(this.doc.companytype)
        }
        if (this.doc.city) {
          buffer.push(this.doc.city)
        }

        if (this.doc.postalCode) {
          buffer.push(this.doc.postalCode)
        }

        const votes = this.doc.responses.filter(x => x.completed).length
        buffer.push(this.$t('survey.fields.votes', { iCount: votes }))

        const status = this.doc.completed
          ? 'survey.fields.completed'
          : 'survey.fields.pending'
        buffer.push(this.$t(status))

        if (this.doc.newsletter) {
          buffer.push(this.$t('survey.labels.addToNewsletter'))
        }

        return buffer.join('<br />')
      },

      completedResponses() {
        const responses = this.doc.responses
          .map((response, idx) => {
            if (response.completed === false) {
              return false
            }

            const {
              categoryName = 'unknown',
              productName = 'unknown',
              seq = 99
            } = this.xref[response.productId]

            return {
              response: {
                ...response,
                categoryName,
                productName
              },
              idx,
              seq
            }
          })
          .filter(x => x)

        return sortIt('seq:a')(responses)
      }
    },

    methods: {
      responseSchema(idx) {
        return getFieldSchema(this.schema)(this.doc)(`responses.${idx}`)
      },

      runReturn() {
        return this.$emit('runReturn')
      }
    },

    components: {
      'dy-response': SurveyViewResponse
    }
  }
</script>
