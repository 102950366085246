import readOrCreate from './../read_or_create_survey'
import readDocumentFn from './../../../setup/client/crud/read_document.wrapper'
import readAllFn from './../../../setup/client/crud/read_all.wrapper'
import updateDocumentFn from './../../../setup/client/crud/update_document.wrapper'
import deleteDocumentFn from './../../../setup/client/crud/delete_document.wrapper'
import validateFieldFn from './../../../setup/client/crud/validate_field.wrapper'

import getDocumentAction from '../../../services/store/document/get_document_action'
import getDocumentState from '../../../services/store/document/get_document_state'
import getDocumentGetters from '../../../services/store/document/get_document_getters'
import getDocumentMutations from '../../../services/store/document/get_document_mutations'

import getViewAction from '../../../services/store/view/get_view_actions'
import getViewState from '../../../services/store/view/get_view_state'
import getViewGetters from '../../../services/store/view/get_view_getters'
import getViewMutations from '../../../services/store/view/get_view_mutations'

import getEditAction from '../../../services/store/edit/get_edit_action'
import getEditFieldAction from '../../../services/store/edit/get_edit_field_action'
import getEditState from '../../../services/store/edit/get_edit_state'
import getEditGetters from '../../../services/store/edit/get_edit_getters'
import getEditMutations from '../../../services/store/edit/get_edit_mutations'

import getUpdateAction from '../../../services/store/update/get_update_actions'

import getDeleteAction from '../../../services/store/delete/get_delete_action'
import getDeleteMutations from '../../../services/store/delete/get_delete_mutations'

import { Schema, isResponseCompleted } from './../survey.schema'

const updateSurvey = schemaFn => document => {
  const responses = document.responses.map(x => {
    return { ...x, completed: isResponseCompleted(x) }
  })

  const updatedDocument = {
    ...document,
    responses
  }
  return updateDocumentFn(schemaFn)(updatedDocument)
}

export const namespaced = true

export const state = {
  ...getDocumentState(),
  ...getViewState(),
  ...getEditState(),
  query: {
    orderBy: [
      {
        field: 'email',
        direction: 'asc'
      }
    ]
  }
}

export const getters = {
  ...getDocumentGetters(),
  ...getViewGetters(),
  ...getEditGetters()
}

export const mutations = {
  ...getDocumentMutations(),
  ...getViewMutations(),
  ...getEditMutations(),
  ...getDeleteMutations()
}

export const actions = {
  runRead: (ctx, payload) => {
    const { rootGetters } = ctx
    const user = rootGetters['auth/getCurrentUser']
    const readFn = readOrCreate(user)(Schema(true))
    return getEditAction(readFn)(ctx, { ...payload, id: user.uid })
  },
  runView: getViewAction(readDocumentFn(Schema(false))),
  runReadAll: getDocumentAction(readAllFn(Schema(false))),
  runDelete: getDeleteAction(deleteDocumentFn(Schema(false))),
  runUpdateFieldValue: getEditFieldAction(validateFieldFn(Schema(false))),
  runUpdate: getUpdateAction(updateSurvey(Schema(false)))
}
