import { isBlank } from '../../utils/data/is_blank'
import { isEmpty } from '../../utils/data/is_empty'

const uidRe = /^[a-zA-Z0-9]{28}$/

/**
 * Validate the id of a firebase user.
 *
 * @param {Object} payload document and fieldValue
 * @returns {Boolean | Object} true if validation passes
 */
const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  const isString = typeof fieldValue === 'string'
  if (isBlank(fieldValue) && isString) {
    return true
  }

  if (isString === false) {
    return { fieldError: 'system.errors.invalid' }
  }

  if (uidRe.test(fieldValue)) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
