<template>
  <button class="button button_action" :disabled="isSaving">
    <span class="button__response" v-if="isSaving">Saving...</span>
    <div class="button__text">
      <slot>{{ $t('system.actions.submit') }}</slot>
    </div>
  </button>
</template>

<script>
  export default {
    computed: {
      isSaving() {
        return this.$store.getters.isSaving
      }
    }
  }
</script>

<style lang="scss">
  .button_action {
    position: relative;
  }

  .button_action .button__response {
    // visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
</style>
