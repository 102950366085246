<template>
  <th data-name="&nbsp;" class="listTable__count">
    {{ documentCount }}
  </th>
</template>

<script>
  export default {
    props: {
      count: {
        type: Number,
        required: true
      }
    },

    computed: {
      documentCount() {
        return this.$t('system.labels.documentCount', {
          iCount: this.count
        })
      }
    }
  }
</script>
