<template>
  <div class="notification__container">
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
  import NotificationBar from './NotificationBar'

  export default {
    components: { NotificationBar },
    computed: {
      notifications() {
        return this.$store.getters['notification/getNotifications']
      }
    }
  }
</script>
