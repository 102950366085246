<template>
  <div class="file__container">
    <dy-base-form-field :isError="isError" :isStacked="isStacked">
      <template v-if="fieldLabel" #label>
        <label :for="fieldId">{{ fieldLabel }}</label>
      </template>

      <template v-if="fieldError" #error>
        {{ fieldError }}
      </template>

      <template #default>
        <div class="file__wrapper">
          <div class="file__dropbox">
            <input
              :id="fieldId"
              :name="fieldName"
              :multiple="maxFileCount > 1"
              :accept="fileTypes"
              type="file"
              :disabled="disableUpload"
              @change="filesChange($event)"
            />
            <p>
              {{ $t('upload.help.dropToBegin') }}
            </p>

            <div class="file__log">
              <ul>
                <li v-for="file in fieldValue" :key="file.name">
                  <span> {{ file.name }} ({{ file.size }}) </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </dy-base-form-field>
  </div>
</template>

<script>
  import { SetupFormField } from '../setup_form_field.mixin'
  import { getFileContents } from './get_file_content'
  import { getOptions } from './../../../services/validate/validate_file_upload'

  const getFileRequest = evt => {
    return Promise.resolve({
      fieldName: evt.target.name,
      fileList: evt.target.files,
      fileData: []
    })
  }

  const fetchFileContents = req => {
    return getFileContents(req.fileList).then(fileData => {
      return {
        ...req,
        fileData
      }
    })
  }

  export default {
    name: 'BaseFileUpload',

    mixins: [SetupFormField],

    emits: ['updateFieldValue'],

    data() {
      return {
        disableUpload: false
      }
    },

    computed: {
      uploadOptions() {
        return getOptions(this.fieldValidation)
      },

      fileTypes() {
        return this.uploadOptions.fileTypes
      },

      maxFileCount() {
        return this.uploadOptions.maxFileCount
      },

      maxFileSize() {
        return this.uploadOptions.maxFileSize
      }
    },

    methods: {
      startUpload() {
        this.disableUpload = true
      },

      stopUpload() {
        this.disableUpload = false
      },

      filesChange(evt) {
        return Promise.resolve(this.startUpload())
          .then(() => getFileRequest(evt))
          .then(fetchFileContents)
          .then(req => {
            const { fileData } = req

            const payload = {
              target: {
                type: 'file',
                value: fileData
              }
            }

            this.updateFieldValue(payload)

            this.stopUpload()
          })
          .catch(err => {
            console.log('err', err)
            this.stopUpload()
            return true
          })
      }
    }
  }
</script>
