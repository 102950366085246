import readDocumentFn from './../../../setup/client/crud/read_document.wrapper'
import readAllFn from './../../../setup/client/crud/read_all.wrapper'

import getDocumentAction from '../../../services/store/document/get_document_action'
import getDocumentState from '../../../services/store/document/get_document_state'
import getDocumentGetters from '../../../services/store/document/get_document_getters'
import getDocumentMutations from '../../../services/store/document/get_document_mutations'

import getViewAction from '../../../services/store/view/get_view_actions'
import getViewState from '../../../services/store/view/get_view_state'
import getViewGetters from '../../../services/store/view/get_view_getters'
import getViewMutations from '../../../services/store/view/get_view_mutations'

import getProductXref from './../get_product_xref'
import { Schema } from './../category.schema'

export const namespaced = true

export const state = {
  ...getDocumentState(),
  ...getViewState(),
  query: {
    orderBy: [
      {
        field: 'seq',
        direction: 'asc'
      }
    ]
  }
}

export const getters = {
  ...getDocumentGetters(),
  ...getViewGetters(),
  getProductXref: (_state, getters) => {
    return getProductXref(getters.getDocuments)
  }
}

export const mutations = {
  ...getDocumentMutations(),
  ...getViewMutations()
}

export const actions = {
  runView: getViewAction(readDocumentFn(Schema)),
  runReadAll: getDocumentAction(readAllFn(Schema))
}
