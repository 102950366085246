import getFieldSchema from './../../services/schema/get_field_schema'
import getFieldHelp from './../../services/schema/get_field_help'
import getFieldLabel from './../../services/schema/get_label'
import getValidationRules from './../../services/schema/get_validation_rules'

import { get } from './document'
import getPropertyList from './../../utils/document/get_property_list'

export const SetupFormField = {
  inheritAttrs: false,

  props: {
    schema: {
      type: Object,
      required: true
    },

    doc: {
      type: Object,
      required: true
    },

    docErrors: {
      type: Object,
      required: false
    },

    fieldName: {
      type: String,
      required: true
    },

    stacked: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['updateFieldValue'],

  computed: {
    fieldSchema() {
      return getFieldSchema(this.schema)(this.doc)(this.fieldName)
    },

    fieldLabel() {
      return this.$t(getFieldLabel(this.fieldSchema))
    },

    fieldValidation() {
      return getValidationRules(this.fieldSchema)
    },

    fieldId() {
      return `${this.fieldName}_${Math.random().toString(36).substring(2, 5)}`
    },

    fieldValue() {
      return get(this.doc)(this.fieldName)()
    },

    fieldPlaceholder() {
      return this.fieldLabel
    },

    fieldHelp() {
      return getFieldHelp(this.fieldSchema)
    },

    fieldError() {
      const errors = get(this.docErrors)(this.fieldName)([])
      if (errors.length === 0) {
        return false
      }

      return errors
        .map(x => {
          const params = getPropertyList(x).reduce((accum, field) => {
            if (x !== this.fieldName) {
              accum[field] = this.$t(x[field])
            }
            return accum
          }, {})
          return this.$t(x.fieldError, params)
        })
        .join(', ')
    },

    isError() {
      const errors = get(this.docErrors)(this.fieldName)([])
      return errors.length === 0 ? false : true
    },

    isStacked() {
      return this.stacked || this.fieldLabel.length > 20
    }
  },

  methods: {
    updateFieldValue(evt) {
      const payload = {
        fieldName: this.fieldName,
        fieldValue:
          evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
      }

      this.$emit('updateFieldValue', payload)
    }
  }
}
