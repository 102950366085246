import { isBlank } from '../../utils/data/is_blank'
import { isEmpty } from '../../utils/data/is_empty'

/* eslint-disable no-useless-escape */
const format =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

/* eslint-enable no-useless-escape */

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  const isString = typeof fieldValue === 'string'
  if (isBlank(fieldValue) && isString) {
    return true
  }

  if (isString === false) {
    return { fieldError: 'system.errors.invalid' }
  }

  if (format.test(fieldValue)) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
