import { getApplication } from '../../../database_services/fbclient/fb_client'
import updateDocument from '../../../database_services/fbclient/update/update_document'

import validateDocument from './validate_document.wrapper'

import getCollectionName from './../../../services/schema/get_document_collection'

const wrapUpdateDocument = schemaFn => document => {
  return validateDocument(schemaFn)(document)
    .then(() => getApplication())
    .then(({ fdb }) => {
      const schema = schemaFn(document)
      const collectionName = getCollectionName(schema)
      return updateDocument(fdb)(collectionName)(document)
    })
    .catch(err => {
      throw err
    })
}

export default wrapUpdateDocument
