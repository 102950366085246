<template>
  <article class="article">
    <header class="article-header">
      <h1>{{ $t('survey.titles.thankYou') }}</h1>
    </header>

    <dy-base-suspense>
      <template v-slot:default>
        <p>{{ $t('survey.messages.surveyCompleted') }}</p>
        <p>{{ $t('survey.messages.thankYou') }}</p>
      </template>
    </dy-base-suspense>
  </article>
</template>

<script>
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'

  import isValidationError from './../../../errors/is_validation_error'
  import isNotFoundError from './../../../errors/is_not_found_error'

  import { Schema } from './../finished.schema'
  import createDocumentFn from './../../../setup/client/crud/create_document.wrapper'

  export default {
    name: 'Finished',

    props: {
      finishedUid: {
        type: String,
        required: true
      }
    },

    setup(props) {
      const store = useStore()
      const router = useRouter()

      store.commit('SET_LOADING')

      const doc = {
        finishedUid: props.finishedUid,
        processedOn: '',
        processedError: '',
        createdOn: new Date().toJSON()
      }

      const tasks = [
        store.dispatch('locale/runReadGuest'),
        createDocumentFn(Schema)(doc)
      ]

      Promise.all(tasks)
        .then(() => {
          store.commit('CLEAR_LOADING')
          return true
        })
        .catch(err => {
          console.log('err', JSON.stringify(err, null, 2))
          if (isValidationError(err) || isNotFoundError(err)) {
            store.commit('notification/PUSH_ERROR', err.title)
            store.commit('CLEAR_LOADING')
            return false
          }

          store.commit('SET_ERROR', err)
          router.push({ name: 'error' })
          store.commit('CLEAR_LOADING')
          return false
        })

      return { doc }
    }
  }
</script>
