import { getApplication } from '../../../database_services/fbclient/fb_client'
import queryBy from '../../../database_services/fbclient/query/query_by'

import getCollectionName from './../../../services/schema/get_document_collection'

const wrapQuery = schemaFn => options => {
  const schema = schemaFn({})
  const collectionName = getCollectionName(schema)
  return getApplication()
    .then(({ fdb }) => queryBy(fdb)(collectionName)(options))
    .then(docs => {
      return { docs, docCount: docs.length }
    })
}

export default wrapQuery
