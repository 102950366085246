class NotFoundError extends Error {
  constructor(payload) {
    const {
      url = 'system.errors.unknown',
      message = 'error.messages.notFoundError'
    } = { ...payload }

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError)
    }

    this.code = '404'
    this.title = 'error.labels.notFoundError'
    this.data = { url }
    this.errorOn = new Date().toJSON()
  }
}

export default NotFoundError
