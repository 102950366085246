import cloneDocument from './../../../utils/document/clone_document'
import setProperty from './../../../utils/document/set_property_value_strict'
import { isBlank } from '../../../utils/data/is_blank'
import removeProperty from '../../../utils/document/remove_property'

const getEditMutations = (key = 'id') => {
  const setEditDocument = (state, { doc, errors = {} }) => {
    if (isBlank(doc)) {
      return
    }
    const useDoc = cloneDocument(doc)
    const id = useDoc[key] || ''
    state.edit[id] = useDoc
    state.editError[id] = errors
  }

  const setEditDocumentField = (state, payload) => {
    const { id, fieldName, fieldValue, fieldErrors } = { ...payload }
    if (isBlank(fieldName)) {
      return
    }

    const lastFieldName = fieldName.split('.').pop()
    if (lastFieldName !== 'root') {
      state.edit[id] = setProperty(state.edit[id])(fieldName)(fieldValue)
    }

    if (fieldErrors && fieldErrors.length >= 1) {
      state.editError[id][fieldName] = fieldErrors
    } else if (state.editError[id]) {
      state.editError[id] = removeProperty(state.editError[id])(fieldName)
    }
  }

  return { setEditDocument, setEditDocumentField }
}

export default getEditMutations
