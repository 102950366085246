<template>
  <div class="card__container">
    <div class="card__icon">
      <dy-base-icon :icon="icon" class="icon--primary icon--lg" />
    </div>
    <div class="card__content">
      <slot name="title"></slot>
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: false,
        default: 'icon-facebook'
      },
      width: {
        type: [String, Number],
        required: false,
        default: 48
      },
      height: {
        type: [String, Number],
        required: false,
        default: 48
      }
    }
  }
</script>
