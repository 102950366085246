<template>
  <div class="help__container">
    {{ beforeLink }}

    <dy-link :link="link" :linkName="linkName" />

    {{ afterLink }}
  </div>
</template>

<script>
  import HelpLink from './HelpLink'

  export default {
    name: 'Help',

    computed: {
      link() {
        const application = process.env.VUE_APP_app_name
        const email = process.env.VUE_APP_admin_email
        return `mailto:${email}?subject=${application}`
      },

      linkName() {
        return process.env.VUE_APP_admin_name
      },

      message() {
        return this.$t('help.help.help')
      },

      beforeLink() {
        const tokens = this.message.split('[[ADMIN]]')
        return tokens[0] ? tokens[0].trim() : ''
      },

      afterLink() {
        const tokens = this.message.split('[[ADMIN]]')
        return tokens[1] ? tokens[1].trim() : ''
      }
    },

    components: {
      'dy-link': HelpLink
    }
  }
</script>
