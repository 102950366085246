import { isEmpty } from './../../utils/data/is_empty'

/**
 * Return the name of the schema to use for items included in an array.
 *
 * This is the special case where all items in an array use the same schema.
 *
 * Note: only return schema for items in the array. Return false for the actual array and empty items.
 *
 * options include:
 * - schema: schema to return
 *
 * @param {object} options options  i.e. { schema: 'choice' }
 * @param {object} data    doc and fieldValue { doc, fieldValue }
 * @returns {string}       schema to use
 */

const select =
  (options = {}) =>
  ({ doc }) => {
    const schema = options.schema || false

    return isEmpty(doc) || Array.isArray(doc) ? false : schema
  }

export default select
