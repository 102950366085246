import { isEmpty } from './../../utils/data/is_empty'

const transform = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return null
  }

  return fieldValue.toString().trim().toLowerCase()
}

export default transform
