import getProperty from '../../utils/document/get_property_value'
import { isBlank } from '../../utils/data/is_blank'

/**
 * validate a minimum value is less than or equal to a maximum value.
 *
 * USE:
 * {
 *   rule: 'minLTEMax',
 *   message: 'system.errors.minimumLTEMaximum',
 *   maximumField: 'options.maximum
 * }
 *
 * @param {object} options options
 * @param {object}  validateData { fieldValue, doc }
 */

const validate =
  options =>
  ({ fieldValue, doc }) => {
    const message = options.message || 'system.errors.minimumLTEMaximum'
    const maximumField = options.maximumField

    if (!maximumField) {
      return {
        fieldError: 'MinLessThanMax: A maximum field must be specified.'
      }
    }

    const maximum = getProperty(doc)(maximumField)(undefined)

    if (isBlank(fieldValue) || isBlank(maximum)) {
      return true
    }

    if (fieldValue <= maximum) {
      return true
    }

    return {
      fieldError: message,
      minimum: fieldValue,
      maximum
    }
  }

export default validate
