import buildError from '../../errors/build_error'

const AUTHORIZATION_ERROR = 'missing or insufficient permissions.'

const assertError = err => {
  const msg = err && err.message ? err.message.toLowerCase() : ''
  if (msg === AUTHORIZATION_ERROR) {
    throw buildError('notAuthorized')(err)
  }

  throw buildError('databaseError')(err)
}

export default assertError
