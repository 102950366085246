<template lang="html">
  <strong>{{ doc.categoryName }}: ({{ doc.productName }})</strong><br />
  <ul class="list--outside-disc">
    <li>{{ doc.brand }}</li>
    <li>{{ doc.product }}</li>
    <li>{{ doc.comment }}</li>
  </ul>
</template>

<script>
  export default {
    name: 'SurveyViewResponse',

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      }
    }
  }
</script>
