<template>
  <dt>{{ fieldLabel }}</dt>
  <dd>{{ displayFieldValue }}</dd>
</template>

<script>
  import ViewMixin from './view.mixin'

  export default {
    name: 'BaseViewItem',

    mixins: [ViewMixin],

    computed: {
      displayFieldValue() {
        if (this.fieldType === 'date' || this.fieldType === 'datetime') {
          return this.$filters.formatDateTime(this.fieldValue)
        } else if (this.fieldType === 'boolean') {
          return this.$t(
            this.fieldValue ? 'system.labels.yes' : 'system.labels.no'
          )
        } else if (this.fieldType === 'array') {
          return this.fieldValue.join(', ')
        } else if (this.fieldType === 'object' && this.fieldValue.en) {
          return this.$t(this.fieldValue)
        } else {
          return this.fieldValue
        }
      }
    }
  }
</script>
