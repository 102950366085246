<template>
  <dy-base-article>
    <template #header>
      <h1>{{ $t('error.label.opps') }}</h1>
    </template>

    <template #default>
      <dy-base-view v-if="error">
        <dy-base-view-slot>
          <template v-slot:label>
            {{ $t('error.fields.code') }}
          </template>

          <template v-slot:value>
            {{ error.code }}
          </template>
        </dy-base-view-slot>

        <dy-base-view-slot>
          <template v-slot:label>
            {{ $t('error.fields.title') }}
          </template>

          <template v-slot:value>
            {{ error.title }}
          </template>
        </dy-base-view-slot>

        <dy-base-view-slot>
          <template v-slot:label>
            {{ $t('error.fields.message') }}
          </template>

          <template v-slot:value>
            {{ error.message }}
          </template>
        </dy-base-view-slot>

        <dy-base-view-slot>
          <template v-slot:label>
            {{ $t('error.fields.data') }}
          </template>

          <template v-slot:value>
            <dl v-if="errorData" class="report">
              <template v-for="item in errorData" :key="item.key">
                <dt>{{ item.key }}</dt>
                <dd>{{ item.value }}</dd>
              </template>
            </dl>
            <div v-else>&nbsp;</div>
          </template>
        </dy-base-view-slot>

        <dy-base-view-slot>
          <template v-slot:label>
            {{ $t('error.fields.errorOn') }}
          </template>

          <template v-slot:value>
            {{ $filters.formatDateTime(error.errorOn) }}
          </template>
        </dy-base-view-slot>

        <dy-base-view-button @click="runHome">
          {{ $t('system.actions.return') }}
        </dy-base-view-button>
      </dy-base-view>

      <dy-base-view v-else>
        <dy-base-view-slot>
          <template v-slot:label>
            {{ $t('error.fields.message') }}
          </template>

          <template v-slot:value>
            {{ $t('error.labels.noErrorFound') }}
          </template>
        </dy-base-view-slot>

        <dy-base-view-button @click="runHome">
          {{ $t('system.actions.return') }}
        </dy-base-view-button>
      </dy-base-view>
    </template>
  </dy-base-article>
</template>

<script>
  import NotFoundError from './../../../errors/not_found.error'
  import getPropertyList from '../../../utils/document/get_property_list'

  export default {
    computed: {
      error() {
        return new NotFoundError({})
      },

      errorData() {
        const fields = getPropertyList(this.error.data)
        if (fields.length === 0) {
          return false
        }

        return fields.map(field => {
          return {
            key: field,
            value: JSON.stringify(this.error.data[field])
          }
        })
      }
    },

    methods: {
      runHome() {
        return this.$router.push({ name: 'home' })
      }
    },

    created() {
      this.$store.commit('CLEAR_LOADING')
    },

    unmounted() {
      this.$store.commit('CLEAR_ERROR')
    }
  }
</script>
