import pipe from '../../utils/fp/pipe'

import { getLocales, getKey } from './label.schema'

/**
 * Build a locale vs label cross reference
 *
 *  {
 *    en: {
 *      'system.action.go': 'Go',
 *      'system.action.home': 'Home'
 *    },
 *    fr: {
 *      'system.action.go': 'Va',
 *      'system.action.home': 'Acceiul'
 *    }
 *  }
 *
 * @param  {Array} labels list of labels
 * @return {Object}       locale vs label cross reference
 */
const getLabelXref = labels => {
  const useLabels = Array.isArray(labels) ? labels : []

  return pipe(assignLocales, assignLabels(useLabels))({})
}

const assignLocales = xref =>
  getLocales().reduce((accum, locale) => {
    accum[locale] = {}
    return accum
  }, xref)

const assignLabels = labels => xref =>
  labels.reduce((accum, label) => {
    getLocales().forEach(locale => {
      const key = getKey(label)
      accum[locale][key] = label[locale]
    })

    return accum
  }, xref)

export default getLabelXref
