<template lang="html">
  <dy-base-list-table>
    <template #header>
      <dy-base-list-row>
        <dy-base-list-column
          width="35%"
          label="system.fields.email"
          type="th"
        />
        <dy-base-list-column width="35%" label="system.fields.name" type="th" />

        <dy-base-list-column
          width="10%"
          label="survey.fields.votes"
          type="th"
        />

        <dy-base-list-column
          width="10%"
          label="survey.fields.completed"
          type="th"
        />

        <dy-base-list-column
          width="10%"
          label="system.fields.actions"
          type="th"
        />
      </dy-base-list-row>
    </template>

    <template #default>
      <dy-survey
        v-for="doc in docs"
        :key="doc.id"
        :schema="schema"
        :doc="doc"
      />
    </template>

    <template #footer>
      <dy-base-list-row>
        <dy-base-list-document-count colspan="5" :count="docs.length" />
      </dy-base-list-row>
    </template>
  </dy-base-list-table>
</template>

<script>
  import SurveyListItem from './SurveyListItem'

  export default {
    name: 'SurveyList',

    props: {
      schema: {
        type: Object,
        required: true
      },

      docs: {
        type: Array,
        required: true
      }
    },

    components: {
      'dy-survey': SurveyListItem
    }
  }
</script>
