import isNotFoundError from './../../errors/is_not_found_error'

import readDocument from './../../setup/client/crud/read_document.wrapper'
import readAll from './../../setup/client/crud/read_all.wrapper'
import createDocument from './../../setup/client/crud/create_document.wrapper'

import spawn from './spawn_survey'

import { Schema as categorySchema } from './../categories/category.schema'

const readOrCreate = user => schemaFn => id => {
  return readDocument(schemaFn)(id)
    .catch(err => {
      if (isNotFoundError(err)) {
        return false
      }

      throw err
    })
    .then(doc => {
      if (doc) {
        return doc
      }

      return readAll(categorySchema)({}).then(({ docs }) => {
        const survey = spawn(user)(docs)

        return createDocument(schemaFn)(survey)
      })
    })
}

export default readOrCreate
