import { isBlank } from '../../utils/data/is_blank'

import buildError from '../../errors/build_error'

const assertCollection = collection => {
  return new Promise((resolve, reject) => {
    if (isBlank(collection)) {
      return reject(
        buildError('serverError')({
          message: 'fbserver.errors.invalidCollection'
        })
      )
    }

    return resolve(collection)
  })
}

export default assertCollection
