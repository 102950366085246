import getFieldSchema from './../../services/schema/get_field_schema'
import getFieldType from './../../services/schema/get_field_type'
import getFieldList from './../../services/schema/get_fields'
import getFieldLabel from './../../services/schema/get_label'
import isArray from './../../services/schema/is_array'
import isObject from './../../services/schema/is_object'
import isRoot from './../../services/schema/is_root'

import { isEmpty, isNotEmpty } from './../../utils/data/is_empty'
import { isFunction } from './../../utils/data/is_function'

import getDefaultValidator from './get_default_validator'
import getBuiltinValidator from './get_builtin_validator'

const getFieldValidators = schema => fieldName => {
  return schema[fieldName].dyValidation || []
}

const getValidatorFn = validator => {
  if (isFunction(validator.rule)) {
    return validator.rule
  }
  return getBuiltinValidator(validator)
}

const getValidatorsForAnArrayItem = schema => {
  const validators = []

  if (isEmpty(schema)) {
    return validators
  }

  const fieldValidators = []
  const fieldType = getFieldType(schema)
  fieldValidators.push(getDefaultValidator(fieldType))

  const rules = schema.dyValidation || []
  rules.forEach(item => {
    fieldValidators.push(getValidatorFn(item))
  })

  if (fieldValidators.length >= 1) {
    validators.push({
      fieldName: '',
      fieldValidators,
      fieldLabel: getFieldLabel(schema)
    })
  }

  return validators
}

const getValidatorsForAnObject = schema => doc => {
  const validators = []

  if (isEmpty(schema)) {
    return validators
  }

  getFieldList(schema).forEach(fieldName => {
    const fieldValidators = []
    const fieldSchema = getFieldSchema(schema)(doc)(fieldName)
    const fieldType = getFieldType(fieldSchema)

    if (isObject(fieldType)) {
      fieldValidators.push(getDefaultValidator(fieldType)(fieldSchema))
    } else {
      fieldValidators.push(getDefaultValidator(fieldType))
    }

    getFieldValidators(schema)(fieldName).forEach(item => {
      fieldValidators.push(getValidatorFn(item))
    })

    const fieldValue = doc[fieldName]
    if (isNotEmpty(fieldValue)) {
      if (isObject(fieldType)) {
        getValidatorsForAnObject(fieldSchema)(fieldValue).forEach(item => {
          validators.push({
            fieldName: `${fieldName}.${item.fieldName}`,
            fieldValidators: item.fieldValidators,
            fieldLabel: item.fieldLabel
          })
        })
      } else if (isArray(fieldType)) {
        fieldValue.forEach((itemValue, idx) => {
          const itemName = `${fieldName}.${idx}`
          const itemSchema = getFieldSchema(schema)(doc)(itemName)
          if (getFieldList(itemSchema).length === 0) {
            getValidatorsForAnArrayItem(itemSchema).forEach(item => {
              validators.push({
                fieldName: itemName,
                fieldValidators: item.fieldValidators,
                fieldLabel: item.fieldLabel
              })
            })
          } else {
            getValidatorsForAnObject(itemSchema)(itemValue).forEach(item => {
              validators.push({
                fieldName: `${itemName}.${item.fieldName}`,
                fieldValidators: item.fieldValidators,
                fieldLabel: item.fieldLabel
              })
            })
          }
        })
      }
    }

    if (fieldValidators.length >= 1) {
      validators.push({
        fieldName,
        fieldValidators,
        fieldLabel: getFieldLabel(fieldSchema)
      })
    }
  })

  return validators
}

/**
 * Return validators for a document
 *
 * Returns an object - key: fieldName, value: fieldValidators
 *
 * {
 *   'name.en': { fieldValidators: [runRequired], fieldLabel: 'system.name.en' },
 *   'name.fr': { fieldValidators: [runRequired], fieldLabel: 'system.name.fr' },
 *   description: {fieldValidator: [runRequired], fieldLabel: 'system.name.en }
 * }
 *
 * @param  {object} schema  document schema
 * @param  {object} doc     document
 * @return {object}         validators
 */
const getValidators = schema => doc => {
  const useDoc = doc || {}
  const isSchema = !!schema

  const rootValidator = {}

  if (isSchema === false) {
    return rootValidator
  }

  if (isRoot(schema)) {
    rootValidator.root = {
      fieldValidators: [getDefaultValidator('object')(schema)],
      fieldLabel: getFieldLabel(schema)
    }
  }

  return getValidatorsForAnObject(schema)(useDoc).reduce(
    (validators, { fieldName, fieldValidators, fieldLabel }) => {
      validators[fieldName] = { fieldValidators, fieldLabel }
      return validators
    },
    rootValidator
  )
}

export default getValidators
