import transformCreatedOn from './transform_created_on'

const createdOn = () => {
  return {
    createdOn: {
      dyField: 1,
      dyLabel: 'system.fields.createdOn',
      dyType: 'datetime',
      dyTransform: [transformCreatedOn]
    }
  }
}

export default createdOn
