<template>
  <fieldset>
    <legend v-if="$slots.legend">
      <slot name="legend" />
    </legend>

    <div class="form__fields">
      <slot name="default" />
    </div>
  </fieldset>
</template>
