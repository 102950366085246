import Error from './Error.vue'
import PageNotFound from './PageNotFound.vue'

const routes = [
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: {}
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'pagenotfound',
    component: PageNotFound,
    meta: {}
  }
]

export default routes
