import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'

const format01 = /^(\d-)?\d{3}-[A-Za-z0-9]{3}-[A-Za-z0-9]{4}(\s?x\d{1,9})?$/
const format02 = /^(\d-)?\d{3}-[A-Za-z0-9-]{7,8}(\s?x\d{1,9})?$/

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  if (isBlank(fieldValue) && typeof fieldValue === 'string') {
    return true
  }

  if (format01.test(fieldValue.toString())) {
    return true
  }

  if (format02.test(fieldValue.toString())) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
