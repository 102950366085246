const FileData = file => content => {
  return {
    name: file.name,
    size: file.size,
    contentType: file.type,
    content
  }
}

export default FileData
