<template>
  <dy-submenu v-if="hasChildren" :menu="menu" @openLink="openLink" />
  <dy-menu-item v-else :menu="menu" @openLink="openLink" />
</template>

<script>
  import MenuItem from './MenuItem.vue'
  import Submenu from './Submenu'

  export default {
    name: 'Menu',

    props: {
      menu: {
        type: Object,
        required: true
      }
    },

    emits: ['openLink'],

    computed: {
      hasChildren() {
        return this.menu.children && this.menu.children.length > 0
      }
    },

    methods: {
      openLink() {
        return this.$emit('openLink')
      }
    },

    components: {
      'dy-menu-item': MenuItem,
      'dy-submenu': Submenu
    }
  }
</script>
