<template>
  <transition name="modal">
    <div class="modal-background">
      <div
        class="modal-container"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header">
          <slot name="header" />
        </header>

        <section class="modal-body">
          <slot name="body" />
          <slot name="action" />
        </section>

        <footer class="modal-footer">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>
