import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import runAction from '../run_action'

/**
 * Dispatch a runRead action to read a document.
 *
 * Document is placed in the document store.
 *
 * parameters {
 *   id:  id of a document
 * }
 *
 * @param {Function} schemaFn function to return document schema
 * @param {Object} params parameters
 * @returns {Boolean} true
 */
const runReadDocument = schemaFn => params => {
  const { id } = params
  const storeName = schemaFn({}).dyStore
  const store = useStore()

  const router = useRouter()

  const setupFn = () => {
    return store.dispatch(`${storeName}/runRead`, { id })
  }

  runAction({ store, router })(setupFn)()

  return true
}

export default runReadDocument
