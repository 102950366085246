import { isBlank } from './../../utils/data/is_blank'

const transform = ({ fieldValue }) => {
  if (isBlank(fieldValue)) {
    return ''
  }

  const useValue = fieldValue.toString().trim().toLowerCase()

  return useValue === 'true' || useValue === 'yes' || useValue === '1'
}

export default transform
