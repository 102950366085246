<template lang="html">
  <dy-base-modal>
    <template v-slot:header>
      <h3>
        {{ $t('system.titles.confirm') }}
      </h3>
    </template>

    <template v-slot:body>
      <span v-html="getMessage"></span>
    </template>

    <template v-slot:action>
      <p>
        {{ docLabel }} <br />
        {{ docName }}
      </p>
    </template>

    <template v-slot:footer>
      <button type="button" class="btn-close" :aria-label="getYes" @click="yes">
        {{ getYes }}
      </button>
      <button type="button" class="btn-close" :aria-label="getNo" @click="no">
        {{ getNo }}
      </button>
    </template>
  </dy-base-modal>
</template>

<script>
  export default {
    name: 'ConfirmDelete',

    props: {
      docName: {
        type: String,
        required: true
      },

      docLabel: {
        type: String,
        required: true
      }
    },

    computed: {
      getMessage() {
        return this.$t('system.help.deleteConfirmation', {
          label: this.docLabel,
          name: this.docName
        })
      },

      getYes() {
        return this.$t('system.labels.yes')
      },

      getNo() {
        return this.$t('system.labels.no')
      }
    },

    methods: {
      yes() {
        this.close(true)
      },

      no() {
        this.close(false)
      },

      close(deleteFlag) {
        this.$emit('close', deleteFlag)
      }
    }
  }
</script>
