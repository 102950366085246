import getDocuments from '../get_documents'
import runReadAll from './run_read_all_documents'
import getQueryMethod from './get_query_method'

/**
 * setup a page to query a list of documents.
 * - setup computed properties
 *    - schema
 *    - docs
 *
 * - setup method
 *    - runQuery
 *
 * - dispatch a runReadAll request to retrieve documents
 *
 * returns {
 *  docs,
 *  schema
 * }
 *
 * @param {Function} schemaFn function to return schema for a document
 * @returns {Object} computed properties
 */
const setupListPage = Schema => {
  const { docs, schema } = getDocuments(Schema)

  const runQuery = getQueryMethod(Schema)

  runReadAll(Schema)

  return { schema, docs, ...runQuery }
}

export default setupListPage
