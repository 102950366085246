import { isBlank } from './../../utils/data/is_blank'
import { isEmpty } from './../../utils/data/is_empty'

// const format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

/* eslint-disable no-useless-escape */
const format =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* eslint-enable no-useless-escape */

const validate = ({ fieldValue }) => {
  if (isEmpty(fieldValue)) {
    return true
  }

  const isString = typeof fieldValue === 'string'
  if (isBlank(fieldValue) && isString) {
    return true
  }

  if (isString === false) {
    return { fieldError: 'system.errors.invalid' }
  }

  if (format.test(fieldValue)) {
    return true
  }

  return { fieldError: 'system.errors.invalid' }
}

export default validate
