const isPlainObject = x => !!x && x.constructor === Object
const isNotPlainObject = x => !isPlainObject(x)

// const isPlainObject = fieldValue => {
//   return (
//     typeof fieldValue === 'object' &&
//     Object.prototype.toString.call(fieldValue) === '[object Object]'
//   )
// }

export { isPlainObject, isNotPlainObject }
