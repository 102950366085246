<template>
  <a href="" class="action-link" @click.prevent="show">
    <dy-base-icon icon="action/delete" class="icon--primary icon--md" />
  </a>

  <dy-confirm
    v-show="showConfirmation"
    :docName="documentName"
    :docLabel="documentLabel"
    @close="hide"
  />
</template>

<script>
  import ConfirmDelete from './ConfirmDelete.vue'
  import getName from './../../../services/schema/get_document_name'
  import getLabel from './../../../services/schema/get_label'

  export default {
    name: 'DeleteLink',

    data() {
      return {
        showConfirmation: false
      }
    },

    props: {
      schema: {
        type: Object,
        required: true
      },

      doc: {
        type: Object,
        required: true
      }
    },

    emits: ['runDelete'],

    computed: {
      documentLabel() {
        return this.$t(getLabel(this.schema))
      },

      documentName() {
        return getName(this.schema)(this.doc)
      }
    },

    methods: {
      show() {
        this.showConfirmation = true
      },

      hide(response) {
        this.showConfirmation = false
        if (!response) {
          return true
        }

        return this.$emit('runDelete')
      }
    },

    components: {
      'dy-confirm': ConfirmDelete
    }
  }
</script>
